interface AlertProps {
  type?: string;
  message: string;
}

const Alert = function (props: AlertProps) {
  return (
    <div className={`alert alert-${props.type || "danger"} mt-1`}>
      {props.message}
    </div>
  );
};

export default Alert;
