// import { getGigs } from "./gig-read-api";
import { baseUrl } from "../../../API/base-urls";
import { AppDispatch } from "../../../store/store";

const URL = `${baseUrl}/gigs`;

export const deleteGig = function (
  gigId: string,
  setLoading: (val: boolean) => void
) {
  return (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    const uploadGig = fetch(`${URL}/${gigId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    uploadGig
      .then((results) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };
};
