interface DropDownItemProps {
  item: {
    _id?: string;
    name: string;
  };
}

const DropDownItem = function (props: DropDownItemProps) {
  return <option value={props.item?._id}>{props.item?.name}</option>;
};

export default DropDownItem;
