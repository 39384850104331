import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "חייב להיות בין 8 ל 16 תווים")
    .max(16, "חייב להיות בין 8 ל 16 תווים")
    .matches(passwordRules, { message: "יש ליצור סיסמה חזקה" })
    .required("שדה חובה"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "סיסמאות לא תואמות")
    .required("שדה חובה"),
});
