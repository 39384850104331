import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

interface NewReview {
  rating: number;
  reviewText: string;
}

export const createReview = function (
  userId: string,
  orderId: string,
  newReview: NewReview
) {
  // return (dispatch: AppDispatch) => {
  const token = localStorage.getItem("token");
  const { rating, reviewText } = newReview;

  // const submitReview =
  return fetch(`${URL}/${userId}/orders/${orderId}/review`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({ rating, reviewText }),
  });
  // submitReview
  // .then((results) => {
  //   if (!results.ok) throw new Error();
  //   return results.json();
  // })
  // .then((reviewDetails) => {
  //   window.location.replace("/");
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
  // };
};
