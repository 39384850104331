import { Modal } from "react-bootstrap";

const ImageModal = function (props: {
  closeModal?: () => void;
  showImageModal?: boolean;
  imgSrc?: string;
}) {
  const { closeModal, showImageModal, imgSrc } = props;
  return (
    <Modal show={showImageModal} size="xl" onHide={closeModal}>
      <Modal.Header closeButton />
      <Modal.Body className="d-flex align-items-center justify-content-center ">
        <img
          src={imgSrc}
          alt="תמונה לדוגמה"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "80vw",
            maxHeight: "80vh",
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
