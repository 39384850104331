import { Gig } from "../features/gigs/interface/gig.interface";

export const sortGigsByPriceAsc = function (gigArray: Gig[]) {
  const priceAscGigs = gigArray.sort((a, b) => a.price - b.price);
  return priceAscGigs;
};

export const sortGigsByPriceDesc = function (gigArray: Gig[]) {
  const priceDescGigs = gigArray.sort((a, b) => b.price - a.price);
  return priceDescGigs;
};

export const sortGigsByDateAsc = function (gigArray: Gig[]) {
  const dateAscGigs = gigArray.sort(
    (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
  );
  return dateAscGigs;
};

export const sortGigsByDateDesc = function (gigArray: Gig[]) {
  const dateAscGigs = gigArray.sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );
  return dateAscGigs;
};

export const sortGigsByPopularity = function (gigArray: Gig[]) {
  const sortedGigsByPopularity = gigArray.sort((a, b) => {
    let reviewsA: number = 0;
    let reviewsB: number = 0;
    const gigAReviews = a.userId.receivedReviews.filter(
      (review) => review.gig.toString() === a._id.toString()
    );
    const gigBReviews = b.userId.receivedReviews.filter(
      (review) => review.gig.toString() === b._id.toString()
    );
    gigAReviews.map((review) => (reviewsA += review.rating));
    gigBReviews.map((review) => (reviewsB += review.rating));

    reviewsA = reviewsA / gigAReviews.length;
    reviewsB = reviewsB / gigBReviews.length;
    if (isNaN(reviewsB)) reviewsB = 0;
    if (isNaN(reviewsA)) reviewsA = 0;
    if (reviewsA > reviewsB) {
      return -1;
    } else if (reviewsB > reviewsA) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedGigsByPopularity;
};
