import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { GigCard } from "../../gigs/components/GigCard";
const FavoritesPage = function () {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userId, authCheck } = useAppSelector((state) => state.user);
  const { favoriteGigs } = useAppSelector((state) => state.favorites);
  useEffect(() => {
    if (!authCheck) {
      navigate("/");
    } else if (id !== userId) {
      navigate("/");
    }
  }, [userId, id, authCheck, navigate]);
  return (
    <div style={{ minHeight: "100vh" }}>
      {favoriteGigs.length ? (
        <div className="mt-5 container">
          <div className="p-1 border-bottom">
            <h3>ג'ובים אהובים</h3>
          </div>
          <div className="row">
            {favoriteGigs.map((gig) => {
              return (
                <div key={gig._id} className="col-12 col-md-6 col-lg-3">
                  <GigCard gig={gig} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center mt-5">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693686369/haltu_assets/Choose-pana-no-bg_wkkpbv.svg"
            // style={{ width: "600px", height: "550px" }}
            style={{ width: "45vh" }}
            alt="לא נמצאו ג'ובים אהובים"
            // className="d-none d-md-block"
          />
          <h1 className="text-center">לא הצלחנו למצוא ג'ובים שאהבת...</h1>
        </div>
      )}
    </div>
  );
};

export default FavoritesPage;
