export const ForbiddenActivitySection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>שימוש בלתי מורשה באתר</h2>
      <p>בשימושך באתר הנך מסכים במפורש לא לעשות את הפעולות הבאות ביחס לאתר:</p>
      <ul>
        <li>
          להעתיק,להעביר להתאים לשנות להפיץ לשדר להציג ליצור יצירות מקויות, לפרסם
          או לשכפל אותו בכל אופן
        </li>
        <li>
          לנסות לפרק לאחור או להפיק את קוד המקור שלו, רעיונותיו הבסיסיים
          אלגוריתמיו מבנהו או ארגונו
        </li>
        <li>להסיר כל הודעת זכויות יוצרים, זיהוי או כל הודעה אחרת ברשותו</li>
        <li>
          להשתמש בתוכנות אוטומציה(בוטים), האקים, שינויים, או כל תוכנה של צד
          שלישי שאינה מאושרת, המיועדת לשנות את האתר
        </li>
        <li>
          לנסות לגשת לאתר באופן בלתי מורשה, להפריע בו, לגרום לנזק או להפריע בו
          או במערכות המחשבים או הרשתות המחוברות לאתר
        </li>
        <li>
          להפנות,להסיר,לשנות,לבטל,להוריד או למנוע כל צעד טכנולוגי או הגנות תוכן
          האתר
        </li>
        <li>
          להכניס וירוסים, טרויאנים, תוכניות זדוניות, תוכנות רמאות או חומרים
          אחרים שהם זדוניים או מזיקים מבחינה טכנולוגית
        </li>
        <li>
          להשתמש ברובוט, או בכל תוכנה אוטומטית אחרת,תהליך תוכנה או שאילתות
          שמפנות או כורות מידע מהאתר
        </li>
        <li>לסרוק או לאסוף ממנו מידע או נתונים ולערוך תהליך ידני לעשות כך</li>
        <li>
          להשתמש באתר באופן שעשוי לגרום, לנטרל, להעמיס או לפגוע באתר, או להפריע
          למשתמשים אחרים באתר
        </li>
        <li>לגשת או להשתמש באתר בכל אופן שאינו מורשה במפורש כגון</li>
      </ul>
    </div>
  );
};
