export const ExpectationsSection = () => {
  return (
    <div>
      <h2>איכות ודיוק</h2>
      <p>
        הפרילנסרים מתחייבים לספק מוצרים ושירותים העומדים בתקני האיכות, כפי
        שמפורט בתיאורי המוצרים. האחריות לספק דרישות ברורות ומדוייקות לפרילנסר
        היא עליך, וזה יעזור לוודא שהעבודה הנמסרת מתאימה לציפיותיך.
      </p>
    </div>
  );
};
