import React from "react";
import { Gig } from "../../../interface/gig.interface";

import ProgressButtons from "./ProgressButtons";
import Input from "../../../../../components/Forms/Input";
import TextArea from "../../../../../components/Forms/TextArea";
import { GigFormOutput } from "../../../interface/gig-form-output";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface FirstSectionProps {
  gig?: Gig;
  errState: string | null;
  setErrState: (str: string) => void;
  progress: number;
  onProgress: (event: any) => void;
  formData: GigFormOutput;
  btnText: string;
  onChange: (event: React.ChangeEvent) => void;
}

const FirstSection = function (props: FirstSectionProps) {
  const checkInput = function (event: any) {
    const { name } = event.target;
    if (name === "previous") {
      props.onProgress(event);
      return;
    }
    let isValid = true;
    for (const [key, value] of Object.entries(props.formData)) {
      if (!isValid) break;
      if (key === "title" || key === "description") {
        if (!value) {
          props.setErrState(key);
          isValid = false;
        }
      }
      if (key === "price")
        if (Number(value) < 20 || Number.isNaN(value)) {
          props.setErrState(key);
          isValid = false;
        }
    }
    if (isValid && name === "next") props.onProgress(event);
  };
  return (
    <div className="col-12">
      <h1 className="pb-2 border-bottom">כללי</h1>
      <div className="row">
        <div className="col-12 col-md-6 px-2">
          <div>
            <div className="d-flex align-items-center">
              <div className="col-11">
                <Input
                  id="title"
                  type="text"
                  text="כותרת"
                  onChange={props.onChange}
                  placeholder="אני אעשה את..."
                  value={props.formData.title}
                  extraClass={`${
                    props.errState === "title" ? "is-invalid" : ""
                  }`}
                />
              </div>
              <OverlayTrigger
                overlay={
                  <Tooltip id="titleTooltip">
                    מומלץ לבחור כותרת שתגרום לאנשים לרצות להיכנס לצפות בג'וב
                  </Tooltip>
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mx-2 mt-4"
                />
              </OverlayTrigger>
            </div>
            {props.errState === "title" ? (
              <p className="text-danger">נא להזין כותרת לג'וב.</p>
            ) : null}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="col-11">
                <Input
                  id="price"
                  type="text"
                  text={`מחיר`}
                  onChange={props.onChange}
                  value={props.formData.price?.toString()}
                  extraClass={props.errState === "price" ? "is-invalid" : ""}
                />
              </div>
              <OverlayTrigger
                overlay={
                  <Tooltip id="titleTooltip">
                    יש לבחור מחיר בהתאם לשוק בו מתפרסם הג'וב. <br /> ג'ובים
                    יקרים מדי יפגעו בהזדמנות להגיע לקהל יעד גדול יותר
                  </Tooltip>
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mx-2 mt-4"
                />
              </OverlayTrigger>
            </div>
            {props.errState === "price" ? (
              <p className="text-danger">
                נא להזין מחיר גבוה או שווה ל-20 ש"ח.
              </p>
            ) : null}
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="col-11">
                <TextArea
                  id="description"
                  text="תיאור"
                  onChange={props.onChange}
                  value={props.formData.description}
                  extraClass={
                    props.errState === "description" ? "is-invalid" : ""
                  }
                />
              </div>
              <OverlayTrigger
                overlay={
                  <Tooltip id="titleTooltip">
                    תיאור טוב ומפורט יוכל להגדיל משמעותית את ההתעניינות ברכישת
                    הג'וב
                  </Tooltip>
                }
                placement="bottom"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mx-2 mt-4"
                />
              </OverlayTrigger>
            </div>
            {props.errState === "description" ? (
              <p className="text-danger">נא להוסיף תיאור לג'וב.</p>
            ) : null}
          </div>
        </div>
        <div className="d-none d-md-flex justify-content-center align-items-center col-6">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693687266/haltu_assets/Processing-pana-no-bg_rc1oic.svg"
            alt="התקדמות ראשונה"
            style={{ width: "75%" }}
          />
        </div>
      </div>
      <div className="mt-5">
        <ProgressButtons
          progress={props.progress}
          btnText={props.btnText}
          onProgress={checkInput}
        />
      </div>
    </div>
  );
};

export default FirstSection;
