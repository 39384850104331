import { Fragment, useEffect, useState } from "react";
import { Gig } from "../../../../features/gigs/interface/gig.interface";

import Carousel from "react-multi-carousel";
import { GigCard } from "../../../../features/gigs/components/GigCard";

const ViewedGigs = () => {
  const [viewedGigs, setViewedGigs] = useState<Gig[] | null>(null);

  useEffect(() => {
    // const maxLength = 10;
    let storageViewedGigs: Gig[] = [];
    const ls = localStorage.getItem("viewedGigs");
    if (ls) {
      try {
        storageViewedGigs = JSON.parse(ls);
        setViewedGigs(storageViewedGigs);
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Fragment>
      {viewedGigs?.length ? (
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <h1 className="col-11 text-center text-md-start">
              המשיכו מאיפה שעצרתם
            </h1>
          </div>
          <Carousel
            responsive={responsive}
            rtl={true}
            className="pb-3"
            itemClass="d-flex justify-content-center align-items-center"
          >
            {viewedGigs.map((gig) => {
              return (
                <div className="col-10 col-md-8" key={gig._id}>
                  <GigCard gig={gig} />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ViewedGigs;
