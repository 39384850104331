import { useEffect } from "react";
import { userActions } from "../../store/slices/user/user-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DecodedToken } from "../../features/user/interface/user.interface";
import jwtDecode from "jwt-decode";

export const useAutoLog = function () {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token);
      dispatch(userActions.setUserData(decodedToken));
    } else {
      return;
    }
  }, [token, dispatch]);
};
