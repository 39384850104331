import React from "react";
import { uploadUserImage } from "../../api/user-info/user-info-update/user-image";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";
import { User } from "../../interface/user.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const UserImage = function (props: {
  user: User;
  fontSize?: string;
  canEdit: boolean;
  showOnline?: boolean;
  size: number;
}) {
  const { user, showOnline, canEdit } = props;
  const { onlineUsers } = useAppSelector((state) => state.ui);
  const connectedUser: boolean =
    onlineUsers.findIndex((onlineUser) => onlineUser.userId === user._id) !== -1
      ? true
      : false;
  const initials =
    user.firstName[0].toUpperCase() + user.lastName[0].toLowerCase();
  const inputId = `profile_image_${user._id}`;
  const params = useParams();
  const convertImage = function (event: React.ChangeEvent) {
    event.preventDefault();
    const image = (event.target as HTMLInputElement).files;
    if (!image) return;
    if (image[0].size > 9437184) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(image[0]);
    reader.onloadend = (e) => {
      if (!reader.result) return;
      const str = reader.result.toString();
      uploadUserImage(str, params.id!)
        .then((results) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  return (
    <div
      style={{
        width: canEdit ? `${props.size}px` : "",
        height: canEdit ? `${props.size}px` : "",
      }}
    >
      <label htmlFor={inputId} className="d-flex">
        {user.userPhoto ? (
          <div className={`${props.canEdit ? "user-image-input" : ""}`}>
            <img
              src={user.userPhoto}
              alt="..."
              style={{
                width: `${props.size}px`,
                height: `${props.size}px`,
                borderRadius: "50%",
                position: canEdit ? "absolute" : "static",
              }}
            />
            {props.canEdit && (
              <div className="user-image-overlay">
                <FontAwesomeIcon icon={faCamera} />
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              className="text-light d-flex justify-content-center align-items-center"
              style={{
                width: `${props.size}px`,
                height: `${props.size}px`,
                fontSize: props.fontSize || "35px",
                borderRadius: "50%",
                background: "#e0a2a2",
                position: canEdit ? "absolute" : "static",
              }}
            >
              {initials}
            </div>
            {props.canEdit && (
              <div className="user-image-overlay">
                <FontAwesomeIcon icon={faCamera} />
              </div>
            )}
          </div>
        )}
        {showOnline && (
          <div
            style={{
              width: `${props.size / 4}px`,
              height: `${props.size / 4}px`,
              borderRadius: "50%",
              background: `${!connectedUser ? "#605F5E" : "#53DD6C"}`,
              position: "absolute",
            }}
          />
        )}
      </label>
      {props.canEdit && (
        <input
          type="file"
          hidden
          id={inputId}
          onChange={convertImage}
          accept="image/*"
        />
      )}
    </div>
  );
};

export default UserImage;
