import { Modal, Button, Spinner } from "react-bootstrap";

const SellerCancelationModal = function (props: {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  loading: boolean;
}) {
  const { open, loading } = props;
  return (
    <Modal
      show={open}
      onHide={props.onClose}
      className="d-flex align-items-center justify-content-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>ביטול הזמנה</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fs-5 text-center" style={{ fontWeight: "700" }}>
          האם אתה בטוח שתרצה לבטל הזמנה זו?
        </p>
        <p className="text-secondary text-center">
          הקונה יוודע על ביטול הזמנה זאת במייל, לא ניתן לאשר הזמנה זו לאחר
          ביטולה.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button
          variant="primary"
          className="col-4 mx-2"
          type="button"
          onClick={props.onClose}
        >
          חזרה
        </Button>
        <Button
          variant={`danger ${loading ? "disabled" : ""}`}
          className="col-4 mx-2"
          type="button"
          onClick={props.onCancel}
        >
          {loading ? <Spinner size="sm" /> : "ביטול ההזמנה"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SellerCancelationModal;
