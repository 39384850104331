export const IntellectualPropertySection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>קניין רוחני</h2>
      <p>
        כל התוכן באתר, כולל אך לא מוגבל לטקסט, גרפיקות, לוגואים, תמונות, קטעי
        קול, קטעי וידאו, אוספי מידע, ותוכנה, הם רכושה של חלטו ומוגנים על פי חוקי
        זכות יוצרים וחוקים הנוגעים לקניין הרוחני. אין לשכפל, להפיץ, לשנות או
        ליצור יצירות מן החומר באתר, להציג בציבור, לפרסם מחדש, להוריד, לאחסן או
        לשדר כל חומר מהאתר שלנו ללא הסכמה מראש בכתב מאת חלטו, אלא בהתאמה להלן:
        המחשב שברשותך עשוי לאחסן זמנית העתקים מכלל החומרים הללו בזכרון הפעיל
        (RAM) כחלק מתהליך גישה וצפייה שלך בחומרים אלו. חלטו, חרוזים ושמות
        המוצרים הם סימני מסחר של חלטו. אין להשתמש בסימנים אלו ללא הסכמה מראש
        בכתב מאת חלטו.
      </p>
    </div>
  );
};
