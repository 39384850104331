import { Gig } from "../../../interface/gig.interface";
import { GigFormOutput } from "../../../interface/gig-form-output";
import { Category } from "../../../../categories/interface/category.interface";

import ProgressButtons from "./ProgressButtons";
import TextArea from "../../../../../components/Forms/TextArea";
import DropDown from "../../../../../components/Forms/Dropdowns/DropDown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

interface SecondSectionProps {
  categories: Category[] | undefined;
  onChange: (event: React.ChangeEvent) => void;
  setErrState: (str: string) => void;
  progress: number;
  btnText: string;
  gig: Gig | undefined;
  onProgress: (event: any) => void;
  formData: GigFormOutput;
  errState: string | null;
}

const SecondSection = function (props: SecondSectionProps) {
  const deliveryDays = [];
  const revisions = [];
  for (let i = 1; i <= 30; i++)
    deliveryDays.push({ _id: i, name: `${i} ימי אספקה` });
  for (let i = 0; i <= 14; i++)
    revisions.push({ _id: i, name: `${i} תיקונים` });
  const checkInput = function (event: any) {
    const { name } = event.target;
    if (name === "previous") {
      props.onProgress(event);
      return;
    }
    let isValid = true;
    for (const [key, value] of Object.entries(props.formData)) {
      if (!isValid) break;
      if (key === "category") {
        if (!value) {
          props.setErrState(key);
          isValid = false;
        }
      }
      if (key === "whatsIncluded") {
        if (!value) {
          props.setErrState(key);
          isValid = false;
        }
        if (value.toString().length < 15) {
          props.setErrState(key);
          isValid = false;
        }
      }
      if (key === "revisions") {
        if (Number(value) < 0 || Number.isNaN(value)) {
          props.setErrState(key);
          isValid = false;
        }
      }
      if (key === "deliveryDays") {
        if (Number(value) <= 0 || Number.isNaN(value)) {
          props.setErrState(key);
          isValid = false;
        }
      }
    }
    if (isValid && name === "next") props.onProgress(event);
  };
  return (
    <div className="col-12">
      <h1 className="pb-2 border-bottom">קטגוריות</h1>
      <div className="row">
        <div className="col-12 col-md-7">
          {props.categories && (
            <div className="col-12 border-bottom pb-2">
              <DropDown
                value={props.formData.category}
                text="קטגוריה"
                items={props.categories}
                onChange={props.onChange}
                name="category"
              />
              {props.errState === "category" ? (
                <p className="p-0 m-0 text-danger">יש לבחור קטגוריה.</p>
              ) : null}
            </div>
          )}
          <div className="col-12 border-bottom mt-2 pb-2">
            <DropDown
              items={deliveryDays}
              value={props.formData.deliveryDays.toString()}
              name="deliveryDays"
              text="ימי אספקה"
              onChange={props.onChange}
            />
            {props.errState === "deliveryDays" ? (
              <p className="text-danger">
                יש לבחור כמה ימי אספקה נדרשים להשלמת העבודה.
              </p>
            ) : null}
          </div>
          <div className="col-12 border-bottom mt-2 pb-2">
            <DropDown
              items={revisions}
              value={props.formData.revisions.toString()}
              name="revisions"
              text="תיקונים"
              onChange={props.onChange}
            />
            {props.errState === "revisions" ? (
              <p className="text-danger">
                יש לבחור כמה תיקונים רשאי הלקוח לבקש.
              </p>
            ) : null}
          </div>
          <div className="mt-5 mb-5">
            <div>
              <div className="d-flex align-items-center">
                <div className="col-11">
                  <TextArea
                    id="whatsIncluded"
                    text="מה כלול?"
                    onChange={props.onChange}
                    placeholder="תאר מה הלקוח יקבל ברכישת המוצר"
                    value={props.formData.whatsIncluded}
                    extraClass={
                      props.errState === "whatsIncluded" ? "is-invalid" : ""
                    }
                  />
                </div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="titleTooltip">
                      מומלץ לרשום דברים כמו סוג קבצים,זכויות יוצרים וכו'
                    </Tooltip>
                  }
                  placement="bottom"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="mx-2 mt-4"
                  />
                </OverlayTrigger>
              </div>
              {props.errState === "whatsIncluded" ? (
                <p className="text-danger">
                  יש לרשום לפחות 15 אותיות על מה הלקוח יקבל.
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-none d-md-flex justify-content-center align-items-center col-5">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693687287/haltu_assets/Visual_data-pana-no-bg2_x3mtvh.svg"
            alt="התקדמות שניה"
          />
        </div>
      </div>
      <div className="mt-5">
        <ProgressButtons
          progress={props.progress}
          onProgress={checkInput}
          btnText={props.btnText}
        />
      </div>
    </div>
  );
};

export default SecondSection;
