import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import { createGig } from "../api/gig-create-api";
import { Category } from "../../categories/interface/category.interface";

import { Spinner } from "../../../components/Spinner";
import Alert from "../../../components/Alert";
import GigForm from "../components/Forms/GigForm";
import { GigFormOutput } from "../interface/gig-form-output";
import { getUserInfo } from "../../user/api/user-info/user-info";
import { Gig } from "../interface/gig.interface";
import { User } from "../../user/interface/user.interface";

export const GigCreation = function (props: { categories: Category[] }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errState, setErrState] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { serverError } = useAppSelector((state) => state.ui);
  const { userId, membership } = useAppSelector((state) => state.user);

  useEffect(() => {
    setPageLoading(true);
    if (id !== userId) {
      setPageLoading(false);
      navigate("/");
    }
    getUserInfo(id!)
      .then((results: { foundUser: User; activeGigs: Gig[] }) => {
        if (membership && membership?.maxGigs <= results.activeGigs.length) {
          navigate("/plans/pricing");
        }
      })
      .catch((error: Error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setPageLoading(false);
        }, 2000);
      });
  }, [id, userId, navigate, membership]);

  function submitHandler(gigValues: GigFormOutput) {
    if (!loading) {
      setLoading(true);
      dispatch(createGig(gigValues, (state) => setLoading(state)));
    }
  }

  return (
    <>
      {!pageLoading ? (
        <div
          className="d-flex flex-column justify-content-start"
          style={{ minHeight: "150vh" }}
        >
          <div className="container mt-5">
            <GigForm
              submitHandler={submitHandler}
              btnText="יצירה!"
              categories={props.categories!}
              setErrState={setErrState}
              errState={errState}
              loading={loading}
            />
            {serverError ? <Alert message={serverError} /> : ""}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};
