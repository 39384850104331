const ClientBenefits = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="text-center m-0">רוכשים? תוכלו ליהנות מהדברים הבאים:</h1>
      <div className="p-0 p-md-5 col-10 d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529063/haltu_assets/coin-stack_5316866_j78pmt.svg"
            alt="חסכון בכסף"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">מחירים תחרותיים</h5>
          <p className="mt-2 text-center text-secondary">
            הפלטפורמה שלנו מציעה מגוון מחירים עבור שירותי פרילנסרים, מה שאומר
            שתוכלו לקבל שירותים שונים בישראל במחיר נוח לכל כיס.
          </p>
        </div>
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694534073/haltu_assets/access_2830604_gxtebb.svg"
            alt="גישה לפרילנסרים"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">גישה לפרילנסרים בישראל</h5>
          <p className="mt-2 text-center text-secondary">
            הפלטפורמה שלנו תספק לכם את הגישה למאגר של פרילנסרים מיומנים ומנוסים
            מכל רחבי הארץ. זה אומר שתוכלו בקלות למצוא את הפרילנסר הנכון לפרויקט
            שלכם.
          </p>
        </div>
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694533984/haltu_assets/peace_624526_bblphu.svg"
            alt="מגוון רחב של שירותים"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">מגוון רחב של שירותים</h5>
          <p className="mt-2 text-center text-secondary">
            הפלטפורמה שלנו מציעה מגוון רחב של שירותי פרילנסרים, מכתיבה ועריכה עד
            עיצוב גרפי ותכנות. מה שאומר שתוכלו תמיד למצוא את מה שאתם צריכים לעסק
            שלכם.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClientBenefits;
