import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateFavorites } from "../api/favorites-update";
import { deleteFavorites } from "../api/favorites-delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";

const LikeButton = function (props: { gigId: string; isFavorite: boolean }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { gigId, isFavorite } = props;
  const [showFull, setShowFull] = useState<boolean>(false);
  const { authCheck } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (isFavorite) {
      setShowFull(true);
    } else {
      setShowFull(false);
    }
  }, [isFavorite]);

  const clickHandler = function () {
    if (!authCheck) {
      navigate("/users/login");
      return;
    }
    if (isFavorite) {
      setShowFull(false);
      dispatch(deleteFavorites(gigId));
    } else {
      setShowFull(true);
      dispatch(updateFavorites(gigId));
    }
  };

  return (
    <div
      onMouseEnter={() => setShowFull(true)}
      style={{ cursor: "pointer" }}
      onClick={clickHandler}
    >
      <FontAwesomeIcon
        icon={!showFull ? faHeartOutline : faHeartSolid}
        onMouseLeave={() => {
          if (!isFavorite) {
            setShowFull(false);
          }
        }}
      />
    </div>
  );
};

export default LikeButton;
