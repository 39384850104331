import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";

import { User } from "../../interface/user.interface";
import UserCard from "../../components/Cards/UserCard";
import UserGigsCarousel from "../../components/UserGigsCarousel";

import { getUserInfo } from "../../api/user-info/user-info";
import AboutUserCard from "../../components/Cards/AboutUserCard";
import UserDataCard from "../../components/Cards/UserDataCard";
import ReviewsSection from "../../../reviews/components/ReviewsSection";
import { Spinner } from "../../../../components/Spinner";
import { Gig } from "../../../gigs/interface/gig.interface";
import { Button } from "react-bootstrap";

const ProfilePage = function () {
  const params = useParams();
  const navigate = useNavigate();
  const { userId, membership } = useAppSelector((state) => state.user);
  const [activeGigs, setActiveGigs] = useState<Gig[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUser, setIsUser] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<User>({
    _id: "",
    active: false,
    firstName: "",
    lastName: "",
    userPhoto: "",
    aboutMe: "",
    languages: "",
    certifications: "",
    education: "",
    email: "",
    gigs: [],
    receivedReviews: [],
    sentReviews: [],
    createdAt: "",
    membership: { name: "", maxGigs: 0 },
  });

  useEffect(() => {
    setLoading(true);
    setIsUser(false);
    getUserInfo(params.id!)
      .then((results: { foundUser: User; activeGigs: Gig[] }) => {
        const { foundUser, activeGigs } = results;
        if (foundUser._id === userId) {
          setIsUser(true);
        }
        setActiveGigs(activeGigs);
        setUserInfo(foundUser);
      })
      .catch((error: Error) => {
        console.log(error);
        window.location.replace("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id, userId]);
  return (
    <div className="">
      {!loading ? (
        <div className="container mt-4">
          {userInfo._id && (
            <div className="d-flex flex-column">
              <div className="card d-flex flex-column mb-3">
                <div className="col-12 d-flex flex-column flex-md-row align-items-start border-bottom">
                  <div className="col-12 col-md-3 border-end">
                    <UserCard user={userInfo} isUser={isUser} />
                    {isUser && (
                      <div>
                        <div
                          className="border-bottom mx-4 d-flex justify-content-between mb-2"
                          style={{ fontWeight: 500 }}
                        >
                          <p className="m-0">סוג חשבון:</p>
                          <p className="m-0">
                            {membership?.name === "FREE" && "חינם"}
                            {membership?.name === "MID" && "מדיום"}
                            {membership?.name === "PREMIUM" && "פרימיום"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-around pb-2 mx-2">
                          <Button
                            size="sm"
                            variant={`primary`}
                            onClick={() => navigate("/plans/pricing")}
                          >
                            שינוי תוכנית
                          </Button>
                          <Button
                            size="sm"
                            onClick={() => navigate("/plans/cancel")}
                            variant={`outline-danger ${
                              membership?.name === "FREE" && "disabled"
                            }`}
                          >
                            הפסקת תשלום
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-9">
                    <div className="mx-2">
                      <AboutUserCard
                        aboutMe={userInfo.aboutMe}
                        isUser={isUser}
                      />
                    </div>
                  </div>
                </div>
                <UserDataCard userInfo={userInfo} isUser={isUser} />
              </div>
              {userInfo.active ? (
                <Fragment>
                  <div className="col-12">
                    {userInfo.gigs && (
                      <div className="col-12 d-flex justify-content-center">
                        <UserGigsCarousel
                          userGigs={activeGigs}
                          isUser={isUser}
                          userId={userId}
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column flex-md-row mt-2">
                    <div className="d-flex flex-column col-12">
                      <div className="container">
                        <ReviewsSection
                          reviews={userInfo.receivedReviews}
                          header="ביקורות על המוכר:"
                          noReviewsText="אין עדיין ביקורות על מוכר זה"
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="mt-5 mb-5">
                  <h2 className="text-center text-danger">משתמש זה מושהה</h2>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default ProfilePage;
