const ProgressBar = function (props: { progress: number }) {
  return (
    <div className="position-relative m-4">
      <div
        className="progress"
        role="progressbar"
        aria-label="Progress"
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ height: "1px" }}
      >
        <div
          className="progress-bar bg-danger"
          style={{ width: `${props.progress}%` }}
        ></div>
      </div>
      <button
        type="button"
        className={`position-absolute top-0 start-0 translate-middle btn btn-sm rounded-pill ${
          props.progress >= 0 ? "btn-danger" : "btn-secondary"
        }`}
        style={{ width: "2rem", height: "2rem" }}
      >
        1
      </button>
      <button
        type="button"
        className={`position-absolute top-0 start-50 translate-middle btn btn-sm rounded-pill ${
          props.progress >= 50 ? "btn-danger" : "btn-secondary"
        }`}
        style={{ width: "2rem", height: "2rem" }}
      >
        2
      </button>
      <button
        type="button"
        className={`position-absolute top-0 start-100 translate-middle btn btn-sm rounded-pill ${
          props.progress === 100 ? "btn-danger" : "btn-secondary"
        }`}
        style={{ width: "2rem", height: "2rem" }}
      >
        3
      </button>
    </div>
  );
};

export default ProgressBar;
