import LatestGigs from "./LatestGigs";
import { useAppSelector } from "../../../../store/hooks";
const LoggedInHeroSection = function () {
  const { firstName } = useAppSelector((state) => state.user);
  return (
    <div
      className="border-top"
      style={{
        background:
          "url(https://res.cloudinary.com/dm20uwmki/image/upload/v1694687056/haltu_assets/logged-in-background2_w3ovxw.png) no-repeat top center",
        height: "100vh",
      }}
    >
      <div
        className="d-flex flex-column align-items-center text-light"
        style={{ marginTop: "50px" }}
      >
        <div className="mx-3 mx-md-0">
          <h1 style={{ fontSize: "52px" }}>שלום, {firstName}</h1>
          <h1 style={{ fontSize: "52px" }} className={`mb-5`}>
            עכשיו אפשר לבחור שירותים שיתאימו אליך{" "}
            <span style={{ color: "#ff4e5b" }}>בול.</span>
          </h1>
        </div>
        <div className={`mt-0 mt-md-3 col-12 col-md-12 col-xxl-10`}>
          <div className={`mt-0 mt-md-3 text-dark`}>
            <LatestGigs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedInHeroSection;
