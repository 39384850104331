const WorkingTogether = () => {
  return (
    <div className="col-12 d-flex flex-column align-items-center">
      <div className="col-10 col-md-8 text-center d-flex flex-column align-items-center">
        <h1>אם נעבוד יחד, נוכל להגיע לתוצאות מרשימות</h1>
        <p className="text-secondary col-12 col-md-8">
          בחלטו אנו שמים דגש על החשיבות שבעבודה צמודה ושיתוף פעולה. כאשר מתחילים
          פרויקט חדש היכולות הייחודיות של כל אחד מתממשות בצורה מושלמת. זו דרך
          שבה נוכל לייצר עבודה איכותית ולהעניק ערך מוסף. באמצעות הכלים המתקדמים
          בפלטפורמה, תוכלו לתקשר ולעבוד יחד בצורה אפקטיבית, תקדישו פחות זמן
          ומשאבים, ותבטיחו שהתוצאות יתאימו בדיוק אליכם. ככה נוכל ליצור שביעות
          רצון רבה ותוצאות מרשימות לכל הצדדים בפלטפורמה.
        </p>
      </div>
      <div
        className="d-flex justify-content-center col-11 col-md-9"
        style={{
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.8)",
          borderRadius: "18px",
        }}
      >
        <img
          style={{ borderRadius: "18px" }}
          className="col-10 col-md-6"
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694634100/haltu_assets/Good_team-pana-no-bg3_nombms.svg"
          alt="עבודת צוות"
        />
      </div>
    </div>
  );
};

export default WorkingTogether;
