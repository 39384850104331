import { useState } from "react";
import { useLocation } from "react-router-dom";
import { addDots } from "../../../../utils/trimmers";

import SummaryCard from "../../components/SummaryCard";
import TextArea from "../../../../components/Forms/TextArea";
import { Gig } from "../../../gigs/interface/gig.interface";
import FileInput from "../../../../components/Forms/FileInput";
import ReviewsSum from "../../../reviews/components/ReviewsSum";

const defaultImage =
  "https://res.cloudinary.com/dm20uwmki/image/upload/v1690181148/haltu_assets/no_image_found_jchi3o.png";

const SummaryPage = function () {
  const location = useLocation();
  const gig: Gig = location.state;
  const [uploadedFiles, setUploadedFiles] = useState<string[]>(["", "", ""]);
  const [description, setDescription] = useState<string>("");

  const onChangeHandler = function (e: any) {
    const { value } = e.target;
    setDescription(value);
  };

  return (
    <div className="container" style={{ minHeight: "80vh" }}>
      <div className="d-flex flex-column flex-lg-row mt-5">
        <div className="col-12 col-lg-8">
          <div className="d-flex flex-column">
            <div
              className="d-flex border-bottom"
              style={{ paddingBottom: "5px" }}
            >
              <img
                src={gig.imagesUrls![0] || defaultImage}
                alt="תמונת הג'וב"
                style={{ width: "150px", height: "100px" }}
                className="col-3 rounded"
              />
              <div className="d-flex flex-column mx-3 col-3 col-md-7">
                <h5>{addDots(gig.title, 15)}</h5>
                <ReviewsSum receivedReviews={gig.userId.receivedReviews} />
              </div>
              <p className="fs-6 text-secondary">₪{gig.price}</p>
            </div>
            <div>
              <div className="d-flex flex-column mt-2">
                {gig.requirements && (
                  <div>
                    <h5>דרישות המוכר להשלמת הג'וב:</h5>
                    <p style={{ width: "200px" }}>{gig.requirements}</p>
                  </div>
                )}
                <div className="col-12 col-lg-10">
                  <TextArea
                    id="description"
                    text="תאר את ההזמנה:"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="d-flex flex-column col-12">
                  <div className="col-4">
                    <p className="m-0">
                      העלאת תמונות לדוגמה:
                      <span
                        className="text-secondary"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {" "}
                        (לא חייב)
                      </span>
                    </p>
                  </div>
                  <div
                    className="row col-12 mt-3 mb-3"
                    style={{ height: "200px" }}
                  >
                    {uploadedFiles.map((file, index) => {
                      return (
                        <div className="col-4 mt-3 mt-md-0" key={index}>
                          <FileInput
                            key={index}
                            uploadedFiles={uploadedFiles}
                            index={index}
                            setUploadedFiles={setUploadedFiles}
                            overlayPosition="557px"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <SummaryCard
            gig={gig}
            description={description}
            total={gig.price}
            uploadedFiles={uploadedFiles}
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryPage;
