import { useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Membership } from "../../user/interface/user.interface";
import { subscribeToMid } from "../api/subscription-mid";
import MidPaymentCard from "../components/Payments/MidPaymentCard";

const MidTierPayment = function () {
  const midPlan = Membership.mid;
  const { membership, userId } = useAppSelector((state) => state.user);

  const approveHandler = function (subscriptionID: string) {
    return subscribeToMid(subscriptionID, userId);
  };

  useEffect(() => {
    if (membership?.name === midPlan.name) {
      window.location.replace("/");
    }
  }, [membership?.name, midPlan.name]);

  return (
    <div className="mt-5 p-3 p-md-5 container " style={{ minHeight: "100vh" }}>
      <div className="mt-5 d-flex justify-content-center">
        <MidPaymentCard approveHandler={approveHandler} />
      </div>
    </div>
  );
};

export default MidTierPayment;
