import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const registerSchema = Yup.object().shape({
  firstName: Yup.string().max(15, "15 תווים מקסימום").required("שדה חובה"),
  lastName: Yup.string().max(15, "15 תווים מקסימום").required("שדה חובה"),
  email: Yup.string().email("יש לרשום אימייל תקני!").required("שדה חובה"),
  password: Yup.string()
    .min(8, "חייב להיות בין 8 ל 16 תווים")
    .max(16, "חייב להיות בין 8 ל 16 תווים")
    .matches(passwordRules, { message: "יש ליצור סיסמה חזקה" })
    .required("שדה חובה"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "סיסמאות לא תואמות")
    .required("שדה חובה"),
  termsAgreement: Yup.boolean().isTrue("חובה לאשר את תנאי השימוש"),
});
