import { baseUrl } from "./base-urls";

export const searchDB = function (query: string) {
  return fetch(`${baseUrl}/search?query=${query}`)
    .then((results) => {
      if (results.ok) {
        return results.json();
      } else throw new Error();
    })
    .catch((error) => {
      console.log(error);
    });
};
