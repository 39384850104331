import { baseUrl } from "../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const askForReset = function (email: string) {
  return fetch(`${URL}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
};
