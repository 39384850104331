import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { isValidUrl } from "../../../utils/validators";
import { uploadDownloadLinkOrder } from "../api/order-upload";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Order } from "../interface/order.interface";
import UploadModal from "./Modals/UploadModal";
const SellerDownload = function (props: {
  userId: string | null;
  order: Order;
}) {
  const navigate = useNavigate();
  const { userId, order } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [errState, setErrState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const submitHandler = function () {
    setLoading(true);
    uploadDownloadLinkOrder(userId!, order._id!, input)
      .then((results) => {
        if (!results.ok) {
          throw new Error("Error");
        }
        navigate(`/users/${userId}/orders`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };
  return (
    <div>
      {order.status !== "PENDING" && (
        <div className="mt-3">
          <h5>קישור למוצר / שירות:</h5>
          <div className="row">
            <div className="col-9 col-md-10">
              <input
                className={`form-control ${errState && "is-invalid"}`}
                type="text"
                defaultValue={order.downloadLink}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInput(e.target.value)
                }
              />
            </div>
            {order.active && (
              <Button
                variant={`danger ${!input && "disabled"}`}
                className="col-2 col-md-1"
                type="button"
                onClick={() => {
                  if (!input || !isValidUrl(input)) {
                    setErrState(true);
                    return;
                  } else {
                    setShowModal(true);
                  }
                }}
              >
                <FontAwesomeIcon icon={faUpload} />
              </Button>
            )}
          </div>
          <UploadModal
            open={showModal}
            onClose={() => setShowModal(false)}
            onApprove={() => submitHandler()}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default SellerDownload;
