type ExpectedInput = string | undefined | null;

export const isValidUrl = (urlString: ExpectedInput) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + //
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!urlPattern.test(urlString!);
};

export const checkIfEmpty = (value: ExpectedInput) => value!.trim() !== "";

export const checkLength = (enteredValue: ExpectedInput) => {
  let isValid = true;
  isValid = isValid && enteredValue!.trim().length >= 5;
  isValid = isValid && enteredValue!.trim().length <= 16;
  return isValid;
};

export const checkIfMatch = (
  firstValue: ExpectedInput,
  secondValue: ExpectedInput
) => {
  return firstValue === secondValue;
};

export const checkEmail = (value: ExpectedInput) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value!
  );
