const CarouselImage = function (props: {
  imageUrl?: string;
  photoIndex?: number;
  width?: string;
  height?: string;
  extraClass?: string;
  setModalSrc?: (imgSrc: string) => void;
  openImageModal?: () => void;
}) {
  const defaultImage =
    "https://res.cloudinary.com/dm20uwmki/image/upload/v1690181148/haltu_assets/no_image_found_jchi3o.png";
  if (props.photoIndex === 0) {
    return (
      <div className="carousel-item active" data-bs-interval="30000">
        <img
          src={props.imageUrl || defaultImage}
          className={`d-block w-100 rounded ${props.extraClass}`}
          alt="..."
          style={{
            width: props.width,
            height: props.height,
            cursor: props.imageUrl ? "pointer" : "cursor",
          }}
          onClick={() => {
            if (props.imageUrl && props.setModalSrc && props.openImageModal) {
              props.openImageModal();
              props.setModalSrc(props.imageUrl);
            }
          }}
        />
        <div className="carousel-caption d-none d-md-block"></div>
      </div>
    );
  } else {
    return (
      <div className="carousel-item" data-bs-interval="3000">
        <img
          src={props.imageUrl || defaultImage}
          className={`d-block w-100 rounded ${props.extraClass}`}
          alt="..."
          style={{
            width: props.width,
            height: props.height,
            cursor: props.imageUrl ? "pointer" : "cursor",
          }}
          onClick={() => {
            if (props.imageUrl && props.setModalSrc && props.openImageModal) {
              props.openImageModal();
              props.setModalSrc(props.imageUrl);
            }
          }}
        />
        <div className="carousel-caption d-none d-md-block"></div>
      </div>
    );
  }
};

export default CarouselImage;
