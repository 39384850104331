const IndicatorButton = function (props: {
  carouselId: string;
  photoIndex: number;
}) {
  if (props.photoIndex === 0) {
    return (
      <button
        type="button"
        data-bs-target={`#${props.carouselId}`}
        data-bs-slide-to={props.photoIndex}
        className="active"
        aria-current="true"
        aria-label={`Slide ${props.photoIndex + 1}`}
      ></button>
    );
  } else {
    return (
      <button
        type="button"
        data-bs-target={props.carouselId}
        data-bs-slide-to={props.photoIndex}
        aria-label={`Slide ${props.photoIndex + 1}`}
      ></button>
    );
  }
};

export default IndicatorButton;
