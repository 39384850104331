import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const createOrderComment = function (
  commentBody: string,
  commentSender: string,
  orderId: string
) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${commentSender}/orders/${orderId}/add-comment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({
      commentBody: commentBody,
      commentSender: commentSender,
    }),
  });
};
