import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useAppSelector } from "../../../../store/hooks";

const ReadyToCreateGig = () => {
  const { userId, verified } = useAppSelector((state) => state.user);
  return (
    <div className="d-flex justify-content-center">
      <div
        style={{ background: "linear-gradient(to left, #313131, #cfcfcf)" }}
        className="col-10 col-md-10 col-xxl-8 p-5 p-md-0 rounded d-flex flex-column flex-md-row justify-content-center"
      >
        <div
          style={{ color: "#313131" }}
          className="m-0 m-md-5 col-12 col-md-5 d-flex flex-column justify-content-center justify-content-md-start text-center text-light"
        >
          <h1>
            פרילנסרים? <br /> התחילו לפרסם שירותים חדשים!
          </h1>
          {verified ? (
            <Link
              to={`/users/${userId}/create-new-gig`}
              className="col-12 col-md-6 btn btn-danger align-self-center p-2 mt-4"
            >
              יצירה
            </Link>
          ) : (
            <Button variant="success disabled" className="p-2">
              חשבון לא מאומת
            </Button>
          )}
        </div>
        <div className="d-none d-md-flex justify-content-start col-5">
          <img
            className="mx-5"
            style={{ width: "50%" }}
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694639914/haltu_assets/Launching-pana-no-bg-dark2_nuelkp.svg"
            alt="התחלה"
          />
        </div>
      </div>
    </div>
  );
};

export default ReadyToCreateGig;
