import { useState, useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Order } from "../interface/order.interface";
import { useParams, useNavigate } from "react-router-dom";

import { getAllOrders } from "../api/order-read";
import { Spinner } from "../../../components/Spinner";
import SoldOrders from "../components/Info/SoldOrders";
import BoughtOrders from "../components/Info/BoughtOrders";
import { Socket } from "socket.io-client";

const UserOrdersPage = function (props: { socket: Socket }) {
  const { socket } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { userId, authCheck } = useAppSelector((state) => state.user);
  const [reload, setReload] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[] | null>(null);

  let soldNotifications: number = 0;
  let boughtNotifications: number = 0;

  if (orders?.length) {
    orders.map((order) => {
      if (order.status === "PENDING" && order.sellerId._id === userId) {
        return soldNotifications++;
      } else if (order.status === "AWAITING" && order.buyerId._id === userId) {
        return boughtNotifications++;
      } else {
        return null;
      }
    });
  }

  socket.on("newOrderEvent", (data) => {
    const { buyerId, sellerId } = data;
    if (userId !== buyerId && userId !== sellerId) {
      return;
    } else {
      const timer = setTimeout(() => {
        setReload(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  });

  const boughtOrders = orders?.length
    ? orders?.filter(
        (order) => order.buyerId._id.toString() === userId!.toString()
      )
    : null;
  const soldOrders = orders?.length
    ? orders?.filter(
        (order) => order.buyerId._id.toString() !== userId!.toString()
      )
    : null;
  const [toggleBoS, setToggleBoS] = useState<boolean>(true);

  useEffect(() => {
    if (!authCheck) {
      navigate("/");
    } else if (userId !== id) {
      navigate("/");
    }
    if (reload) {
      getAllOrders(userId)
        .then((allOrders: Order[]) => {
          setOrders(allOrders);
        })
        .catch((error) => {
          console.log(error);
          window.location.replace("/404");
        })
        .finally(() => {
          setReload(false);
          setLoading(false);
        });
    }
  }, [userId, id, navigate, reload, authCheck]);

  return (
    <div className="mx-1">
      {!loading && orders ? (
        <>
          <div
            className="mt-3 d-flex flex-column"
            style={{ minHeight: "100vh" }}
          >
            <h3 className="mx-3">ההזמנות שלי:</h3>
            <div className="mt-3 d-flex flex-column flex-md-row">
              <div className="mt-1 mx-0 mx-md-3 col-12 col-md-2 d-flex flex-row flex-md-column align-items-center justify-content-around justify-content-md-start card">
                <div
                  className={`d-flex align-items-center justify-content-between mb-2 mt-2 col-5 col-md-10 order-toggle-btn text-start ${
                    toggleBoS ? "btn btn-danger" : "text-secondary"
                  }`}
                  onClick={() => setToggleBoS(true)}
                >
                  <span>רכישות</span>
                  {boughtNotifications ? (
                    <div
                      className={`mt-1 d-flex justify-content-center align-items-center ${
                        !toggleBoS
                          ? "bg-danger text-light"
                          : "bg-light text-dark"
                      }`}
                      style={{
                        fontSize: "14px",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <span>{boughtNotifications}</span>
                    </div>
                  ) : null}
                </div>
                <div
                  className={`d-flex align-items-center justify-content-between mb-2 mt-2 col-5 col-md-10 order-toggle-btn text-start ${
                    !toggleBoS ? "btn btn-danger" : "text-secondary"
                  }`}
                  onClick={() => setToggleBoS(false)}
                >
                  <span>מכירות</span>
                  {soldNotifications ? (
                    <div
                      className={`mt-1 d-flex justify-content-center align-items-center ${
                        toggleBoS
                          ? "bg-danger text-light"
                          : "bg-light text-dark"
                      }`}
                      style={{
                        fontSize: "14px",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    >
                      <span>{soldNotifications}</span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-1 mx-0 mx-md-3 card col-12 col-md-9">
                {boughtOrders && soldOrders ? (
                  <div>
                    {!toggleBoS ? (
                      <SoldOrders orders={soldOrders} />
                    ) : (
                      <BoughtOrders orders={boughtOrders} />
                    )}
                  </div>
                ) : (
                  <div
                    style={{ minHeight: "505px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h2>לא מצאנו הזמנות למשתמש זה</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default UserOrdersPage;
