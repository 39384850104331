import { useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { createUserMessage } from "../../api/user-chat-create";
import { Button, Form, Spinner } from "react-bootstrap";

const MessageForm = function (props: { to: string }) {
  const { userId } = useAppSelector((state) => state.user);
  const [input, setInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errState, setErrState] = useState<boolean>(false);

  const submitHanlder = function (e: React.FormEvent) {
    e.preventDefault();
    if (!input) {
      setErrState(true);
      return;
    }
    if (!loading) {
      setLoading(true);
      if (userId === null) return;
      createUserMessage(props.to, input, userId)
        .then((results) => {
          setInput("");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Form
      noValidate
      onSubmit={submitHanlder}
      className="d-flex form-control"
      style={{ borderRadius: "22px" }}
    >
      <input
        id="message"
        type="text"
        value={input}
        style={{ border: "none", outline: "none", borderRadius: "22px" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setErrState(false);
          setInput(e.target.value);
        }}
        className={`form-control ${errState && "is-invalid"}`}
      />
      <div style={{ marginRight: "15px" }}>
        <Button
          type="submit"
          style={{ borderRadius: "50%" }}
          variant={`danger ${
            errState || input === "" || loading ? "disabled" : null
          }`}
        >
          {loading ? (
            <Spinner variant="light" size="sm" />
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} />
          )}
        </Button>
      </div>
    </Form>
  );
};

export default MessageForm;
