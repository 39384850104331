const FirstSection = function () {
  return (
    <div>
      <h5>הצהרת הפרטיות בחלטו</h5>
      <p>
        אנו מחויבים להגן על הפרטיות והאבטחה של המידע האישי של המשתמשים שלנו.
        מדיניות פרטיות זו ("מדיניות") מסבירה כיצד אנו אוספים, משתמשים וחושפים את
        המידע האישי שלך כאשר אתה משתמש באתר או בשירותים שלנו.
      </p>
    </div>
  );
};

export default FirstSection;
