// import { useAppSelector } from "../store/hooks";
import { Routes, Route, Navigate } from "react-router-dom";

import ChatPage from "../features/chat/pages/ChatPage";
import LoginPage from "../features/user/pages/LoginPage";
import ReviewPage from "../features/reviews/pages/ReviewPage";
import RegisterPage from "../features/user/pages/RegisterPage";
import { GigCreation } from "../features/gigs/pages/GigCreation";
import UserOrdersPage from "../features/orders/pages/UserOrdersPage";
import FavoritesPage from "../features/favorites/pages/FavoritesPage";
import ProfilePage from "../features/user/pages/UserInfo/ProfilePage";
import SingleOrderPage from "../features/orders/pages/SingleOrderPage";
import ResetPassPage from "../features/user/pages/Password/ResetPassPage";
import PassChangePage from "../features/user/pages/Password/PassChangePage";
import VerificationPage from "../features/user/pages/UserInfo/VerificationPage";
import NotFound from "../generalPages/NotFound";

import { Category } from "../features/categories/interface/category.interface";

import { Socket } from "socket.io-client";
import GigUpdate from "../features/gigs/pages/GigUpdate";

interface UserRoutesProps {
  authCheck?: boolean;
  categories: Category[];
  socket: Socket;
}

const UserRoutes = function (props: UserRoutesProps) {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route
        path="register"
        element={
          props.authCheck ? <Navigate to="/" replace /> : <RegisterPage />
        }
      />
      <Route path=":id" element={<ProfilePage />} />
      <Route
        path=":id/create-new-gig"
        element={
          props.authCheck ? (
            <GigCreation categories={props.categories} />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />
      <Route
        path=":id/edit-job"
        element={
          props.authCheck ? (
            <GigUpdate />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />

      <Route path=":id/favorites" element={<FavoritesPage />} />
      <Route
        path=":id/orders"
        element={<UserOrdersPage socket={props.socket} />}
      />
      <Route
        path=":id/orders/:orderId"
        element={<SingleOrderPage socket={props.socket} />}
      />
      <Route path=":id/orders/:orderId/review" element={<ReviewPage />} />
      <Route path=":id/chat" element={<ChatPage socket={props.socket} />} />
      <Route
        path="reset-password"
        element={
          props.authCheck ? <Navigate to="/" replace /> : <ResetPassPage />
        }
      />
      <Route path="reset-password/:token" element={<PassChangePage />} />
      <Route path="verify/:token" element={<VerificationPage />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default UserRoutes;
