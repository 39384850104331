import { Button, Modal, Spinner } from "react-bootstrap";

const DeletionModal = function (props: {
  loading: boolean;
  closeModal?: () => void;
  showDeletionModal?: boolean;
  onGigDelete: () => void;
}) {
  const { closeModal, showDeletionModal, onGigDelete, loading } = props;
  return (
    <Modal show={showDeletionModal} onHide={closeModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>מחיקת ג'וב</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div>
          <h5>האם אתה בטוח שברצונך למחוק עמוד ג'וב זה?</h5>
          <p className="text-secondary">
            לא יהיה ניתן לשחזר עמוד ג'וב אשר נמחק.
          </p>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <Button variant="primary" className="mx-2 col-4" onClick={closeModal}>
            ביטול
          </Button>
          <Button
            variant={`danger ${loading ? "disabled" : ""}`}
            className="mx-2 col-4"
            onClick={onGigDelete}
          >
            {loading ? <Spinner size="sm" /> : "מחיקת הג'וב"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletionModal;
