import { Link } from "react-router-dom";
import { User } from "../../../user/interface/user.interface";
import UserImage from "../../../user/components/Image/UserImage";
import { Button } from "react-bootstrap";
import { addDots } from "../../../../utils/trimmers";
import ReviewsSum from "../../../reviews/components/ReviewsSum";
import VerificationBadge from "../../../user/components/VerificationBadge";

const GigUser = function (props: {
  user: User;
  openChat: () => void;
  isSeller: boolean;
}) {
  const { user, openChat, isSeller } = props;
  // const date = new Date(Date.parse(user.createdAt));
  // const year = date.getFullYear();
  // const month = date.toLocaleString("heb", { month: "short" });
  return (
    <div className="card">
      <div className="d-flex flex-column align-items-center m-2 border-bottom pb-3">
        <UserImage user={user} canEdit={false} size={100} showOnline={true} />
        <div className="col-12 mx-3 d-flex flex-column align-items-center">
          <div className="d-flex align-items-center">
            <VerificationBadge idVerification={user.idVerification} />
            <Link
              className="fw-semibold link-dark mx-2"
              style={{ textDecoration: "none" }}
              to={`/users/${user._id}`}
            >
              {user.firstName} {user.lastName}
            </Link>
          </div>
          <div className="mt-1">
            <ReviewsSum receivedReviews={user.receivedReviews} />
          </div>
          <Button
            variant={`danger ${isSeller && "disabled"}`}
            className="col-10"
            type="button"
            onClick={openChat}
          >
            צור קשר
          </Button>
        </div>
      </div>
      <div className="card-body">
        <p className="mt-2">{user.aboutMe && addDots(user.aboutMe, 70)}</p>
      </div>
    </div>
  );
};

export default GigUser;
