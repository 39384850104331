import { configureStore } from "@reduxjs/toolkit";

import categorySlice from "./slices/category-slice/category-slice";
import favoritesSlice from "./slices/user/favorites-slice";
import userSlice from "./slices/user/user-slice";
import uiSlice from "./slices/ui/ui-slice";

const store = configureStore({
  reducer: {
    categories: categorySlice.reducer,
    favorites: favoritesSlice.reducer,
    user: userSlice.reducer,
    ui: uiSlice.reducer,
  },
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
