export const JuristrictionBoundment = () => {
  return (
    <div>
      <h2 className="terms-header">דין ושיפוט</h2>
      <p>
        תנאי השימוש אלו נחלקים על פי חוקי ישראל ומופרשים בהתאם לו. כל סכסוכים
        שנגרמו משימוש באתר יחדלו לסמכותה הבלעדית של בתי המשפט בישראל.
      </p>
    </div>
  );
};
