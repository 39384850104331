import React from "react";

interface InputProps {
  id: string;
  type: string;
  text?: string;
  value?: string;
  placeholder?: string;
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: any) => void;
  extraClass?: string;
}

const Input = React.forwardRef((props: InputProps, ref: any) => {
  return (
    <div className="mb-3">
      <label htmlFor={props.id} className="form-label">
        {props.text}
      </label>
      <input
        autoComplete={props.autoComplete || "off"}
        type={props.type}
        className={`form-control ${props.extraClass}`}
        id={props.id}
        name={props.id}
        aria-describedby={`${props.id}Input`}
        placeholder={props.placeholder}
        ref={ref}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
});
export default Input;
