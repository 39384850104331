export const SubscriptionSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>מנויים ועמלות</h2>
      <p>
        חלטו מציעה תוכנית חינמית ותוכניות בתשלום בפלטפורמה. משתמשים יכולים לגשת
        לתוכנית החינמית מבלי לשלם כל דמי שימוש, אך הם יכולים לבחור לרכוש תוכנית
        מנוי ליהנות מיתרונות ותכונות נוספות בפלטפורמה. המחירים והתכונות של
        תוכניות המנוי עשויות להשתנות וכך כלולים בשיקול ענייני של חלטו. שינויים
        במחירים ותוספות ישלחו למשתמשים בזמן שיתרחשו.
      </p>
      <p>
        אתה מתחייב לשלם את כל החיובים הקשורים במנוי שבו בחרת בעיתוי החודשי שנקבע
        בעת רכישת המנוי.
      </p>
    </div>
  );
};
