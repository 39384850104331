import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User } from "../../../../user/interface/user.interface";
import UserImage from "../../../../user/components/Image/UserImage";

const MiniChatHeader = function (props: {
  user: User;
  closeModal: () => void;
}) {
  const { user, closeModal } = props;
  return (
    <div className="p-2 row">
      <div className="col-8 d-flex align-items-center">
        <UserImage
          canEdit={false}
          user={user}
          size={36}
          fontSize="14px"
          showOnline={true}
        />
        <div style={{ fontWeight: "600" }} className="mx-2">
          {user.firstName} {user.lastName}
        </div>
      </div>
      <div className="text-end col-4">
        <FontAwesomeIcon
          icon={faClose}
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export default MiniChatHeader;
