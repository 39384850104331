import { Link } from "react-router-dom";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const UserMaxGigs = function () {
  return (
    <Link
      to="/plans/pricing"
      className="card link-light d-flex align-items-center justify-content-center"
      style={{
        textDecoration: "none",
        height: "280px",
        width: "350px",
        marginLeft: "5px",
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div
          className="px-1 bg-danger d-flex align-items-center justify-content-center"
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            opacity: "0.8",
          }}
        >
          <span className="text-light">
            <FontAwesomeIcon icon={faArrowUp} />
          </span>
        </div>
        <p className="mt-4 text-dark text-center" style={{ fontWeight: "700" }}>
          מקסימום ג'ובים למשתמש זה.
          <br></br> ניתן ללחוץ כאן ולשדרג את החשבון!
        </p>
      </div>
    </Link>
  );
};

export default UserMaxGigs;
