import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchDB } from "../API/search";

import { Gig } from "../features/gigs/interface/gig.interface";
import { User } from "../features/user/interface/user.interface";
import { Category } from "../features/categories/interface/category.interface";

import SearchResults from "../components/SearchResults";
import { Spinner } from "../components/Spinner";

interface ISearchResults {
  users: User[] | [];
  gigs: Gig[] | [];
  categories: Category[] | [];
}

const SearchResultsPage = function () {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState<ISearchResults>({
    users: [],
    gigs: [],
    categories: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const query = searchParams.get("query");
  useEffect(() => {
    if (!query) {
      return;
    } else {
      setLoading(true);
      searchDB(query)
        .then((results) => {
          const { users, gigs, categories } = results;
          setSearchResults({
            users: users,
            gigs: gigs,
            categories: categories,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 1500);
        });
    }
  }, [query]);
  const { users, gigs, categories } = searchResults;
  return (
    <Fragment>
      {!loading ? (
        <div className="container" style={{ minHeight: "100vh" }}>
          {!users.length && !gigs.length && !categories.length ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "90vh", minWidth: "50vw" }}
            >
              <h3>לא נמצאו תוצאות עבור החיפוש שלך</h3>
              <div className="col-6 col-md-5 col-lg-5">
                <img
                  src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693238436/haltu_assets/Questions-pana-no-bg_dqjnbv.svg"
                  alt="אין תוצאות חיפוש"
                />
              </div>
            </div>
          ) : (
            <SearchResults
              users={searchResults.users}
              categories={searchResults.categories}
              gigs={searchResults.gigs}
            />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
};

export default SearchResultsPage;
