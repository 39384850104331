import { ChoosingServicesSection } from "./ClientsComponents/ChoosingServicesSection";
import { ClientsDataDiscretionSection } from "./ClientsComponents/ClientsDataDiscretionSection";
import { ClientsDisputesSection } from "./ClientsComponents/ClientsDisputesSection";
import { ClientsIntellectualPropertySection } from "./ClientsComponents/ClientsIntellectualPropertySection";
import { ClientsPaymentSection } from "./ClientsComponents/ClientsPaymentSection";
import { ClientsRealLifeMeetingsSection } from "./ClientsComponents/ClientsRealLifeMeetingsSection";
import { CLientsReviewsSection } from "./ClientsComponents/ClientsReviewsSection";
import { ClientsUnfairBehaviorSection } from "./ClientsComponents/ClientsUnfairBehaviorSection";
import { ExpectationsSection } from "./ClientsComponents/ExpectationsSection";
import { SchedulesSection } from "./ClientsComponents/SchedulesSection";

export const ClientsAgreement = () => {
  return (
    <div>
      <h2 className="terms-header">הסכם קליינטים</h2>
      <p style={{ fontWeight: 700 }}>
        על ידי הירשמות לאתר הנך מסכים להסכם לקוח זה עם חלטו, אתה מכיר ומסכים
        לתנאים המפורטים למטה לשימושך כלקוח בשירותים המסופקים דרך פלטפורמת
        haltu.co.il ("הפלטפורמה").
      </p>
      <p>
        חלטו שומרת לעצמה את הזכות לשנות, לעדכן, או לשנות את התנאים וההגבלות הללו
        בכל עת מבלי לספק הודעה מראש. זהו עליך לבדוק מעת לעת את התנאים האלו
        לשינויים. השימוש המתמשך באתר לאחר שנעשו שינויים מהווה את הסכמתך לתנאים
        המעודכנים.
      </p>
      <ChoosingServicesSection />
      <ClientsPaymentSection />
      <SchedulesSection />
      <ExpectationsSection />
      <ClientsDisputesSection />
      <ClientsIntellectualPropertySection />
      <ClientsRealLifeMeetingsSection />
      <ClientsDataDiscretionSection />
      <ClientsUnfairBehaviorSection />
      <CLientsReviewsSection />
    </div>
  );
};
