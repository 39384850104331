import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Button } from "react-bootstrap";
import { Fragment } from "react";

const NoFoundGigs = function () {
  const { userId, verified, authCheck } = useAppSelector((state) => state.user);
  return (
    <div className="d-flex flex-row justify-content-center mx-3 mx-md-0">
      <div className="mx-5 d-flex flex-column align-items-center justify-content-center">
        <h3 className="text-center">
          נראה כאילו לא יצרו עוד ג'ובים מסוג זה...
        </h3>
        <img
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693239177/haltu_assets/Startup_life-pana-no-bg2_bhhzpu.svg"
          alt="לא נמצאו ג'ובים"
          style={{ width: "45vh" }}
        />

        {authCheck ? (
          <Fragment>
            {verified ? (
              <Link
                to={`/users/${userId}/create-new-gig`}
                className="mt-2 mb-2 btn btn-primary"
              >
                בואו להיות הראשונים ליצור!
              </Link>
            ) : (
              <Button variant="primary disabled">חשבון לא מאומת</Button>
            )}
          </Fragment>
        ) : (
          <Link to="/users/login" className="btn btn-danger">
            התחברו וצרו ג'וב חדש!
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoFoundGigs;
