import { Link } from "react-router-dom";
import Input from "../../../../components/Forms/Input";
import { Button, Spinner } from "react-bootstrap";
import { Fragment, useRef, useState } from "react";
import { LoginProps } from "../../../../interfaces/Forms/LoginProps";
import { checkEmail, checkLength } from "../../../../utils/validators-new";
import { useAppDispatch } from "../../../../store/hooks";
import { userActions } from "../../../../store/slices/user/user-slice";

function LoginForm(props: LoginProps) {
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [errState, setErrState] = useState<string>("");
  function resetErrors() {
    setErrState("");
    dispatch(userActions.resetError());
  }

  function passLoginInfo(event: React.FormEvent) {
    event.preventDefault();
    let validForm = false;
    const currentEmail = emailInputRef.current!.value;
    const currentPass = passwordInputRef.current!.value;
    const userInfo = {
      email: checkEmail(currentEmail),
      password: checkLength(currentPass),
    };
    for (const [key, value] of Object.entries(userInfo)) {
      if (value === false) {
        validForm = false;
        setErrState(key);
        break;
      }
      validForm = true;
    }
    if (validForm) {
      props.onLogin({
        email: emailInputRef.current!.value,
        password: passwordInputRef.current!.value,
      });
    }
  }
  return (
    <Fragment>
      <form
        onSubmit={passLoginInfo}
        className="d-flex flex-column"
        onChange={resetErrors}
      >
        <Input
          id="email"
          autoComplete="on"
          type="text"
          text="אימייל"
          ref={emailInputRef}
          extraClass={errState === "email" ? "is-invalid" : ""}
        />
        {errState === "email" && (
          <p className="text-danger">יש להקיש איימיל תקין!</p>
        )}
        <Input
          id="password"
          autoComplete="on"
          type="password"
          text="סיסמה"
          ref={passwordInputRef}
          extraClass={errState === "password" ? "is-invalid" : ""}
        />
        {errState === "password" && (
          <p className="text-danger">יש להקיש סיסמא!</p>
        )}
        <div className="mb-3 text-end">
          <Link
            to="/users/reset-password"
            className="mb-3 link-danger"
            style={{ textDecoration: "none" }}
          >
            שכחתי סיסמה
          </Link>
        </div>
        {props.serverError && (
          <p className="text-danger text-center">
            אחד או יותר מפרטי הזיהוי שהקשת שגויים
          </p>
        )}
        <Button type="submit" variant={`danger ${props.loading && "disabled"}`}>
          {props.loading ? (
            <Spinner variant="light" className="spinner-size" />
          ) : (
            "התחברות"
          )}
        </Button>
      </form>
    </Fragment>
  );
}

export default LoginForm;
