export const UserTerminationSectionn = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>סירוב מתן שימוש באתר</h2>
      <p>
        חלטו שומרת לעצמה את הזכות להשבית או להשעות את חשבונך באתר בכל עת ולכל
        סיבה, לא הודעה מראש או הסבר, כאשר נעשה זאת אתה מחוייב להפסיק לאלתר כל
        שימוש באתר.מקרה זה כולל, אך אינו מוגבל למקרים בהם אתה פוגע בתנאים
        והגבלות אלו, נוהג בהתנהגות לא ראויה, או מהווה סיכון לבטחון או לחווית
        האתר או המשתמשים שבו. במקרה של השבתת החשבון, לא תהיה לך עוד גישה לתכני
        ו-או שירותים המסופקים על ידי האתר. אתה מכיר כי חלטו אינה אחראית לך ולכל
        צד ג' לגבי כל שנעשה בשל השבתת החשבון שלך. בנוסף, אתה יכול לבחור להשבית
        את החשבון שלך בכל עת על ידי הודעה בכתב לחלטו. בכל זאת, כל תוכן שהגשת או
        העלית לאתר עשוי להימשך לשימוש לפי תנאים והגבלות אלו גם לאחר השבתת החשבון
        שלך
      </p>
    </div>
  );
};
