export const ClientsPaymentSection = () => {
  return (
    <div>
      <h2>תשלומים</h2>
      <p>
        אתה מכיר כי תשלומים עבור מוצרים ושירותים יקבעו על ידי המחירים שנקבעו על
        ידי הפרילנסרים בפלטפורמה. אתה מסכים להשתמע לתנאי התשלום ואמצעיו שנקבעו
        עם הפרילנסר, ולוודא תשלומים במועדם בדיוק עבור המוצרים והשירותים שסופקו.
      </p>
    </div>
  );
};
