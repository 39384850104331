import { Routes, Route, Navigate } from "react-router-dom";
import PricingPage from "../generalPages/PricingPage/PricingPage";
import CancelPayment from "../features/membership/pages/CancelPayment";
import MidTierPayment from "../features/membership/pages/MidTierPayment";
import PremiumTierPayment from "../features/membership/pages/PremiumTierPayment";
import NotFound from "../generalPages/NotFound";

const PlansRoutes = function (props: { authCheck: boolean }) {
  return (
    <Routes>
      <Route path="pricing" element={<PricingPage />} />
      <Route
        path="cancel"
        element={
          props.authCheck ? (
            <CancelPayment />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />
      <Route
        path="mid/payment"
        element={
          props.authCheck ? (
            <MidTierPayment />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />
      <Route
        path="premium/payment"
        element={
          props.authCheck ? (
            <PremiumTierPayment />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default PlansRoutes;
