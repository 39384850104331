export const UserSuggestions = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>הצעות משתמשים</h2>
      <p>
        חלטו מעריכה משוב והצעות משתמשים לשיפור האתר ותכניו. בכל זאת, כל הצעה,
        רעיון או הצעה שנשלחת על ידי משתמשים לחלטו, באמצעות האתר או בכל דרך אחרת,
        נמסרת מרצון בלבד, ואין בה מצופה לפיצוי או לזכויות על כך. חלטו אינה
        מחויבת ליישם, לאמץ או לכלול הצעות שמסרו משתמשים. במקרה בו חלטו בוחרת
        לאמץ הצעה שהוצגה על ידי משתמש, המשתמש מכיר ומסכים שהוא אינו זכאי לפיצוי,
        לתשלום או לטענות בקשר לזכויות קניין רוחני הנובעות מהשיפור או מההתאמה
        שנעשתה בהתאם להצעה. בנוסף על כך, משתמשים מעניקים לחלטו זכות בלעדית, ללא
        דמי שום סוג, ללא הגבלה בזמן, ללא אפשרות לבטל את ההרשאה, ובאופן מוחלט
        לשימוש, לשינוי, להתאמה, לפרסום, לתרגום, ליצירת יצירות מופקות ממנה, להפצה
        ולהצגה של ההצעות ברחבי העולם ובכל אמצעי התקשורת.
      </p>
    </div>
  );
};
