import { User } from "../../../user/interface/user.interface";
import UserImage from "../../../user/components/Image/UserImage";

const MiniChatBtn = function (props: { gigUser: User; showModal: () => void }) {
  const { gigUser, showModal } = props;
  return (
    <div
      style={{
        zIndex: 9998,
        height: "50px",
        width: "160px",
        position: "fixed",
        borderRadius: "68px",
        background: "#ffffff",
        bottom: "5vh",
        right: "2vh",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
        cursor: "pointer",
      }}
      className="border d-flex align-items-center"
      onClick={showModal}
    >
      <div className="mx-3">
        <UserImage
          user={gigUser}
          canEdit={false}
          size={42}
          fontSize="14px"
          showOnline={true}
        />
      </div>
      <span className="text-secondary" style={{ fontWeight: "600" }}>
        צור קשר!
      </span>
    </div>
  );
};

export default MiniChatBtn;
