import ContactUs from "../../../components/ContactUs";
import { ClientsAgreement } from "./ClientsAgreement";
// import GigsSection from "./Components/GigsSection";
// import ReportSection from "./Components/ReportSection";
// import OrdersSection from "./Components/OrdersSection";
// import ReviewsSection from "./Components/ReviewsSection";
// import UserProtectionSection from "./Components/UserProtectionSection";
import { DisclaimerOfLiabilitySection } from "./Components/DisclaimerOfLiabilitySection";
import { EmailNotificationAcceptionSection } from "./Components/EmailNotificationAcceptionSection";
import { ForbiddenActivitySection } from "./Components/ForbiddenActivitySection";
import { IndemnificationSection } from "./Components/IndemnificationSection";
import { IntellectualPropertySection } from "./Components/IntellectualPropertySection";
import { JuristrictionBoundment } from "./Components/JuristrictionBoundment";
import { RealLifeMeetingsSection } from "./Components/RealLifeMeetingsSection";
import { SpamContentSection } from "./Components/SpamContentSection";
import { SubscriptionSection } from "./Components/SubscriptionSection";
import { ThirdPartyIntellectualPropertySection } from "./Components/ThirdPartyIntellectualPropertySection";
import { UploadedContentSection } from "./Components/UploadedContentSection";
import { UserBehaviorSection } from "./Components/UserBehaviorSection";
import { UserCreationSection } from "./Components/UserCreationSection";
import { UserDisputesSection } from "./Components/UserDisputesSection";
import { UserGeneratedContentSection } from "./Components/UserGeneratedContentSection";
import { UserIdentificationSection } from "./Components/UserIdentificationSection";
import { UserPaymentSection } from "./Components/UserPaymentSection";
import { UserProfilesSection } from "./Components/UserProfilesSection";
import { UserSuggestions } from "./Components/UserSuggestions";
import { UserTerminationSectionn } from "./Components/UserTerminationSection";
import { FreelanceAgreement } from "./FreelanceAgreement";

const TermsOfService = function () {
  return (
    <div className="mt-5 container">
      <h1>תנאי השימוש של חלטו</h1>
      <p>עדכון אחרון: ספטמבר 2023</p>
      <div style={{ fontWeight: "600" }}>
        <p>
          הסכמה לתנאים בכניסה ובשימוש ב-haltu.co.il ("האתר"), אתה מסכים להסכימה
          ולהתחייב בתנאים ובתנאי השימוש האלו. אם אינך מסכים לתנאים אלו, אנא אל
          תשתמש באתר.
        </p>
        <p>
          השפה של תנאי שימוש אלו, וגם כל טקסט אחר באתר היא עברית, תנאי השימוש
          מנוסחים בלשון זכר ויחיד אך ורק מטעמי נוחות ויש לראות בכתוב בהם כמתייחס
          לשני המינים. כותרות הסעיפים ניתנו לצורכי נוחות ואין ללמוד מהן לצורך
          פרשנות תנאי השימוש.
        </p>
        <h4 style={{ color: "#ff4e5b" }}>גיל המשתמשים</h4>
        <p>
          האתר מיועד לשימוש על ידי אנשים בגיל 18 שנים ומעלה המהווים אישיות
          משפטית הכשרה ליצור התחייבות חוזית. אם אתה מתחת לגיל 18 שנים, תוכל
          להשתמש באתר רק עם הסכמתם ובהשגחתם של הוריך או אפוטרופוס מוסמך. על ידי
          שימוש באתר בין במישרין או בעקיפין, אתה מתחייב שהנך בגיל 18 שנים ומעלה,
          או שקיבלת את הסכמת הוריך או אפוטרופוס מוסמך והנך רשאי לעשות זאת כל עוד
          הנך משתמש רשום באתר.
        </p>
        <p>
          חלטו רשאית לסגור את האתר ולשנות את מבנהו, מראהו, וזמינות השירותים
          והתכנים בו מעת לעת, מבלי להודיע מראש. אין לך זכות לטענות, תביעות או
          דרישות ביחס לכך. במקרה שבו החלטת שאינך זקוק יותר לחשבונך, תהיה
          באפשרותך לסגור את החשבון על ידי פניה למחלקת התמיכה של חלטו.
        </p>
        <h4 style={{ color: "#ff4e5b" }}>מטרת האתר</h4>
        <p>
          מטרת האתר היא להוות פלטפורמה לקישור בין בעלי פרויקטים ו-או קליינטים
          לבין פרילנסרים בשוק הישראלי. החברה מסייעת לקדם מטרה זו באופן ניטרלי,
          אינה משתתפת באופן ישיר בעסקאות שנעשות בין המשתמשים או בעסקאות שבין
          המשתמשים לעצמם, לכן חלטו איננה מתחייבת לסיפוק עבודה, מכירות, תשלומים
          או פרויקטים. לחברה אין כל אחריות ביחס לתכנים הנמצאים באתר ובכל מכשיר
          קצה בו הם מופיעים,מהימנותם,דיוקם, אמינותם ולכל השלכות שלהם על מחשבי
          המשתמשים באתר וכן לכל נזק, אי נוחות, אובדן, עוגמת נפש והשלכות כאלו,
          ישירות או עקיפות, שייגרמו למשתמש, לרכושו או לצדדים שלישיים אחרים
          כתוצאה משימוש בתכנים הנ"ל. בנוסף, החברה אינה מאמת את זהותם של משתמשים
          ואיננה מאשרת או בודקת את כישוריהם או הניסיון שלהם. לכן, אנו ממליצים
          לפעול בזהירות והשכל הישר בעת שימוש באתר זה ובעת יצירת קשר עם משתמשים
          בו. חלטו משדרת תכנים שפורסמו על ידי צדדים שלישיים ומשתמשים. חלטו לא
          שולטת כלל על תכנים אלו. כל הביקורות, ההמלצות, ההצעות, ההצהרות,
          השירותים, המשתמשים והמידע האחר אשר זמינים שפורסמו על ידי צדדים
          שלישיים, הם בבעלותם של מי שיצר אותם באתר ואין להם שום קשר לחלטו. איננו
          תומכים בתכנים אלו ואיננו אחראים לדיוקם או לאמינותם של ביקורות, המלצות
          או מידע אחר שפורסמו או יפורסמו באתר על ידי צדדים שלישיים שאינם עובדים
          במערך חלטו. חלטו איננה מאשרת ואיננה מאמת את זהות הפרילנסר ואם נחשב
          פרילנסר זה למומחה מקצועי או שמוסמך לתת ייעוץ מקצועי, ואיננה אחראית
          לפעולותיו. המשתמש מבין ומסכים כי החברה איננה אחראית לתכנים באתר ואיננה
          אחראית למהימנותם ודיוקם.
        </p>
        <p>
          חלטו שומרת לעצמה את הזכות לשנות, לעדכן, או לשנות את התנאים וההגבלות
          הללו בכל עת מבלי לספק הודעה מראש. זהו עליך לבדוק מעת לעת את התנאים
          האלו לשינויים. השימוש המתמשך באתר לאחר שנעשו שינויים מהווה את הסכמתך
          לתנאים המעודכנים.
        </p>
        <p>
          צוות התמיכה שלנו זמין לכל שאלות בנוגע לאתר זה או לתנאי שימוש אלו. ניתן
          ליצור קשר עם צוות התמיכה על ידי שליחת מייל עם פרטי הבקשה או שאלה
          ל-support@haltu.co.il.
        </p>
      </div>
      <IntellectualPropertySection />
      <RealLifeMeetingsSection />
      <SpamContentSection />
      <SubscriptionSection />
      <ThirdPartyIntellectualPropertySection />
      <IndemnificationSection />
      <EmailNotificationAcceptionSection />
      <FreelanceAgreement />
      <ClientsAgreement />
      <UploadedContentSection />
      <UserCreationSection />
      <UserDisputesSection />
      <UserGeneratedContentSection />
      <UserBehaviorSection />
      <UserTerminationSectionn />
      <UserProfilesSection />
      <UserIdentificationSection />
      <UserPaymentSection />
      <UserSuggestions />
      <DisclaimerOfLiabilitySection />
      {/* <SellersSection /> */}
      {/* <GigsSection /> */}
      {/* <OrdersSection /> */}
      {/* <ReviewsSection /> */}
      {/* <UserProtectionSection /> */}
      {/* <ReportSection /> */}
      {/* <ThirdSection /> */}
      {/* <FourthSection /> */}
      {/* <FifthSection /> */}
      <ForbiddenActivitySection />
      <JuristrictionBoundment />
      <ContactUs />
    </div>
  );
};
export default TermsOfService;

/* <div
  style={{ height: "150vh" }}
  className="d-flex align-items-center justify-content-center"
>
  <span
    style={{
      direction: "ltr",
      fontSize: "128px",
      fontFamily: "Comfortaa",
      letterSpacing: "-20px",
    }}
  >
    <span>h</span>
    <span style={{ color: "#ff4e5b" }}>`</span>
    <span style={{ fontSize: "64px", letterSpacing: "0px" }}>altu</span>
  </span>
</div> */
