import { Nav } from "react-bootstrap";
import {
  faRightToBracket,
  faUser,
  faMessage,
  // faPiggyBank,
  faInbox,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import ResponsiveLink from "./ResponsiveLink";

const LoggedInLinks = function (props: {
  logoutHandler: () => void;
  userId: string | null;
  messageNotifications: number;
  ordersNotifications: number;
  color: string;
  closeCanvas?: () => void;
}) {
  const {
    logoutHandler,
    userId,
    messageNotifications,
    ordersNotifications,
    color,
    closeCanvas,
  } = props;

  return (
    <div className="d-flex flex-column flex-md-row mx-2 mx-md-0 mt-md-2">
      <Nav.Item>
        <ResponsiveLink
          id="profile"
          text="פרופיל"
          icon={faUser}
          to={`/users/${userId}`}
          color={color}
          closeCanvas={closeCanvas}
        />
      </Nav.Item>
      <Nav.Item>
        <ResponsiveLink
          id="chat"
          text="צ'אט"
          icon={faMessage}
          to={`/users/${userId}/chat`}
          notifications={messageNotifications}
          notificationPlacement={{ top: "20px", left: "155px" }}
          color={color}
          closeCanvas={closeCanvas}
        />
      </Nav.Item>
      {/* Uncomment to get withdraws system */}
      {/* <Nav.Item>
        <ResponsiveLink
          id="balance"
          text="יתרות"
          icon={faPiggyBank}
          to={`/users/${userId}/balance`}
          color={color}
        />
      </Nav.Item> */}
      <Nav.Item>
        <ResponsiveLink
          id="favorites"
          text="אהבתי"
          icon={faHeart}
          to={`/users/${userId}/favorites`}
          color={color}
          closeCanvas={closeCanvas}
        />
      </Nav.Item>
      <Nav.Item>
        <ResponsiveLink
          id="orders"
          text="הזמנות"
          icon={faInbox}
          to={`/users/${userId}/orders`}
          color={color}
          notifications={ordersNotifications}
          notificationPlacement={{ top: "20px", left: "55px" }}
          closeCanvas={closeCanvas}
        />
      </Nav.Item>
      <Nav.Item onClick={logoutHandler}>
        <ResponsiveLink
          id="logout"
          text="התנתק"
          icon={faRightToBracket}
          to="/"
          color={color}
        />
      </Nav.Item>
    </div>
  );
};

export default LoggedInLinks;
