import { Button } from "react-bootstrap";
import OrderInfoCard from "./OrderInfoCard";
import { updateOrder } from "../api/order-update";
import { Order } from "../interface/order.interface";

import SellerCancelationModal from "./Modals/SellerCancelationModal";
import SellerConfirmationModal from "./Modals/SellerConfirmationModal";
import { useState } from "react";

const SellerSingleOrder = function (props: {
  order: Order;
  orderId: string;
  userId: string;
}) {
  const { order, userId, orderId } = props;
  const [acceptLoading, setAcceptLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const onOrderAccept = function () {
    setAcceptLoading(true);
    updateOrder(userId, orderId, "accept")
      .then((results) => {
        if (!results.ok) {
          throw new Error("Something went wrong");
        }
        setTimeout(() => {
          setConfirmationModal(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setAcceptLoading(false);
        }, 2000);
      });
  };

  const onOrderCancel = function () {
    setCancelLoading(true);
    updateOrder(userId, orderId, "cancel")
      .then((results) => {
        if (!results.ok) {
          throw new Error("Something went wrong");
        }
        setTimeout(() => {
          setCancelModal(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setCancelLoading(false);
        }, 2000);
      });
  };

  return (
    <div>
      <div className="card">
        <OrderInfoCard order={order} userId={userId} />
      </div>
      {order.active ? (
        <div className="mt-3">
          {order.status === "PENDING" ? (
            <div className="d-flex justify-content-between">
              <Button
                variant="danger col-3 mx-2"
                className="col-3 mx-2"
                onClick={() => setCancelModal(true)}
                type="button"
              >
                ביטול
              </Button>
              <Button
                variant="primary"
                className="col-3 mx-2"
                onClick={() => setConfirmationModal(true)}
                type="button"
              >
                אישור
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-center text-secondary">הזמנה זאת הסתיימה.</p>
        </div>
      )}
      <SellerConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onApprove={onOrderAccept}
        loading={acceptLoading}
      />
      <SellerCancelationModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        onCancel={onOrderCancel}
        loading={cancelLoading}
      />
    </div>
  );
};

export default SellerSingleOrder;
