export const EmailNotificationAcceptionSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>אישור קבלת דיוור</h2>
      <p>
        ברישום חשבון באתר haltu.co.il ("האתר"), אתה מסכים לקבל הודעות דוא"ל
        והתראות במערכת מחלטו בנוגע להזמנות, עסקאות, מצב החשבון, או כל מידע
        רלוונטי אחר שימצא לנכון להישלח על ידי חלטו. ההודעות הללו עשויות לכלול
        אישורי הזמנה, עדכוני משלוחים, קבלות תשלום ושאלות תמיכה לקוחות. כתובת
        הדוא"ל שתספק במהלך הרישום תשמש לשליחת ההודעות הללו. אתה מכיר ומסכים כי
        לא תחזיק את חלטו אחראית לכל אי נוחות, אובדן או תחושת תקלה הנובעים מאי
        קבלתך או קריאתך של ההודעות הללו. בנוסף, תקבל התראות בתוך הפלטפורמה עצמה.
        התראות אלו יהיו נגישות בעת כניסתך לחשבונך ועשויות לכלול עדכונים חשובים
        אודות ההזמנות שלך ופעילות החשבון. ייתכן כי יהיה לך אפשרות לנהל את
        ההעדפות שלך בנושא ההתראות בתוך הגדרות החשבון שלך כדי לשלוט בסוגי ההתראות
        שאתה מקבל. עם זאת, התראות מסוימות הנוגעות לעיבוד הזמנות ותחזוקת החשבון
        עשויות להיות אינן ניתנות לביטול והן חיוניות לתפקוד התקין של שירותי האתר.
        חלטו מתחייבת לשמור על הפרטיות והאבטחה של מידע הקשר שלך. המידע שלך לא
        ישותף עם צדדים שלישיים לצורך מטרות שיווק בלבד מבלי לקבל את ההסכמה
        המפורשת שלך. למידע נוסף אודות כיצד המידע שלך משמש ומוגן, אנא הסתכל על
        מדיניות הפרטיות שלנו.
      </p>
    </div>
  );
};
