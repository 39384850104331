import { useAppSelector } from "../../../store/hooks";
import { useState, useEffect, Fragment } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import TextArea from "../../../components/Forms/TextArea";
import RatingInput from "../../../components/Forms/RatingInput";
import { Spinner as PageSpinner } from "../../../components/Spinner";
import { Spinner, Button } from "react-bootstrap";

import { createReview } from "../api/reviews-create";
import { getSingleOrder } from "../../orders/api/order-read";
import { Order } from "../../orders/interface/order.interface";

const ReviewPage = function () {
  const params = useParams();
  const { userId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const [rating, setRating] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [reviewText, setReviewText] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageLoading(true);
    getSingleOrder(params.id!, params.orderId!)
      .then((order: Order) => {
        if (userId !== order.buyerId._id.toString()) {
          navigate("/");
        } else if (order.active === true) {
          navigate("/");
        } else if (order.reviewed === true) {
          navigate("/");
        } else if (order.status !== "FINISHED") {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setPageLoading(false);
        }, 2000);
      });
  }, [navigate, params.id, params.orderId, userId]);

  const handleSubmit = function (event: React.FormEvent) {
    event.preventDefault();
    setLoading(true);
    createReview(params.id!, params.orderId!, {
      rating: rating,
      reviewText: reviewText,
    })
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      });
    return;
  };
  return (
    <div className="container d-flex align-items-center">
      {!pageLoading ? (
        <Fragment>
          <div className="col-12 col-md-6 mt-3 mx-0 mx-md-5">
            <h1>תתחדשו! תרצו להשאיר ביקורת?</h1>
            <form noValidate onSubmit={handleSubmit}>
              <h5>דירוג המוכר:</h5>
              <RatingInput
                rating={rating}
                setRating={(index) => {
                  console.log(rating);
                  setRating(index);
                }}
              />
              <div className="mt-2">
                <TextArea
                  id={"reviewText"}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setReviewText(event.target.value);
                  }}
                  value={reviewText}
                  text={"איך הייתה חווית הרכישה מהפרילנסר?"}
                />
              </div>
              <div className="d-flex flex-column">
                <Button
                  variant={`danger ${loading ? "disabled" : null}`}
                  className="mb-1"
                  type="submit"
                >
                  {!loading ? "שלח/י" : <Spinner size="sm" />}
                </Button>
                <Link to="/" className="btn btn-primary">
                  דף הבית
                </Link>
              </div>
            </form>
          </div>
          <div className="mt-5 mx-5">
            <img
              src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694325541/haltu_assets/Feedback-pana-no-bg_lfgi99.svg"
              alt="..."
              style={{ width: "25vw" }}
              className="d-none d-lg-block mt-5"
            />
          </div>
        </Fragment>
      ) : (
        <PageSpinner />
      )}
    </div>
  );
};

export default ReviewPage;
