import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const updateOrder = function (
  userId: string,
  orderId: string,
  action: string
) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/orders/${orderId}/${action}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // .then((results) => {
  //   if (!results.ok) {
  //     throw new Error("Error");
  //   }
  //   if (action === "approve" && results.ok) {
  //     window.location.replace(`/users/${userId}/orders/${orderId}/review`);
  //   } else {
  //     window.location.reload();
  //   }
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
};
