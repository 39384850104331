import { useAppSelector } from "../../store/hooks";
import { Category } from "../../features/categories/interface/category.interface";
import CategoriesCarousel from "../../features/categories/components/CategoriesCarousel";
import HeroSection from "./Components/HeroSection";
import JoinUs from "./Components/JoinUs";
// import HowItWorks from "./Components/HowItWorks";

// Logged in components:
import CreateGig from "./Components/LoggedIn/CreateGig";
import LoggedInHeroSection from "./Components/LoggedIn/LoggedInHeroSection";
import ReadyToGetStarted from "./Components/ReadyToGetStarted";
import FreelancerBenefits from "./Components/FreelancerBenefits";
import ClientBenefits from "./Components/ClientBenefits";
import HaltuFeatures from "./HaltuFeatures";
import WorkingTogether from "./Components/WorkingTogether";
import ReadyToCreateGig from "./Components/LoggedIn/ReadyToCreateGig";
import PopularGigs from "./Components/LoggedIn/PopularGigs";
import ViewedGigs from "./Components/LoggedIn/ViewedGigs";

interface MainFeedProps {
  categories: Category[];
}

const MainFeed: React.FC<MainFeedProps> = function (props) {
  const { authCheck } = useAppSelector((state) => state.user);
  return (
    <div style={{ overflow: "" }}>
      {!authCheck ? <HeroSection /> : <LoggedInHeroSection />}
      <div>
        <ViewedGigs />
      </div>
      {!authCheck ? (
        <div className="p-3 mt-5">
          <h3
            className="mb-4 text-center"
            style={{ fontWeight: "700", marginRight: "3px" }}
          >
            קטגוריות מומלצות
          </h3>
          <div style={{ marginRight: "1vw" }} className="pt-2 pb-2">
            <CategoriesCarousel categories={props.categories} />
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <ClientBenefits />
        </div>
      )}
      <div className="mt-5">
        {!authCheck ? <ReadyToGetStarted /> : <ReadyToCreateGig />}
      </div>
      {!authCheck ? (
        <div className="mt-5">
          <ClientBenefits />
        </div>
      ) : (
        <div className="mt-5 ">
          <PopularGigs />
        </div>
      )}
      <div className="mt-5">
        {!authCheck ? <HaltuFeatures /> : <FreelancerBenefits />}
      </div>
      {!authCheck ? (
        <div className="mt-5">
          <FreelancerBenefits />
        </div>
      ) : (
        <div className="p-3 mt-5">
          <h3
            className="mb-4 text-center"
            style={{ fontWeight: "700", marginRight: "3px" }}
          >
            קטגוריות מומלצות
          </h3>
          <div style={{ marginRight: "1vw" }} className="pt-2 pb-2">
            <CategoriesCarousel categories={props.categories} />
          </div>
        </div>
      )}
      <div className="mt-5">
        <WorkingTogether />
      </div>
      <div style={{ marginTop: "80px" }}>
        {!authCheck ? <JoinUs /> : <CreateGig />}
      </div>
    </div>
  );
};

export default MainFeed;
