import { baseUrl } from "../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const getUserInfo = function (userId: string): any {
  return fetch(`${URL}/${userId}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
