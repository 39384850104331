import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { tokenValidity } from "../../api/password/password-token";
import { NewPassword, postPassword } from "../../api/password/password-post";

import { useFormik } from "formik";
import InputFormik from "../../../../components/Forms/InputFormik";
import { Button, Spinner } from "react-bootstrap";
import { passwordSchema } from "../../schemas/password.schema";

interface ResetPassValues {
  password: string;
  confirmPassword: string;
}

const initialValues: ResetPassValues = {
  password: "",
  confirmPassword: "",
};

const PassChangePage = function () {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: (data) => {
      setLoading(true);
      const newPassword: NewPassword = {
        password: data.password,
        confirmPass: data.confirmPassword,
        token: params.token!,
      };
      postPassword(newPassword).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
    },
  });

  useEffect(() => {
    tokenValidity(params.token!);
  }, [params.token]);

  return (
    <div className="container mt-5" style={{ minHeight: "70vh" }}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h3 className="mb-4">איפוס סיסמה</h3>
        <div className="col-12 col-lg-6">
          <form
            onSubmit={formik.handleSubmit}
            className="container d-flex flex-column card p-4"
            noValidate
          >
            <InputFormik
              type="password"
              id="password"
              text="סיסמה"
              formik={formik}
            />
            <InputFormik
              type="password"
              id="confirmPassword"
              text="אימות סיסמה"
              formik={formik}
            />
            <Button
              type="submit"
              variant={`danger ${(!formik.isValid || loading) && "disabled"}`}
            >
              {loading ? <Spinner size="sm" /> : "שינוי סיסמה!"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PassChangePage;
