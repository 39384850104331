import { baseUrl } from "../../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const uploadUserImage = function (imageStr: string, userId: string) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/upload-image`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({ image: imageStr }),
  });
};
