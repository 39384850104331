import MisuseSection from "./MisuseSection";

export const UserBehaviorSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>כללי התנהגות</h2>
      <p>
        אתה מסכים שלא להשתמש באתר לכל מטרה בלתי חוקית או לכל אופן שעשוי לפגוע,
        להפריע או להתערער בפועלו של האתר. אתה לא תעלה, תפרסם, תשדר או תשים לרשות
        אחרים באופן כזה או אחר תוכן שהוא פוגע, איום, פוגעני, מגיע לשם דיבה, או
        שמו כל אדם אחר. אתה לא תעסוק באגירת מידע של משתמשים ללא רשות ולא תנסה
        לגשת לאזורים באתר שהם מוגבלים.
      </p>
      <MisuseSection />
      <p>
        חלטו פתוחה לכולם. אתה מתחייב לא להפלות משתמש אחר על בסיס מין, גזע, גיל,
        השתייכות דתית, השתייכות פוליטית, נטייה מינית או כל דבר אחר, ואתה מאשר כי
        אפליה כזו עלולה לגרום להשעיה של חשבונך.
      </p>
    </div>
  );
};
