import { Modal, Button, Spinner } from "react-bootstrap";

const BuyerConfirmationModal = function (props: {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  loading: boolean;
}) {
  const { open, loading } = props;
  if (!open) return null;
  return (
    <Modal
      show={open}
      onHide={props.onClose}
      className="d-flex align-items-center justify-content-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>אישור קבלת הפריטים</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          בעת לחיצה על אישור, תאשרו כי קיבלתם את השירות או המוצר ובכך ההזמנה
          תושלם.
        </p>
        <p className="text-secondary text-center">
          לא תוכלו לפתוח את הזמנה זו מחדש ברגע שסומנה כהושלמה.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button
          className="col-4 mx-2"
          variant="danger"
          type="button"
          onClick={props.onClose}
        >
          ביטול
        </Button>
        <Button
          variant={`primary ${loading ? "disabled" : ""}`}
          className="col-4 mx-2"
          type="button"
          onClick={props.onApprove}
        >
          {loading ? <Spinner size="sm" /> : "אישור ההזמנה"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BuyerConfirmationModal;
