const SixthSection = function () {
  return (
    <div>
      <h5>הזכויות שלך</h5>
      <p>
        יש לך את הזכות לגשת, לתקן או למחוק את המידע האישי שלך בכל עת על ידי
        פנייה אלינו. יש לך גם את הזכות להתנגד לשימוש שלנו במידע האישי שלך או
        לבקש שנגביל את השימוש שלנו במידע האישי שלך.
      </p>
    </div>
  );
};

export default SixthSection;
