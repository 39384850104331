import { baseUrl } from "../../../API/base-urls";
import { AppDispatch } from "../../../store/store";
import { GigFormOutput } from "../interface/gig-form-output";
import { uiActions } from "../../../store/slices/ui/ui-slice";

const URL = `${baseUrl}/gigs`;

export const updateGig = function (
  updatedGig: GigFormOutput,
  gigId: string,
  callback: (state: boolean) => void
) {
  return (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    const uploadGig = fetch(`${URL}/${gigId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedGig),
    });
    uploadGig
      .then((results) => {
        if (!results.ok) throw new Error("Something went wrong");
        return results.json();
      })
      .then((res) => {
        window.location.replace(`/gigs/${updatedGig.category}/${gigId}`);
      })
      .catch((error) => {
        console.log(error);
        dispatch(uiActions.setError(error.message));
      })
      .finally(() => {
        setTimeout(() => {
          callback(false);
        }, 500);
      });
  };
};
