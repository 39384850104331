import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { uploadAboutMe } from "../../api/user-info/user-info-update/user-aboutMe";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditAboutMe = function (props: { aboutMe: string; isUser: boolean }) {
  const params = useParams();
  const aboutMeInput = React.useRef<HTMLTextAreaElement>(null);
  const [error, setError] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);

  const userEditing = function () {
    setEditing(!editing);
  };

  const rowsOfText: string[] = props.aboutMe ? props.aboutMe.split("\n") : [""];

  const submitHandler = function () {
    if (!aboutMeInput.current?.value) {
      setError(true);
      return;
    }
    const aboutMe = aboutMeInput.current.value;
    uploadAboutMe(aboutMe, params.id!)
      .then((results) => {
        console.log(results);
        window.location.reload();
      })
      .catch((error) => {
        setError(true);
        console.log(error);
      });
  };

  const resetErrors = function () {
    setError(false);
  };

  return (
    <div className="container mt-3">
      <div className="d-flex flex-column justify-content-start">
        <div className="d-flex align-items-center justify-content-between border-bottom">
          <h4>קצת עליי</h4>
          {props.isUser && !editing && (
            <Button
              variant="outline-dark mb-2"
              className="mb-2 d-flex align-items-center justify-content-center"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                fontSize: "12px",
              }}
              type="button"
              onClick={userEditing}
            >
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          )}
        </div>
        {!editing ? (
          <React.Fragment>
            <div className="p-3 px-0">
              {rowsOfText.map((row, index) => {
                if (row === "") {
                  return null;
                } else {
                  return <p key={index}>{row}</p>;
                }
              })}
            </div>
            <p className="p-3 px-0">{props.aboutMe}</p>
          </React.Fragment>
        ) : (
          <div className="d-flex flex-column">
            <textarea
              className={`form-control ${error ? "is-invalid" : ""}`}
              rows={6}
              ref={aboutMeInput}
              placeholder="ספרו לנו קצת על עצמכם"
              defaultValue={props.aboutMe}
              onChange={resetErrors}
              style={{ resize: "none" }}
            ></textarea>
            <div className="d-flex border-top justify-content-between mt-2 mb-3">
              <Button
                variant="danger"
                className="col-5 mt-3"
                type="button"
                onClick={userEditing}
              >
                ביטול
              </Button>
              <Button
                variant="primary"
                className="col-5 mt-3"
                type="button"
                onClick={submitHandler}
              >
                עדכון
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default EditAboutMe;
