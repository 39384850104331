export const UserGeneratedContentSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>תוכן שנוצר על ידי משתמשים</h2>
      <p>
        התוכן הזמין באתר haltu.co.il ("האתר") עשוי לכלול תוכן שנוצר על ידי
        המשתמשים, כגון טקסט, תמונות, וידאו, ביקורות, הערות וחומרים אחרים המועלים
        על ידי משתמשי האתר. אתה מכיר ומסכים שחלטו אינה ממליצה, אינה מאמתת ואינה
        מתחייבת לאמת את הדיוק, השלמות, החוקיות או האמינות של התוכן שנוצר על ידי
        המשתמשים. המשתמשים אחראים באופן בלעדי על התוכן שהם מעלים, מפרסמים,
        משדרים או עושים זמין באתר. כל הסתמכות על תוכן שנוצר על ידי המשתמשים היא
        באחריותך האישית. חלטו אינה תחשב לאחראית לכל אובדן, נזק או פגיעה שנגרמו
        כתוצאה מהסתמכות על תוכן שנוצר על ידי משתמשים או מידע שסופק על ידי
        משתמשים אחרים. חלטו אינה מבצעת בדיקה מראש או מעקב אחר כל התוכן שנוצר על
        ידי המשתמשים ולא מתיבה על עצמה חובה לעשות זאת. אף על פי כן, חלטו שומרת
        לעצמה את הזכות לסקור, לערוך או להסיר כל תוכן שנוצר על ידי המשתמשים שהפר
        את התנאים וההגבלות הנכללות בתנאי שימוש אלו או שנראה כלא הולם, פוגע או
        שואף להפרת דיני המשפט. חלטו ממליצה למשתמשים להיות זהירים ולהפעיל שיקול
        דעת בזמן השימוש באתר עם התוכן שנוצר על ידי המשתמשים. אתה מבין כי כל
        התנהלות, עסקה או הקשה על התוכן שנוצר על ידי המשתמשים נעשית על אחריותך
        האישית וחלטו אינה תהיה אחראית לכל נזק או טענה הנובעים מכך.
      </p>
    </div>
  );
};
