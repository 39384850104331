// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Category } from "../../../features/categories/interface/category.interface";

const OffcanvasCategories = function (props: {
  categories: Category[];
  closeCanvas: () => void;
}) {
  const { categories, closeCanvas } = props;
  const [showCategories, setShowCategories] = useState<boolean>(false);

  useEffect(() => {
    setShowCategories(false);
  }, []);
  return (
    <div>
      <h3
        onClick={() => {
          setShowCategories(!showCategories);
        }}
      >
        קטגוריות{" "}
        <span>
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{
              transition: "0.3s",
              transform: `rotate(${showCategories ? "0.5" : "0"}turn)`,
            }}
          />
        </span>
      </h3>
      <div style={{}}>
        {showCategories &&
          categories.map((category) => {
            return (
              <Link
                onClick={closeCanvas}
                to={`/gigs/${category.urlName}`}
                key={category._id}
                className="link-dark"
                style={{ textDecoration: "none", display: "block" }}
              >
                {category.name}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default OffcanvasCategories;
