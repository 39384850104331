import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";

const RatingInput = function (props: {
  rating: number;
  setRating: (index: number) => void;
}) {
  return (
    <div>
      {[...Array(5)].map((star, index) => {
        return (
          <button
            style={{ border: "none", background: "none" }}
            type="button"
            key={index}
            onClick={() => {
              props.setRating(index + 1);
            }}
          >
            {props.rating > index ? (
              <FontAwesomeIcon icon={faStarSolid} />
            ) : (
              <FontAwesomeIcon icon={faStar} />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default RatingInput;
