import React, { useState, Fragment } from "react";
import { Gig } from "../../interface/gig.interface";
import { GigFormOutput } from "../../interface/gig-form-output";
import { Category } from "../../../categories/interface/category.interface";
import ProgressBar from "./GigFormProgress/ProgressBar";
import FirstSection from "./GigFormProgress/FirstSection";
import SecondSection from "./GigFormProgress/SecondSection";
import ThirdSection from "./GigFormProgress/ThirdSection";

interface GigFormProps {
  gig?: Gig;
  btnText: string;
  submitHandler: (gigValues: GigFormOutput) => void;
  categories?: Category[];
  errState: string;
  setErrState: (str: string) => void;
  loading: boolean;
}

const GigForm = function (props: GigFormProps) {
  const [formData, setFormData] = useState<GigFormOutput>({
    title: props.gig?.title || "",
    price: props.gig?.price || 0,
    whatsIncluded: props.gig?.whatsIncluded || "",
    requirements: props.gig?.requirements || "",
    description: props.gig?.description || "",
    category: props.gig?.category._id.toString() || "",
    deliveryDays: props.gig?.deliveryDays || 0,
    revisions: props.gig?.revisions || 0,
  });
  let loadedImages: string[] = ["", "", ""];
  for (let i = 0; i <= 2; i++) {
    if (
      props.gig?.imagesUrls[i] !== "" &&
      props.gig?.imagesUrls[i] !== undefined
    ) {
      loadedImages[i] = props.gig!.imagesUrls[i];
    }
  }
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([
    ...loadedImages,
  ]);
  const [progress, setProgress] = useState<number>(0);

  const onProgressHandler = function (event: any) {
    const incrementOrDecrementBy = 50;
    const { name } = event.target;
    setProgress((prev) => {
      if (name === "next") return prev + incrementOrDecrementBy;
      if (name === "previous") return prev - incrementOrDecrementBy;
      return prev;
    });
  };

  const passValues = function (event: React.FormEvent) {
    event.preventDefault();
    props.submitHandler({ ...formData, images: uploadedFiles });
  };

  const onChangeHandler = function (event: React.ChangeEvent) {
    props.setErrState("");
    const { value, name } = event.target as HTMLInputElement;
    if (name === "price" || name === "revisions" || name === "deliveryDays") {
      const num = Number(value);
      setFormData((prev) => {
        return { ...prev, [name]: num };
      });
      return;
    } else {
      setFormData((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between">
        <span
          className={`d-none d-lg-inline col-2 text-start ${
            progress >= 0 ? "text-danger" : "text-secondary"
          }`}
          style={{ fontWeight: progress >= 0 ? "700" : "500" }}
        >
          כללי
        </span>
        <span
          className={`d-none d-lg-inline col-2 text-center ${
            progress >= 50 ? "text-danger" : "text-secondary"
          }`}
          style={{ fontWeight: progress >= 50 ? "700" : "500" }}
        >
          קטגוריות
        </span>
        <span
          className={`d-none d-lg-inline col-2 text-end ${
            progress >= 100 ? "text-danger" : "text-secondary"
          }`}
          style={{ fontWeight: progress >= 100 ? "700" : "500" }}
        >
          תמונות & העלאה
        </span>
      </div>
      <ProgressBar progress={progress} />
      <div>
        <form onSubmit={passValues} className="container">
          {progress <= 0 && (
            <FirstSection
              formData={formData}
              onChange={onChangeHandler}
              setErrState={props.setErrState}
              progress={progress}
              onProgress={onProgressHandler}
              btnText={props.btnText}
              gig={props.gig}
              errState={props.errState}
            />
          )}
          {progress === 50 && (
            <SecondSection
              formData={formData}
              onChange={onChangeHandler}
              setErrState={props.setErrState}
              categories={props.categories}
              progress={progress}
              onProgress={onProgressHandler}
              btnText={props.btnText}
              gig={props.gig}
              errState={props.errState}
            />
          )}
          {progress > 50 && (
            <ThirdSection
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              multiple={true}
              progress={progress}
              onProgress={onProgressHandler}
              btnText={props.btnText}
              gig={props.gig}
              errState={props.errState}
              onChange={onChangeHandler}
              formData={formData}
              loading={props.loading}
            />
          )}
        </form>
      </div>
    </Fragment>
  );
};
export default GigForm;
