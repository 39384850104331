import { useState } from "react";
import { Gig } from "../features/gigs/interface/gig.interface";
import { User } from "../features/user/interface/user.interface";
import { Category } from "../features/categories/interface/category.interface";

import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { GigCard } from "../features/gigs/components/GigCard";
import UserCard from "../features/user/components/Cards/UserCard";
import CategoriesCard from "../features/categories/components/CategoriesCard";

const SearchResults = function (props: {
  users?: User[];
  gigs?: Gig[];
  categories?: Category[];
}) {
  const { users, gigs, categories } = props;
  // 0 is for jobs, 1 is for users, 2 is for categories.
  const [toggle, setToggle] = useState<0 | 1 | 2>(0);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container mt-3">
      <div className="mt-5 d-flex">
        <div
          style={{ cursor: "pointer", transition: "0.3s" }}
          onClick={() => setToggle(0)}
          className={`col-4 border-bottom text-center ${
            toggle === 0 && "text-danger border-danger"
          }`}
        >
          ג'ובים
          <span style={{ fontSize: "14px" }}>{` (${gigs?.length})`}</span>
        </div>
        <div
          style={{ cursor: "pointer", transition: "0.3s" }}
          onClick={() => setToggle(1)}
          className={`col-4 border-bottom text-center ${
            toggle === 1 && "text-danger border-danger"
          }`}
        >
          משתמשים
          <span style={{ fontSize: "14px" }}>{` (${users?.length})`}</span>
        </div>
        <div
          style={{ cursor: "pointer", transition: "0.3s" }}
          onClick={() => setToggle(2)}
          className={`col-4 border-bottom text-center ${
            toggle === 2 && "text-danger border-danger"
          }`}
        >
          קטגוריות
          <span style={{ fontSize: "14px" }}>{` (${categories?.length})`}</span>
        </div>
      </div>
      {toggle === 0 ? (
        <div className={`row mt-4 pb-3`}>
          {gigs?.length ? (
            <div className="row">
              <h3>ג'ובים:</h3>
              <Carousel
                responsive={responsive}
                rtl={true}
                className="pb-3"
                itemClass="d-flex justify-content-center"
              >
                {gigs.map((gig) => {
                  return (
                    <div className="col-11 col-md-9" key={gig._id}>
                      <GigCard gig={gig} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <h1 className="mt-5 text-center">לא נמצאו ג'ובים</h1>
          )}
        </div>
      ) : null}
      {toggle === 2 ? (
        <div className={`row mt-4 pb-3`}>
          {categories?.length ? (
            <div className="row">
              <h3>קטגוריות:</h3>
              <Carousel
                responsive={responsive}
                rtl={true}
                className="pt-4 pb-4"
                itemClass="d-flex justify-content-center d-md-block"
              >
                {categories.map((category) => {
                  return (
                    <div key={category._id}>
                      <CategoriesCard category={category} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <h1 className="mt-5 text-center">לא נמצאו קטגוריות</h1>
          )}
        </div>
      ) : null}
      {toggle === 1 ? (
        <div className="row mt-4 pb-3">
          {users?.length ? (
            <div className="row">
              <h3>משתמשים:</h3>
              <Carousel
                responsive={responsive}
                rtl={true}
                className="pb-3"
                itemClass="d-flex justify-content-center"
              >
                {users.map((user) => {
                  return (
                    <div
                      className="col-12 col-lg-3 mx-5 mx-lg-3 mt-3 mt-lg-0"
                      key={user._id}
                    >
                      <Link
                        to={`/users/${user._id}`}
                        style={{ textDecoration: "none" }}
                        className="link-dark w-75"
                      >
                        <UserCard user={user} isUser={false} />
                      </Link>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <h1 className="mt-5 text-center">לא נמצאו משתמשים</h1>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchResults;
