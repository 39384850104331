import { Card } from "react-bootstrap";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const PremiumPaymentCard = function (props: {
  approveHandler: (subscriptionID: string) => Promise<void>;
}) {
  const { approveHandler } = props;
  const total = 29.99;
  // const priceNoMaam = total * 0.83;
  // const maam = total * 0.17;
  return (
    <Card className="col-12 col-md-8">
      <div className="mx-4">
        <div>
          <h1 className="col-12 mt-4 border-bottom">
            <span
              className="text-secondary"
              style={{ fontSize: "12px", display: "block" }}
            >
              הצטרפות ל:
            </span>
            <span style={{ paddingTop: "0px" }}>מנוי חלטו</span>
          </h1>
        </div>
        <div className="border-bottom">
          <div className="d-flex justify-content-between">
            <p>סוג מנוי</p>
            <p>פרימיום</p>
          </div>
          {/* <div className="d-flex justify-content-between">
            <p>מחיר</p>
            <p>₪{priceNoMaam.toFixed(2)}</p>
          </div> */}
        </div>
        <div className="border-bottom" style={{ fontWeight: "600" }}>
          <div className="mt-1 d-flex justify-content-between">
            <p>דמי יצירה</p>
            <p>₪0</p>
          </div>
          {/* <div className="d-flex justify-content-between">
            <p>מע"מ</p>
            <p>₪{maam.toFixed(2)}</p>
          </div> */}
        </div>
        <div className="mb-3 border-bottom" style={{ fontWeight: "600" }}>
          <div className="mt-3 d-flex justify-content-between">
            <p>סה"כ</p>
            <p>₪{total}</p>
          </div>
        </div>
        <div className="mt-4">
          <PayPalScriptProvider
            options={{
              "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID!,
              components: "buttons",
              currency: "ILS",
              intent: "subscription",
              vault: true,
              // "disable-funding": "credit,card",
            }}
          >
            <PayPalButtons
              style={{
                shape: "rect",
                layout: "vertical",
                color: "black",
                label: "subscribe",
              }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: process.env.REACT_APP_PAYPAL_PREMIUM_PLAN!,
                });
              }}
              onApprove={(data, actions) => {
                return approveHandler(data.subscriptionID!);
              }}
              onError={(err) => {
                console.log("PAYPAL ERROR ", err);
              }}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </Card>
  );
};

export default PremiumPaymentCard;
