import { FreePlan } from "./Plans/FreePlan";
import { MidPlan } from "./Plans/MidPlan";
import { PremiumPlan } from "./Plans/PremiumPlan";

const PricingPage = function () {
  return (
    <div className="container" style={{ minHeight: "75vh", marginTop: "50px" }}>
      <h2 className="text-center">טבלאת מחירים לשדרוג החשבון שלכם</h2>
      <p className="text-secondary text-center mt-4">
        מושלם עבור משתמשים שמחפשים להגיע לקהל יעד גדול יותר, ולייצר הכנסות יותר
        גבוהות
      </p>
      <div className="mt-5 d-flex flex-column flex-md-row align-items-center justify-content-center">
        <FreePlan />
        <MidPlan />
        <PremiumPlan />
      </div>
    </div>
  );
};

export default PricingPage;

// <div id="paypal-button-container-P-47Y92870T5359140TMS2RVDQ"></div>
// <script src="https://www.paypal.com/sdk/js?client-id=AbbsAun5HczagewpIGV_hkhkeuN2IaDSJOspbsU783tJ9tqMtEZzTAl1bH14iZUxnf9HQafX9cPiKIvX&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
// <script>
//   paypal.Buttons({
//       style: {
//           shape: 'rect',
//           color: 'blue',
//           layout: 'vertical',
//           label: 'subscribe'
//       },
//       createSubscription: function(data, actions) {
//         return actions.subscription.create({
//           /* Creates the subscription */
//           plan_id: 'P-47Y92870T5359140TMS2RVDQ'
//         });
//       },
//       onApprove: function(data, actions) {
//         alert(data.subscriptionID); // You can add optional success message for the subscriber here
//       }
//   }).render('#paypal-button-container-P-47Y92870T5359140TMS2RVDQ'); // Renders the PayPal button
// </script>
