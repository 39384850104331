import { loginUser } from "./user-login";
import { baseUrl } from "../../../API/base-urls";
import { AppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/slices/ui/ui-slice";
import { RegisterInfo } from "../interface/registerInfo.model";

const URL = `${baseUrl}/users`;

export const registerUser = function (
  registerInfo: RegisterInfo,
  callback: (state: boolean) => void
) {
  return (dispatch: AppDispatch) => {
    const postInfo = fetch(`${URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerInfo),
    });
    postInfo
      .then((results) => {
        if (!results.ok) {
          return results.json().then((foundError) => {
            throw new Error(foundError.message);
          });
        }
        dispatch(
          loginUser({
            email: registerInfo.email!,
            password: registerInfo.password!,
          })
        );
      })
      .catch((error) => {
        dispatch(uiActions.setError(error.message));
      })
      .finally(() => {
        setTimeout(() => {
          callback(false);
        }, 500);
      });
  };
};

export const checkTakenEmail = function (email: string) {
  return fetch(`${URL}/check-email?email=${email}`);
};
