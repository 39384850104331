import { Link } from "react-router-dom";
import { Category } from "../interface/category.interface";

interface CategoriesCardProps {
  category: Category;
}

const CategoriesCard = function (props: CategoriesCardProps) {
  const defaultImg =
    "https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png";

  return (
    <Link
      to={`/gigs/${props.category.urlName}`}
      className="col-12 card category-card"
      style={{
        textDecoration: "none",
        height: "250px",
        maxWidth: "200px",
        margin: "0px",
      }}
    >
      <img
        className="rounded"
        src={props.category.imageUrl || defaultImg}
        alt={props.category.name}
        style={{ width: "100%", height: "100%" }}
      />
      {/* <div
        className="card-img-overlay"
        style={{ width: "198px", background: "#000", opacity: "0.3" }}
      /> */}
      <div className="card-img-overlay">
        <h5 className="card-title text-center">{props.category.name}</h5>
      </div>
    </Link>
  );
};

export default CategoriesCard;
