import { updateGig } from "../api/gig-update-api";
import { useState, Fragment, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

import Alert from "../../../components/Alert";
import GigForm from "../components/Forms/GigForm";
import { GigFormOutput } from "../interface/gig-form-output";

const GigUpdate = function () {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { serverError } = useAppSelector((state) => state.ui);
  const { categories } = useAppSelector((state) => state.categories);
  const { userId } = useAppSelector((state) => state.user);
  const [errState, setErrState] = useState<string>("");
  const editingGig = location.state;
  useEffect(() => {
    if (id !== userId) {
      navigate("/");
    }
  }, [id, userId, navigate]);

  const submitHandler = function (updatedGig: GigFormOutput) {
    if (!loading) {
      setLoading(true);
      dispatch(
        updateGig(updatedGig, editingGig._id, (state) => setLoading(state))
      );
    }
  };
  return (
    <Fragment>
      <div className="container mt-5">
        <GigForm
          errState={errState}
          setErrState={setErrState}
          categories={categories!}
          submitHandler={submitHandler}
          gig={editingGig}
          btnText="שמור!"
          loading={loading}
        />
        {serverError ? <Alert message={serverError} /> : ""}
      </div>
    </Fragment>
  );
};

export default GigUpdate;
