export const SchedulesSection = () => {
  return (
    <div>
      <h2>לוח זמנים ותקשורת</h2>
      <p>
        אתה מכיר כי הפרילנסרים מתחייבים לספק מוצרים ושירותים בתוך ימי האספקה
        המפורטים בעמוד המוצר או השירות שלהם. כל חריגה מ-ימי אספקה אלו יש ליצור
        קשר ולהגיע להסכמות עליה יחד עם הפרילנסר. תחזוק תקשורת פתוחה וברורה עם
        הפרילנסר לאורך תקופת הפרויקט, ולענות מיד על כל שאלות, משוב או דאגות שיש
        לך.
      </p>
    </div>
  );
};
