export const CommitmentToWorkSection = () => {
  return (
    <div>
      <h2>התחייבות</h2>
      <h5>כפרילנסר בפלטפורמה, אתה מסכים לבצע את המשימות הבאות:</h5>
      <ul>
        <li>
          לספק ללקוחות מוצרים ו/או שירותים כפי שמתואר ברשומת השירות או המוצר
          בפלטפורמה.
        </li>
        <li>
          לוודא שהמוצרים והשירותים שאתה מספק מייצגים באופן מדויק את התיאורים,
          המפרטים וכל המחירים הקשורים ברשומת השירות או המוצר.
        </li>
        <li>
          לקבוע את מחירי המוצרים והשירותים שלך באופן התואם לסטנדרטים של השוק
          והשירותים המוצעים בו.
        </li>
        <li>
          לתקשר, לפעול במקצועיות עם לקוחות, לענות על שאלותיהם, דאגותיהם
          ודרישותיהם בצורה מהימנה ובנוחות.
        </li>
        <li>
          לשתף פעולה עם לקוחות כדי להסכים על אמצעי התשלום שהתנאים מתאימים
          למוצרים ולשירותים המוצעים.
        </li>
      </ul>
      <h5>התחייבות לזמנים:</h5>
      <ul>
        <li>
          אתה מתחייב לספק את המוצרים והשירותים בתוך הזמנים המפורטים בעמוד השירות
          או מוצר. שינויים בזמנים אלו יש לתקשר ולהסכים עליהם עם הלקוח.
        </li>
        <li>
          אם מתרחשים אירועים העשויים להשפיע על היכולת שלך לעמוד בזמנים המוסכמים,
          עליך להודיע במהירות ללקוח ולספק הערכות מעודכנות.
        </li>
      </ul>
      <h5>איכות ודיוק:</h5>
      <ul>
        <li>
          אתה תספק מוצרים ושירותים שעומדים ברמות איכות ודיוק שנקבעו בתיאור עמוד
          השירות או המוצר.
        </li>
        <li>
          האחריות לוודא שכל המידע המסופק בעמוד השירות או המוצר הוא מדויק, נוכחי
          ושלם היא עליך.
        </li>
      </ul>
      <h5>תקשורת:</h5>
      <ul>
        <li>
          תשמור על תקשורת פתוחה וברורה עם לקוחות לאורך תקופת הפרויקט, ותטפל מיד
          בכל שאלה או דאגה שתעלה להם.
        </li>
        <li>תהיה זמין להודעות ועדכונים הנוגעים לפרויקטים שאתה מעורב בהם.</li>
      </ul>
      <h5>תשלום:</h5>
      <ul>
        <li>
          אתה מכיר כי התשלום עבור המוצרים והשירותים שלך יתבצע על פי המחיר שהגדרת
          בעמוד השירות או המוצר בפלטפורמה.
        </li>
        <li>
          אתה מתחייב להימנע מהתחייבויות תשלום ומיני תשלומים שנקבעו בהסכם עם
          הלקוח, ולוודא תהליך התשלום חלק ופשוט.
        </li>
        <li>
          כל סכסוך או בעיה הנוגעים לתשלומים חייב להתנהל בצורה מקצועית ומהירה.
        </li>
      </ul>
    </div>
  );
};
