import { addDots } from "../../../../utils/trimmers";
import UserImage from "../../../user/components/Image/UserImage";
import { Conversation } from "../../interface/Conversation.interface";
import { NotificationsBadge } from "../NotificationsBadge";

const ConversationCard = function (props: {
  currentConversation: Conversation | null;
  conversation: Conversation;
  userId: string | null;
  setConversation: () => void;
}) {
  const { conversation, userId, setConversation, currentConversation } = props;

  let messageDate: string = "";

  const lastMessage =
    conversation.messages[conversation.messages.length - 1].text;

  const lastMessageDate = new Date(
    Date.parse(
      conversation.messages[conversation.messages.length - 1].createdAt
    )
  );

  const currentDate = new Date(Date.now());

  if (lastMessageDate.getDay() === currentDate.getDay()) {
    messageDate = `${
      lastMessageDate.getHours() > 9
        ? lastMessageDate.getHours()
        : `0${lastMessageDate.getHours()}`
    }:${
      lastMessageDate.getMinutes() > 9
        ? lastMessageDate.getMinutes()
        : `0${lastMessageDate.getMinutes()}`
    }`;
  } else if (lastMessageDate.getDate() + 1 === currentDate.getDate()) {
    messageDate = "אתמול";
  } else {
    messageDate = `${lastMessageDate.getDate()}/${
      lastMessageDate.getMonth() + 1
    }/${lastMessageDate.getFullYear()}`;
  }

  const user =
    userId === conversation.firstUser.user._id
      ? conversation.secondUser.user
      : conversation.firstUser.user;

  const notifications =
    userId === conversation.firstUser.user._id
      ? conversation.firstUser.notifications
      : conversation.secondUser.notifications;

  return (
    <div
      className="p-3 d-flex flex-row align-items-center justify-content-between border-bottom border-top"
      style={{
        cursor: "pointer",
        background: `${
          currentConversation?._id === conversation._id
            ? "rgba(255,255,255,0.2)"
            : ""
        }`,
      }}
      onClick={setConversation}
    >
      <div className="d-flex flex-row">
        <div className="mt-1">
          <UserImage
            user={user}
            canEdit={false}
            size={32}
            fontSize={"14px"}
            showOnline={true}
          />
        </div>
        <div className="mx-2 flex-column justify-content-start">
          <div style={{ fontWeight: "600" }} className="text-dark">
            {user.firstName} {user.lastName}
          </div>
          <div
            className="text-dark"
            style={{ fontSize: "10px", fontWeight: "600" }}
          >
            {addDots(lastMessage)}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <span style={{ fontSize: "10px" }}>{messageDate}</span>
        {notifications > 0 && (
          <NotificationsBadge notifications={notifications} />
        )}
      </div>
    </div>
  );
};

export default ConversationCard;
