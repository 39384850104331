type ExpectedInput = string | undefined | null;

export const checkIfEmpty = (value: ExpectedInput): string | boolean => {
  if (value!.trim() !== "") {
    return value as string;
  } else {
    return false;
  }
};
export const checkLength = (enteredValue: ExpectedInput): string | boolean => {
  let isValid = true;
  isValid = isValid && enteredValue!.trim().length >= 5;
  isValid = isValid && enteredValue!.trim().length <= 16;
  if (isValid) {
    return enteredValue as string;
  }
  return false;
};

export const checkIfMatch = (
  firstValue: ExpectedInput,
  secondValue: ExpectedInput
): string | boolean => {
  if (firstValue === secondValue) {
    return secondValue as string;
  } else {
    return false;
  }
};

export const checkEmail = (value: ExpectedInput): string | boolean => {
  const validEmail =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      value!
    );
  if (validEmail) {
    return value as string;
  } else {
    return false;
  }
};
