export const ClientsDisputesSection = () => {
  return (
    <div>
      <h2>פתרון סכסוכים</h2>
      <p>
        במקרה של סכסוכים או דאגות הקשורות למוצרים, שירותים או אינטראקציות עם
        הפרילנסרים, אנחנו ממליצים תחילה לנסות לפתור את הנושא דרך תקשורת פתוחה עם
        הפרילנסר. אם לא ניתן להגיע לפתרון, תוכל ליצור קשר עם חלטו לקבלת סיוע. עם
        זאת, חלטו איננה מבטיחה סיוע או פתרון לסכסוכים ולא תהיה אחראית
        לתוצאותיהם.
      </p>
    </div>
  );
};
