import { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

import { createOrderComment } from "../../api/order-comment";

import CommentCard from "./CommentCard";
import { Button } from "react-bootstrap";
import TextArea from "../../../../components/Forms/TextArea";
import { User } from "../../../user/interface/user.interface";

interface OrderComment {
  commentBody: string;
  commentSender: User;
}

const OrderComments = function (props: {
  comments: OrderComment[];
  isActive: boolean;
}) {
  const { id, orderId } = useParams();
  const commentsEndRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errState, setErrState] = useState<boolean>(false);
  const [commentBody, setCommentBody] = useState<string>("");

  useEffect(() => {
    commentsEndRef.current?.scrollIntoView({
      inline: "start",
      block: "nearest",
      behavior: "auto",
    });
  }, []);

  const handleInput = function (event: React.ChangeEvent<HTMLTextAreaElement>) {
    setCommentBody(event.target.value);
    setErrState(false);
  };

  const onSubmitComment = function (event: React.FormEvent) {
    event.preventDefault();
    if (!commentBody) {
      setErrState(true);
      return;
    }
    if (!id && !orderId) return;
    setLoading(true);
    createOrderComment(commentBody, id!, orderId!)
      .then((response) => {
        setLoading(false);
        if (response.ok) {
          setCommentBody("");
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    commentsEndRef.current?.scrollIntoView({
      inline: "start",
      block: "nearest",
      behavior: "auto",
    });
  };

  return (
    <div className="mt-2">
      <h4>תגובות:</h4>
      <div
        className={`scrollable col-12 col-md-9 border ${
          props.comments.length === 0 && "p-5"
        }`}
        style={{ overflowY: "scroll", scrollbarWidth: "none", height: "300px" }}
      >
        {props.comments.length > 0
          ? props.comments.map((comment, index) => {
              return <CommentCard key={index} comment={comment} />;
            })
          : null}
        <div ref={commentsEndRef} />
      </div>
      {props.isActive && (
        <form
          noValidate
          className="d-flex align-items-center"
          onSubmit={onSubmitComment}
        >
          <div className="col-9 col-md-6">
            <TextArea
              id="commentBody"
              rows={1}
              onChange={handleInput}
              extraClass={errState ? "is-invalid" : ""}
              value={commentBody}
            />
          </div>
          <Button
            variant={`danger ${
              !commentBody.length || loading ? "disabled" : null
            }`}
            type="submit"
            className="col-3  mt-2 mx-2 "
          >
            שלח
          </Button>
        </form>
      )}
    </div>
  );
};

export default OrderComments;
