export const UploadedContentSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>תוכן המועלה לאתר</h2>
      <p>
        אתה שומר בעלות על כל תוכן שאותו תעלה לאתר, כולל הודעות, קבצים, תמונות,
        וחומרים אחרים. אך על ידי הגשת התוכן לאתר, תעניק לחלטו רישיון בלעדי,
        עולמי,תמידי,שלא ניתן לביטול,פטור מתמלוגים,לשימוש, שינוי,והפצה,ולהראות את
        התוכן בחיבור לאתר.
      </p>
    </div>
  );
};
