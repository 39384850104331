import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { registerSchema } from "../../schemas/user.schema";
import { checkTakenEmail } from "../../api/user-register";

import InputFormik from "../../../../components/Forms/InputFormik";
import CheckboxFormik from "../../../../components/Forms/CheckboxFormik";
import { Button, Spinner } from "react-bootstrap";

interface RegisterValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  newsLetterSubscription: boolean;
  termsAgreement: boolean;
}

const initialValues: RegisterValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  newsLetterSubscription: false,
  termsAgreement: false,
};

const RegisterFormV2 = function (props: {
  onSubmit: (registerInfo: any) => void;
  resetError: () => void;
  loading: boolean;
}) {
  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: (data) => {
      if (emailIsntTaken) {
        props.onSubmit(data);
      }
    },
  });

  const [emailIsntTaken, setEmailIsntTaken] = useState<boolean>(false);

  useEffect(() => {
    setEmailIsntTaken(false);
    if (!formik.errors.email) {
      const timer = setTimeout(() => {
        checkTakenEmail(formik.values.email)
          .then((results) => {
            if (results.status === 200) {
              setEmailIsntTaken(true);
            } else if (results.status === 206 || results.status === 304) {
              return;
            } else {
              formik.setErrors({ email: "אופס! האימייל הזה כבר תפוס." });
            }
          })
          .catch((error) => console.log(error));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [formik.values.email]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      onChange={props.resetError}
      className="d-flex flex-column"
    >
      <div className="row">
        <div className="col-12 col-md-6">
          <InputFormik
            type="text"
            id="firstName"
            text="שם פרטי"
            formik={formik}
          />
        </div>
        <div className="col-12 col-md-6">
          <InputFormik
            type="text"
            id="lastName"
            text="שם משפחה"
            formik={formik}
          />
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          אימייל
        </label>
        <input
          type="email"
          autoComplete="off"
          className={`form-control ${
            !emailIsntTaken && formik.errors.email ? "is-invalid" : "valid"
          } ${emailIsntTaken ? "is-valid" : ""}`}
          id="email"
          value={formik.values.email}
          name="email"
          aria-describedby="Email Input"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmailIsntTaken(false);
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
        />
        {formik.errors.email && formik.touched.email ? (
          <p className="text-danger">{formik.errors.email}</p>
        ) : null}
      </div>
      <InputFormik type="password" id="password" text="סיסמה" formik={formik} />
      <InputFormik
        type="password"
        id="confirmPassword"
        text="אימות סיסמה"
        formik={formik}
      />
      <div className="d-flex">
        <CheckboxFormik id="newsLetterSubscription" formik={formik} />
        <div style={{ display: "inline" }}>
          <span className="mx-1">
            הרשמה לקבלת הטבות / פרסומות, טיפים / מגזין
          </span>
        </div>
      </div>
      <div className="d-flex">
        <CheckboxFormik id="termsAgreement" formik={formik} />
        <div style={{ display: "inline" }}>
          <span className="mx-1">הסכם</span>
          <a
            href="/aboutUs/terms-of-service"
            target="_blank"
            className="link-danger"
          >
            תנאי השימוש
          </a>
        </div>
      </div>
      <Button
        type="submit"
        variant={`danger ${
          props.loading || !emailIsntTaken || !formik.isValid ? "disabled" : ""
        }`}
      >
        {props.loading ? (
          <Spinner variant="light" className="spinner-size" />
        ) : (
          "הרשמה"
        )}
      </Button>
    </form>
  );
};

export default RegisterFormV2;
