import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewGigButton = function () {
  const { verified, userId } = useAppSelector((state) => state.user);
  return (
    <>
      {verified ? (
        <Link
          to={`/users/${userId}/create-new-gig`}
          className="card link-light d-flex align-items-center justify-content-center"
          style={{
            textDecoration: "none",
            marginRight: "5px",
            width: "320px",
            height: "280px",
            marginLeft: "5px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="px-1 bg-success d-flex align-items-center justify-content-center"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                opacity: "0.8",
              }}
            >
              <span className="text-light">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </div>
            <p className="text-dark">יצירת ג'וב חדש!</p>
          </div>
        </Link>
      ) : (
        <div
          className="card link-light d-flex align-items-center justify-content-center"
          style={{
            textDecoration: "none",
            marginRight: "5px",
            width: "320px",
            height: "280px",
            marginLeft: "5px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center opacity-50">
            <div
              className="px-1 bg-success d-flex align-items-center justify-content-center"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                opacity: "0.8",
              }}
            >
              <span className="text-light">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </div>
            <p className="text-dark">יצירת ג'וב חדש!</p>
          </div>
          <p className="text-dark mx-2 text-center">
            אמתו את החשבון ותתחילו למכור!
          </p>
        </div>
      )}
    </>
  );
};

export default NewGigButton;
