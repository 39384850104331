import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const uploadDownloadLinkOrder = function (
  userId: string,
  orderId: string,
  downloadLink: string
) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/orders/${orderId}/download`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/JSON",
    },
    body: JSON.stringify({ downloadLink }),
  });
  // .then((results) => {
  //   if (!results.ok) {
  //     throw new Error("Error");
  //   }
  //   window.location.reload();
  // })
  // .catch((error) => {
  //   console.log(error);
  // });
};
