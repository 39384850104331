import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NotificationsBadge } from "../../../features/chat/components/NotificationsBadge";

type ReactElement = () => JSX.Element;
interface ResponsiveLinkProps {
  children?: string | JSX.Element | JSX.Element[] | ReactElement;
  id: string;
  text?: string;
  showOnlyText?: boolean;
  icon?: IconDefinition;
  notifications?: number;
  to: string;
  color: string;
  notificationPlacement?: {
    top?: string;
    right?: string;
    left?: string;
    bottom?: string;
  };
  closeCanvas?: () => void;
}

const ResponsiveLink = function (props: ResponsiveLinkProps) {
  const {
    id,
    text,
    icon,
    to,
    notifications,
    showOnlyText,
    color,
    notificationPlacement,
    closeCanvas,
  } = props;
  return (
    <>
      {!showOnlyText && (
        <div className="d-none d-md-inline mx-3 link-item">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={id}>{text}</Tooltip>}
          >
            <Link to={to} className={color} style={{ textDecoration: "none" }}>
              {icon && (
                <>
                  {notifications && notifications > 0 ? (
                    <NotificationsBadge
                      notifications={notifications}
                      top={notificationPlacement?.top}
                      bottom={notificationPlacement?.bottom}
                      left={notificationPlacement?.left}
                      right={notificationPlacement?.right}
                    />
                  ) : null}
                  <FontAwesomeIcon icon={icon} />
                </>
              )}
            </Link>
          </OverlayTrigger>
        </div>
      )}
      <div className={!showOnlyText ? "d-inline d-md-none" : ""}>
        <Link
          onClick={closeCanvas}
          to={to}
          className={`${color} d-flex align-items-center`}
          style={{ textDecoration: "none", fontWeight: "500" }}
        >
          {text}
          {notifications && notifications > 0 ? (
            <span className="mx-1">
              <NotificationsBadge
                notifications={notifications}
                position="static"
              />
            </span>
          ) : null}
        </Link>
      </div>
    </>
  );
};

export default ResponsiveLink;
