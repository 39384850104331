import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OrderInfoCard from "./OrderInfoCard";
import { Order } from "../interface/order.interface";

import { Button } from "react-bootstrap";
import { updateOrder } from "../api/order-update";
import BuyerCancelationModal from "./Modals/BuyerCancelationModal";
import BuyerConfirmationModal from "./Modals/BuyerConfirmationModal";

const BuyerSingleOrder = function (props: {
  order: Order;
  orderId: string;
  userId: string;
}) {
  const navigate = useNavigate();
  const { order, orderId, userId } = props;
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const onCancelOrder = function () {
    setCancelLoading(true);
    updateOrder(userId, orderId, "cancel")
      .then((results) => {
        if (!results.ok) {
          throw new Error("Something went wrong");
        }
        navigate(`/users/${userId}/orders`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setCancelLoading(false);
        }, 2000);
      });
  };

  const onApproveOrder = function () {
    setApproveLoading(true);
    updateOrder(userId, orderId, "approve")
      .then((results) => {
        if (!results.ok) {
          throw new Error("Something went wrong");
        }
        navigate(`/users/${userId}/orders/${orderId}/review`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setApproveLoading(false);
        }, 2000);
      });
  };

  return (
    <div>
      <div className="card">
        <OrderInfoCard order={order} userId={userId} />
      </div>
      {order.active && (
        <div className="mt-3">
          {order.status === "IN-PROGRESS" || order.status === "AWAITING" ? (
            <div className="d-flex justify-content-end">
              {/* <Button
                className="col-4 col-md-3 mx-2"
                variant="danger col-4 col-md-3 mx-2"
                type="button"
                onClick={() => setCancelModal(true)}
              >
                ביטול
              </Button> */}
              <Button
                className="col-4 col-md-3 mx-2"
                variant="primary"
                type="button"
                onClick={() => setConfirmationModal(true)}
              >
                אישור קבלת המוצר
              </Button>
            </div>
          ) : (
            <Button
              className="col-4 col-md-3 mx-2"
              variant="danger"
              type="button"
              onClick={() => setCancelModal(true)}
            >
              ביטול
            </Button>
          )}
        </div>
      )}
      <BuyerConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onApprove={onApproveOrder}
        loading={approveLoading}
      />
      <BuyerCancelationModal
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        onCancel={onCancelOrder}
        loading={cancelLoading}
      />
    </div>
  );
};

export default BuyerSingleOrder;
