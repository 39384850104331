import { Fragment } from "react";
import CarouselImage from "./CarouselImage";
import IndicatorButton from "./IndicatorButton";

const Carousel = function (props: {
  gigId: string;
  images?: string[];
  imageHeight?: string;
  imageWidth?: string;
  extraImageClass?: string;
  setModalSrc?: (imgSrc: string) => void;
  openImageModal?: () => void;
  closeImageModal?: () => void;
}) {
  const carouselId = `carousel${props.gigId}`;
  const imagesToRender = props.images
    ? props.images?.filter((img) => img !== "")
    : [];

  return (
    <div
      id={carouselId}
      className="carousel slide"
      data-bs-ride="carousel"
      style={{
        // width: "100%",
        // height: "100%",
        maxHeight: "100%",
        maxWidth: "100%",
      }}
    >
      <div className="carousel-indicators">
        {imagesToRender.length > 1 &&
          imagesToRender.map((image, index) => {
            if (!image) return null;
            return (
              <IndicatorButton
                carouselId={carouselId}
                photoIndex={index}
                key={index}
              />
            );
          })}
      </div>
      <div className="carousel-inner">
        {imagesToRender.length ? (
          <Fragment>
            {imagesToRender.map((image, index) => {
              return (
                <CarouselImage
                  imageUrl={image}
                  photoIndex={index}
                  key={index}
                  width={props.imageWidth}
                  height={props.imageHeight}
                  extraClass={props.extraImageClass}
                  setModalSrc={props.setModalSrc}
                  openImageModal={props.openImageModal}
                />
              );
            })}
            {imagesToRender.length > 1 ? (
              <Fragment>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target={`#${carouselId}`}
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon rounded border bg-dark"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target={`#${carouselId}`}
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon rounded border bg-dark"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </Fragment>
            ) : null}
          </Fragment>
        ) : (
          <CarouselImage
            photoIndex={0}
            width={props.imageWidth}
            height={props.imageHeight}
          />
        )}
      </div>
    </div>
  );
};
export default Carousel;
