import { Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Membership } from "../../../features/user/interface/user.interface";
import { Fragment } from "react";

export const FreePlan = function () {
  const { membership, authCheck } = useAppSelector((state) => state.user);
  return (
    <Card
      className="mt-2"
      style={{
        width: "300px",
        height: "375px",
        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
      }}
    >
      <Card.Title className="mt-3 text-center text-danger">בסיסי</Card.Title>
      <Card.Body className="d-flex flex-column align-items-center p-0">
        <div className="col-12 text-center p-3">
          <h1 style={{ fontSize: "64px" }}>
            <span style={{ fontSize: "32px" }}>₪</span>0
          </h1>
          <span
            style={{ fontWeight: 600, fontSize: "14px" }}
            className="text-secondary"
          >
            לכל החיים
          </span>
        </div>
        <div
          className="col-12 text-center mt-3"
          style={{ fontWeight: 500, fontSize: "15px" }}
        >
          <p className="mt-3">ג'וב אחד לפרסום</p>
        </div>
        {authCheck ? (
          <Fragment>
            {membership?.name !== Membership.free.name ? (
              <Link
                to="/plans/cancel"
                style={{ textDecoration: "none" }}
                className="col-8 btn btn-danger mt-4"
              >
                ביטול תשלום
              </Link>
            ) : (
              <Button className="col-8 mt-4" variant="outline-danger disabled">
                נוכחי
              </Button>
            )}
          </Fragment>
        ) : (
          <Link
            to="/users/register"
            style={{ textDecoration: "none" }}
            className="col-8 btn btn-danger mt-4"
          >
            הירשם עכשיו!
          </Link>
        )}
      </Card.Body>
    </Card>
  );
};
