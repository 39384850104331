import { baseUrl } from "../../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const uploadEducation = function (education: string, userId: string) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/education`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({ education }),
  });
};
