import { Fragment, useRef, useState } from "react";
import Button from "../../../../components/Button";
import Input from "../../../../components/Forms/Input";
import { checkEmail } from "../../../../utils/validators-new";
import { askForReset } from "../../api/password/password-reset";
import { SentMail } from "../../components/SentMail";

const ResetPassPage = function () {
  const [errState, setErrState] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("יש להזין אימייל תקין");
  const [sentMail, setSentMail] = useState<boolean>(false);
  const emailInputRef = useRef<HTMLInputElement>();

  const submitHandler = function (event: React.FormEvent) {
    event.preventDefault();
    const currentEmail = checkEmail(emailInputRef.current?.value);
    if (!currentEmail) {
      setErrState("email");
      return;
    }
    askForReset(currentEmail.toString())
      .then((results) => {
        if (results.ok) {
          setSentMail(true);
        } else throw results;
      })
      .catch((error) => {
        setErrState("email");
        setErrMsg("לא נמצא משתמש על כתובת מייל זו");
      });
  };

  const resetErrors = function () {
    setErrMsg("יש להזין אימייל תקין");
    setErrState("");
  };

  return (
    <div className="container mt-5 d-flex" style={{ minHeight: "100vh" }}>
      {!sentMail ? (
        <Fragment>
          <div
            className="col-12 col-lg-6 d-flex flex-column justify-content-start align-items-start"
            style={{ height: "100vh" }}
          >
            <h3 className="col-12 text-center mt-5 mb-4">
              לא נורא, ניתן לאפס סיסמא בקלות!
            </h3>
            <p className="col-12 text-center">
              מה כתובת המייל איתה נרשמת לאתר?
            </p>
            <div className="col-12">
              <form
                onSubmit={submitHandler}
                className="container d-flex flex-column p-4"
                onChange={resetErrors}
              >
                <Input
                  id="email"
                  type="text"
                  text="אימייל"
                  ref={emailInputRef}
                  extraClass={errState === "email" ? "is-invalid" : ""}
                />
                {errState === "email" && (
                  <p className="text-danger">{errMsg}</p>
                )}

                <Button
                  type="submit"
                  btnStyle="danger"
                  text="שלחו לי לינק לאיפוס!"
                />
              </form>
            </div>
          </div>
          <div className="d-none d-lg-block col-6">
            <img
              src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693684175/haltu_assets/Forgot_password-pana-no-bg_lrapw5.svg"
              alt="שכחתי סיסמה"
            />
          </div>
        </Fragment>
      ) : (
        <SentMail to={emailInputRef.current?.value!} />
      )}
    </div>
  );
};

export default ResetPassPage;
