import { Link } from "react-router-dom";

const JoinUs = function () {
  return (
    <div
      className="mt-5 mt-md-1 d-flex justify-content-center text-light"
      style={{
        height: "400px",
        width: "100%",
        background: "#ff4e5b",
      }}
    >
      <div className="col-10 col-md-3 d-flex flex-column justify-content-center text-center">
        <h5
          style={{
            fontWeight: "700",
            marginBottom: "20px",
            fontSize: "32px",
          }}
        >
          העתיד של הפרוייקטים שלכם <br /> מתחיל עם חלטו
        </h5>
        <Link to="/users/register" className="btn btn-dark p-3 mt-4">
          התחילו עכשיו בחינם
        </Link>
      </div>
    </div>
  );
};

export default JoinUs;
