const MisuseSection = function () {
  return (
    <div>
      <h4>שימוש לא מותר</h4>
      <ul>
        <li>
          שירותים למבוגרים ופורנוגרפיה – חלטו אינה מתירה כל החלפה, העלאה ומכירה
          של חומרים בעלי תוכן מיני או כל שירות למבוגרים או פורנוגרפי.
        </li>
        <li>
          התנהגות ושפה בלתי הולמת – התקשורת בחלטו צריכה להיות ידידותית, בונה
          ומקצועית. חלטו מגנה בריונות, הטרדה ודברי שטנה כלפי אחרים. אנו מאפשרים
          למשתמשים מערכת בה הם יכולים להתכתב ביניהם ומערכת דירוג מוכרים.
        </li>
        <li>
          פישינג וספאם – אבטחת המשתמשים היא בראש סדר העדיפויות. כל ניסיון לפרסם
          או לשלוח תוכן זדוני במטרה לסכן חשבון או סביבת מחשב של משתמש אחר אסורה
          בהחלט. אנא כבדו את פרטיות המשתמשים שלנו בכך שלא תפנו אליהם עם הצעות,
          שאלות, או כל דבר שאינו קשור ישירות לג'ובים או להזמנות שלהם.
        </li>
        <li>
          פרטיות וזהות – אינך רשאי לפרסם או להעלות מידע פרטי של אנשים אחרים. כל
          החלפה של מידע אישי הנדרש להשלמת הג'וב חייב להינתן בעמוד ההזמנה.
          המוכרים בנוסף מתחייבים כי כל מידע שהם יקבלו מהקונה, שאינו נחלת הכלל,
          לא ישמש לכל מטרה שהיא מלבד מסירת העבודה לקונה. כל המשתמשים שיפגשו או
          יתקשרו מחוץ לחלטו לא יהיו מוגנים בתנאי השירות שלנו.
        </li>
        <li>
          פרופיל אותנטי – אינך רשאי ליצור זהות בדויה בחלטו, להציג מידע שגוי
          בנוגע לזהותך, ליצור פרופיל חלטו עבור מישהו אחר מלבדך גם אם הוא אדם
          אמיתי, או להשתמש או לנסות להשתמש בחשבון או במידע של משתמש אחר. פרטי
          הפרופיל שלך, לרבות התיאור, שפות, השכלה, והסמכות, וכו' חייבים להיות
          מדויקים ומלאים ואינם עשויים להיות מטעים, לא חוקיים, פוגעניים או מזיקים
          בדרך כזאת או אחרת. חלטו שומרת לעצמה את הזכות לדרוש מהמשתמשים לעבור
          תהליך אימות על מנת להשתמש באתר בין אם באמצעות טלפון, מצלמה, או תעודה
          מזהה.
        </li>
        <li>
          תביעות קניין רוחני – חלטו תגיב להודעות ברורות ומלאות על הפרת זכויות
          יוצרים או סימן מסחרי לכאורה, ו-או הפרה של תנאי השירות של צד שלישי.
          ניתן לעיין בהליכי תביעות קניין רוחני שלנו כאן.
        </li>
        <li>
          הונאה או שימוש לא חוקי – אינך רשאי להשתמש בחלטו למטרות בלתי חוקיות או
          לביצוע פעילויות בלתי חוקיות.
        </li>
      </ul>
      <h4>שימוש לרעה וספאם</h4>
      <ul>
        <li>
          מספר חשבונות – בכדי למנוע הונאה ושימוש לרעה, המשתמשים מוגבלים לחשבון
          חלטו פעיל אחד, כל חשבון נוסף שיתקיים בכדי לעקוף את ההנחיות, לקדם
          יתרונות תחרותיים או להטעות את קהילת חלטו יושבת. יצירת חשבון המונית
          עלולה לגרום לכך שכל החשבונות הקשורים יושבתו. כל הפרה של התנאים
          וההגבלות של חלטו ו-או תקני הקהילה שלנו היא סיבה להשעיה לצמיתות של כל
          החשבונות.
        </li>
        <li>
          שימוש לרעה ממוקד – אנו לא נראה סובלנות כלפי משתמשים שעוסקים בהתעללות
          או הטרדה ממוקדת כלפי משתמשים אחרים בחלטו. זה כולל יצירת חשבונות מרובים
          חדשים כדי להטריד משתמשים באמצעות ההודעות או מערכת ההזמנות שלנו.{" "}
        </li>
        <li>מכירת חשבונות – אינך רשאי לקנות או למכור חשבונות בחלטו.</li>
      </ul>
    </div>
  );
};

export default MisuseSection;
