export const NotificationsBadge = function (props: {
  notifications: number;
  color?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
}) {
  const {
    notifications,
    left,
    right,
    top,
    bottom,
    color,
    width,
    height,
    fontSize,
  } = props;
  return (
    <span
      style={{
        position: props.position ? props.position : "absolute",
        fontSize: fontSize ? fontSize : "11px",
        color: "white",
        width: width ? width : "20px",
        height: height ? height : "20px",
        left: left,
        bottom: bottom,
        right: right,
        top: top,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={color ? color : "bg-danger"}
    >
      <span style={{ fontWeight: "800" }}>
        {notifications > 99 ? "+99" : notifications}
      </span>
    </span>
  );
};
