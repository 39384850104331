export const CLientsReviewsSection = () => {
  return (
    <div>
      <h2>כתיבת ביקורות הוגנות</h2>
      <p>
        בתור לקוח המשתמש בשירותים המסופקים דרך פלטפורמת haltu.co.il
        ("הפלטפורמה"), תפקידך חשוב בשמירה על קהילת הפרילנסרים האמינה והשקופה.
        ביקורות שמתקבלות מלקוחות מהווות תובנות חשובות למשתמשים אחרים המחפשים
        פרילנסרים לפרויקטים.
      </p>
      <ul>
        <li>
          <span className="terms-underline">משוב סביר:</span>
          בעת השארת ביקורת עבור פרילנסר, אתה מסכים לספק משוב שהוא סביר, המשקף
          בדיוק את איכות השירותים שקיבלת. הביקורות צריכות להתבסס על הניסיון
          האישי שלך עם הפרילנסר וביצועו בפרויקט שלך.
        </li>
        <li>
          <span className="terms-underline">
            ביקורות אובייקטיביות ורלוונטיות:
          </span>
          הביקורות צריכות להיות אובייקטיביות ורלוונטיות לשירותים המסופקים. יש
          לשקול את תקשורת הפרילנסר, יציבות הפרויקט, איכות העבודה ושיתוף הפעולה
          הכללי בזמן כתיבת הביקורות שלך.
        </li>
        <li>
          <span className="terms-underline">תובנות מועילות:</span>
          הביקורת שלך יכולה לספק תובנות מועילות למשתמשים אחרים, עוזרת להם לקבוע
          החלטות ממומנות בעת בחירת פרילנסרים לפרויקטים.
        </li>
        <li>
          <span className="terms-underline">שפה מקצועית ומכובדת:</span>
          הביקורות צריכות להיכתב בשפה מקצועית ומכובדת. יש להימנע משימוש בשפה לא
          הולמת או מתן האשמות בריק.
        </li>
        <li>
          <span className="terms-underline">משוב בונה ואמיץ:</span>
          ביקורות בונות יכולות להיות שימושיות גם לפרילנסרים ולקהילה. אם יש לך
          נושאים שמעניקים לך דאגות, העבר אותם בצורה בונה בביקורת שלך.
        </li>
        <li>
          <span className="terms-underline">שמירה על שקיפות:</span>
          על ידי אספקת ביקורות הוגנות ואמיצות, אתה משתתף בשמירה על שקיפות הסביבה
          בתחום פרילנסינג. שקיפות מועילה כאן גם לפרילנסרים וגם ללקוחות, והיא
          מעניקה אמון ואחריות.
        </li>
        <li>
          <span className="terms-underline">חיזוק יכולת הפרט:</span>
          הביקורות שלך יכולות להוביל אחרים בבחירותיהם ולהשפיע על הצלחתם של
          הפרילנסרים. באמצעות השיתוף של ניסיונו, אתה עוזר ליכולתם של משתמשים
          אחרים לקבוע החלטות.
        </li>
        <li>
          <span className="terms-underline">עריכת או הסרת ביקורת:</span>
          חלטו שומרת לעצמה את הזכות לערוך או להסיר ביקורת אם נחוץ, בלי הודעה
          מראש ללקוח. פעולה זו עשויה להתבצע למטרת שמירה על תקנות והתאמה של
          הביקורות למדיניות הפלטפורמה.
        </li>
      </ul>
    </div>
  );
};
