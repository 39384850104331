import Carousel from "react-multi-carousel";
import { Gig } from "../../gigs/interface/gig.interface";
import UserGigCard from "../../gigs/components/UserGigCard";
import NewGigButton from "../../gigs/components/NewGigButton";
import { useAppSelector } from "../../../store/hooks";
import UserMaxGigs from "../../membership/components/UserMaxGigs";
import { Fragment } from "react";

const UserGigsCarousel = function (props: {
  userGigs: Gig[];
  isUser: boolean;
  userId: string | null;
}) {
  const { userGigs, isUser, userId } = props;
  const { membership } = useAppSelector((state) => state.user);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    largeTablet: {
      breakpoint: { max: 1224, min: 464 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    smallTablet: {
      breakpoint: { max: 824, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 624, min: 464 },
      items: 1,
    },
    superSmallMobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel responsive={responsive} rtl={true} className="col-12">
      {isUser ? (
        <>
          {!userGigs || membership!.maxGigs > userGigs.length ? (
            <NewGigButton />
          ) : (
            <UserMaxGigs />
          )}
        </>
      ) : null}
      {userGigs && userGigs.length ? (
        userGigs.map((gig) => {
          return (
            <div key={gig._id} style={{ marginRight: "3px" }}>
              <UserGigCard gig={gig} isUser={isUser} userId={userId} />
            </div>
          );
        })
      ) : (
        <Fragment>
          {!isUser ? (
            <div
              className="card d-flex align-items-center justify-content-center"
              style={{
                height: "280px",
                width: "340px",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              אין ג'ובים למשתמש זה
            </div>
          ) : null}
        </Fragment>
      )}
    </Carousel>
  );
};

export default UserGigsCarousel;
