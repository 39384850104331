import { useState } from "react";
import { Order } from "../interface/order.interface";
import OrderComments from "./OrderComments/OrderComments";
import UploadLinkSection from "./UploadLinkSection";
import Revisions from "./Revisions";
import UserCard from "../../user/components/Cards/UserCard";
import ImageModal from "../../../components/ImageModal";
import { addDots } from "../../../utils/trimmers";
import { Link } from "react-router-dom";

const OrderInfoCard = function (props: {
  order: Order;
  userId: string | null;
}) {
  const { order, userId } = props;

  const [imageModalSrc, setImageModalSrc] = useState<string>("");
  const [showImageModal, setShowImageModal] = useState<boolean>(false);

  const otherUser =
    userId === order.buyerId._id ? order.sellerId : order.buyerId;

  const dueDate = new Date(order.dueDate);

  const today = new Date(Date.now());

  const overdue: boolean = dueDate <= today;
  let badgeColor: string;
  let badgeText: string;
  switch (props.order.status) {
    case "FINISHED": {
      badgeColor = "success";
      badgeText = "הסתיימה";
      break;
    }
    case "CANCELED": {
      badgeColor = "danger";
      badgeText = "בוטלה";
      break;
    }
    case "PENDING": {
      badgeColor = "primary";
      badgeText = "אישור מוכר";
      break;
    }
    case "AWAITING": {
      badgeColor = "primary";
      badgeText = "אישור לקוח";
      break;
    }
    case "IN-PROGRESS": {
      if (overdue) {
        badgeColor = "warning";
        badgeText = "איחור";
      } else {
        badgeColor = "dark";
        badgeText = "תהליך";
      }
      break;
    }
    default: {
      badgeColor = "light";
      badgeText = "לא ידוע";
      break;
    }
  }

  return (
    <div className="card-body">
      <div className="pb-3 border-bottom d-flex flex-column flex-md-row">
        <div className="col-12 col-md-3 card">
          <UserCard user={otherUser} isUser={false} />
          {otherUser.active === false ? (
            <h3 className="text-center text-danger">משתמש זה מושהה</h3>
          ) : null}
        </div>
        <div className="col-12 col-md-8 mx-0 mx-md-5">
          <div className="pb-1 border-bottom d-flex flex-column flex-md-row justify-content-between">
            <div className="text-center text-md-start mt-4">
              <h4>הזמנה:# {order._id}</h4>
            </div>
            <div className="d-flex flex-column align-items-center">
              <h5 className="text-center m-0">סטטוס:</h5>
              <p
                className={`badge rounded-pill mt-2 text-bg-${badgeColor} fs-5`}
              >
                <span>{badgeText}</span>
              </p>
            </div>
          </div>
          <div className="mt-2 d-flex flex-column flex-md-row">
            <div className="col-12 col-md-7 text-center text-md-start border-end">
              <h4>תיאור ההזמנה:</h4>
              {order.description ? (
                <div className="mt-3">{order.description}</div>
              ) : (
                <div className="mt-3">לא קיים תיאור להזמנה</div>
              )}
            </div>
            <div className="col-12 col-md-5 text-center text-md-start">
              <h4 className="text-center">תמונות לדוגמה:</h4>
              <div></div>
              {props.order.imagesUrls[0] ? (
                <div className="d-flex justify-content-center">
                  {props.order.imagesUrls.map((image, index) => {
                    if (!image) return null;
                    return (
                      <img
                        key={index}
                        src={image}
                        alt="..."
                        className="m-2 card"
                        onClick={() => {
                          setImageModalSrc(image);
                          setShowImageModal(true);
                        }}
                        style={{
                          width: "100px",
                          height: "100px",
                          cursor: "pointer",
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <p className="mt-3 text-center">לא קיימות תמונות לדוגמה</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-3 border-bottom">
        <h3>ג'וב:</h3>
        <div className="d-flex">
          <div className="col-3">
            <h5>
              כותרת: <span>{addDots(order.gigId.title, 15)}</span>
            </h5>
          </div>
          <div className="col-3">
            <h5>
              מחיר: <span>₪{order.gigId.price}</span>
            </h5>
          </div>
          <div className="col-3">
            <h5>
              ימי אספקה: <span>{order.gigId.deliveryDays}</span>
            </h5>
          </div>
          <div className="col-3 text-center">
            {order.gigId.active === true && order.sellerId.active === true ? (
              <Link
                to={`/gigs/${order.gigId.category.urlName}/${order.gigId._id}`}
                className="link-danger"
              >
                צפייה
              </Link>
            ) : (
              <span>ג'וב זה מושהה</span>
            )}
          </div>
        </div>
      </div>
      <Revisions order={order} userId={userId} />
      <OrderComments
        comments={props.order.comments}
        isActive={props.order.active}
      />
      <UploadLinkSection order={order} />
      <ImageModal
        showImageModal={showImageModal}
        closeModal={() => {
          setShowImageModal(false);
          setImageModalSrc("");
        }}
        imgSrc={imageModalSrc}
      />
    </div>
  );
};

export default OrderInfoCard;
