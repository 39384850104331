import { createSlice } from "@reduxjs/toolkit";

interface OnlineSocket {
  socketId: string;
  userId: string;
}

interface InitialState {
  serverError: null | string;
  onlineUsers: OnlineSocket[];
}

const initialState: InitialState = {
  serverError: null,
  onlineUsers: [],
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setOnlineUsers(state, action) {
      state.onlineUsers = action.payload;
    },
    setError(state, action) {
      state.serverError = action.payload;
    },
    resetError(state) {
      state.serverError = null;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
