import { User } from "../../interface/user.interface";

import { uploadLanguages } from "../../api/user-info/user-info-update/user-languages";
import { uploadEducation } from "../../api/user-info/user-info-update/user-education";
import { uploadCertifications } from "../../api/user-info/user-info-update/user-certifications";

import EditField from "../Fields/EditField";

const UserDataCard = function (props: { userInfo: User; isUser: boolean }) {
  return (
    <div className="d-flex flex-column flex-md-row">
      <div className="col-12 col-md-4">
        <EditField
          field={props.userInfo.languages}
          fieldName={"שפות"}
          uploadFunc={uploadLanguages}
          isUser={props.isUser}
          fieldPlaceholder="רישמו את השפות שאתם מכירים, ואת הרמה שבה אתם דוברים אותה"
        />
      </div>
      <div className="col-12 col-md-4 border-start border-end">
        <EditField
          field={props.userInfo.education}
          fieldName={"השכלה"}
          uploadFunc={uploadEducation}
          isUser={props.isUser}
          fieldPlaceholder="הוספיו את ההשכלה שרכשתם במהלך השנים"
        />
      </div>
      <div className="col-12 col-md-4">
        <EditField
          field={props.userInfo.certifications}
          fieldName={"הסמכות"}
          uploadFunc={uploadCertifications}
          isUser={props.isUser}
          fieldPlaceholder="ציינו את ההסמכות שקיימות ברשותכם ואת המוסד"
        />
      </div>
    </div>
  );
};

export default UserDataCard;
