import { baseUrl } from "../../../API/base-urls";
// import { Gig } from "../../gigs/interface/gig.interface";
import { OrderOutput } from "../interface/order-output.interface";

const URL = `${baseUrl}/gigs`;

// export const createBalanceCheckout = function (gig: Gig, total: number) {
//   const token = localStorage.getItem("token");
//   return fetch(`${URL}/${gig.category._id}/${gig._id}/balance-check-out`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "Application/JSON",
//     },
//     body: JSON.stringify({ total }),
//   });
// };

// export const createPayPalOrderId = function (gig: Gig) {
//   const token = localStorage.getItem("token");
//   return fetch(`${URL}/${gig.category._id}/${gig._id}/payPal`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "Application/json",
//     },
//   });
// };

export const createOrder = function (
  newOrder: OrderOutput
  // paymentInfo: object
) {
  const token = localStorage.getItem("token");
  const { gig, description, images } = newOrder;
  return fetch(`${URL}/${gig.category._id}/${gig._id}/check-out`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    // body: JSON.stringify({ description, images, paymentInfo }),
    body: JSON.stringify({ description, images }),
  })
    .then((results) => {
      if (!results.ok) {
        switch (results.status) {
          case 401: {
            window.location.replace("/");
            break;
          }
          case 403: {
            window.location.replace("/");
            break;
          }
          default: {
            throw new Error("Something went wrong!");
          }
        }
      }
      return results.json();
    })
    .then((orderDetails) => {
      const { order } = orderDetails;
      return order;
      // window.location.replace(`/users/${order.buyerId}/orders/${order._id}`);
    })
    .catch((error) => {
      console.log(error);
    });
  //     const token = localStorage.getItem("token");
  //     const { description, images, gig } = newOrder;
  //     const submitOrder = fetch(
  //       `${URL}/${gig.category._id}/${gig._id}/check-out`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "Application/json",
  //         },
  //         body: JSON.stringify({ description, images }),
  //       }
  //     )
  // .then((results) => {
  //         return results.json();
  //       })
  //       .then((orderDetails) => {
  //         const { order } = orderDetails;
  //         console.log(order);
  //         window.location.replace(`/users/${order.buyerId}/orders/${order._id}`);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };
};
