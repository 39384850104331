import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getFavorites } from "../../features/favorites/api/favorites-read";

export const useGetFavorites = function () {
  const dispatch = useAppDispatch();
  const { authCheck } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (authCheck) {
      dispatch(getFavorites());
    }
  }, [dispatch, authCheck]);
};
