import { baseUrl } from "../../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const uploadCertifications = function (
  certifications: string,
  userId: string
) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/certification`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({ certifications }),
  });
};
