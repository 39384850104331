import { useState } from "react";
import { Link } from "react-router-dom";
import { Gig } from "../interface/gig.interface";
import { deleteGig } from "../api/gig-delete-api";
import { addDots } from "../../../utils/trimmers";
import { useAppDispatch } from "../../../store/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import DeletionModal from "./DeletionModal";

interface UserGigCardProps {
  gig: Gig;
  isUser: boolean;
  userId: string | null;
}

const UserGigCard = function (props: UserGigCardProps) {
  const defaultImage =
    "https://res.cloudinary.com/dm20uwmki/image/upload/v1690181148/haltu_assets/no_image_found_jchi3o.png";

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onGigDelete = function () {
    setLoading(true);
    dispatch(deleteGig(props.gig._id!, (val: boolean) => setLoading(val)));
  };
  return (
    <div className="card" style={{ height: "280px", width: "340px" }}>
      <div>
        {props.gig.imagesUrls !== undefined && (
          <img
            src={props.gig.imagesUrls[0]! || defaultImage}
            alt="..."
            className="d-block w-100 rounded-top"
            style={{ width: "100px", height: "160px" }}
          />
        )}
        <Link
          to={`/users/${props.gig.userId?._id}`}
          className="link-secondary"
          style={{ textDecoration: "none" }}
        >
          {props.gig.userId?.firstName} {props.gig.userId?.lastName}
        </Link>
        <Link
          to={`/gigs/${props.gig.category.urlName}/${props.gig._id}`}
          className="link-danger"
          style={{ textDecoration: "none" }}
        >
          <h5 className="mt-3 mb-2 mx-2 card-title">
            {addDots(props.gig.title)}
          </h5>
        </Link>
      </div>
      <div className="mt-2 border-top d-flex justify-content-between align-items-center">
        {props.isUser && (
          <div className="dropdown mx-1">
            <button
              className="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link
                  to={`/users/${props.userId}/edit-gig`}
                  state={props.gig}
                  className="dropdown-item"
                  type="button"
                >
                  ערוך
                </Link>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  מחק
                </button>
              </li>
            </ul>
          </div>
        )}
        <div className="d-flex flex-column mx-2" style={{ fontSize: "80%" }}>
          מחיר
          <span className="text-end fs-4">₪{props.gig.price}</span>
        </div>
      </div>
      <DeletionModal
        loading={loading}
        showDeletionModal={showModal}
        closeModal={() => setShowModal(false)}
        onGigDelete={onGigDelete}
      />
    </div>
  );
};

export default UserGigCard;
