import { Order } from "../interface/order.interface";
import { useAppSelector } from "../../../store/hooks";
import { useState, useEffect } from "react";

import BuyerDownload from "./BuyerDownload";
import SellerDownload from "./SellerDownload";

const UploadLinkSection = function (props: { order: Order }) {
  const { order } = props;
  const { userId } = useAppSelector((state) => state.user);

  const [isBuyer, setIsBuyer] = useState<boolean>(false);

  useEffect(() => {
    setIsBuyer(false);
    if (userId) {
      setIsBuyer(userId === order.buyerId._id.toString());
    }
  }, [userId, order.buyerId]);

  return (
    <div>
      {isBuyer && order.downloadLink ? (
        <BuyerDownload downloadLink={order.downloadLink} />
      ) : isBuyer ? null : (
        <SellerDownload userId={userId} order={order} />
      )}
    </div>
  );
};

export default UploadLinkSection;
