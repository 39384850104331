import { Link } from "react-router-dom";
const FooterLinks = function () {
  return (
    <div className="container d-flex justify-content-center text-center">
      <div
        className="d-flex flex-column mt-3 text-light col-12 col-lg-2 align-items-center"
        style={{ fontWeight: "600" }}
      >
        {/* <Link
          to="/aboutUs/faq"
          className="link-light"
          style={{ textDecoration: "none" }}
        >
          שאלות ותשובות
        </Link> */}
        <Link
          to="/about-us/terms-of-service"
          className="link-dark"
          style={{ textDecoration: "none" }}
        >
          תנאי השימוש באתר
        </Link>
        <Link
          to="/about-us/privacy-policy"
          className="link-dark"
          style={{ textDecoration: "none" }}
        >
          הצהרת פרטיות
        </Link>
        <Link
          to="/plans/pricing"
          className="link-dark"
          style={{ textDecoration: "none" }}
        >
          טבלאת מחירים
        </Link>
      </div>
    </div>
  );
};

export default FooterLinks;
