import { Message } from "../../interface/Message.interface";
import UserImage from "../../../user/components/Image/UserImage";

const MessagePill = function (props: { message: Message; isUser: boolean }) {
  const { isUser, message } = props;
  return (
    <div
      className={`d-flex align-items-center mt-2 mb-2 ${
        !isUser && "flex-row-reverse"
      }`}
    >
      <div className="mx-2 align-self-start">
        <UserImage
          user={message.sender}
          canEdit={false}
          size={32}
          fontSize="14px"
        />
      </div>
      <span
        className={`text-bg-${isUser ? "danger" : "secondary"} p-2`}
        style={{
          minWidth: "40px",
          maxWidth: "60%",
          borderRadius: "15px",
          padding: "8px",
          marginRight: "8px",
          marginTop: "6px",
          marginBottom: "6px",
          borderTopRightRadius: isUser ? "0px" : "15px",
          borderTopLeftRadius: isUser ? "15px" : "0px",
        }}
      >
        {message.text}
      </span>
    </div>
  );
};

export default MessagePill;
