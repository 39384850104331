import { Fragment } from "react";

const BuyerDownload = function (props: { downloadLink: string }) {
  const { downloadLink } = props;
  return (
    <Fragment>
      <h5>קישור למוצר / שירות:</h5>
      <div className="form-control">
        <a href={`//${downloadLink}`} target="_blank" rel="noreferrer">
          {downloadLink}
        </a>
      </div>
      <p className="mt-2 mb-0 text-secondary" style={{ fontSize: "14px" }}>
        חלטו לא אחראית על כל נזק אשר נגרם ע"י הורדת מוצר זה
      </p>
    </Fragment>
  );
};

export default BuyerDownload;
