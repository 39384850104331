import { Modal, Button, Spinner } from "react-bootstrap";

const UploadModal = function (props: {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  loading: boolean;
}) {
  const { open, loading } = props;
  if (!open) return null;
  return (
    <Modal
      show={open}
      onHide={props.onClose}
      className="d-flex align-items-center justify-content-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>אישור העלאת קישור</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          בעת לחיצה על אישור, יישלח מייל אוטומטי לקונה שהועלה קישור להורדת
          מוצריו וההזמנה תעבור לסטטוס ממתין.
          <br />
          אנא וודאו כי הקישור להורדה תקין, וההורדה איננה מכילה תוכנות זדוניות
          כלשהן.
          <br />
          רק לאחר אישור הקונה, הזמנה זו תסתיים.
        </p>
        <p className="text-secondary text-center" style={{ fontSize: "14px" }}>
          במידה ותתקבל תלונה על קישור זדוני, והדבר ימצא לנכון, חלטו תשקול השהיית
          חשבון זה.
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button
          className="col-4 col-md-3 mx-2"
          variant="danger"
          type="button"
          onClick={props.onClose}
        >
          ביטול
        </Button>
        <Button
          variant={`primary ${loading ? "disabled" : ""}`}
          className="col-4 col-md-3 mx-2"
          type="button"
          onClick={props.onApprove}
        >
          {loading ? <Spinner size="sm" /> : "אישור"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModal;
