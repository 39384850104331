import { Link } from "react-router-dom";
// import SearchBar from "../../../components/Navbar/NavbarComp/SearchBar/SearchBar";

const HeroSection = function () {
  return (
    <div
      className="d-flex justify-content-center text-center text-light"
      style={{
        background:
          "url(https://res.cloudinary.com/dm20uwmki/image/upload/v1694514650/haltu_assets/background2_di5bzl.png) no-repeat center top",
        backgroundSize: "cover",
        height: "150%",
      }}
    >
      <div className="d-flex flex-column" style={{ marginTop: "150px" }}>
        <h1 style={{ fontSize: "52px" }}>
          הרשמו, פרסמו <br /> והבטיחו את עתידכם העסקי
        </h1>
        <p>
          הכירו את חלטו, הפלטפורמה החדשה לפרילנסרים בישראל, פה תוכלו למצוא
          שירותים של מיטב הפרילנסרים בישראל. <br />
          אז קדימה, למה אתם מחכים? הירשמו עכשיו! {"(זה בחינם)"}
        </p>
        <div className="col-12">
          {/* <a
            href="#howItWorks"
            className="mx-2 col-4 col-md-2 btn btn-outline-primary"
          >
            איך זה עובד?
          </a> */}
          <Link
            to={"/users/register"}
            className="mx-2 col-6 col-md-4 p-3 btn btn-dark"
          >
            התחילו עכשיו בחינם
          </Link>
        </div>

        <div className="mt-5 mx-2">
          <img
            className="align-self-center"
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694517872/haltu_assets/gig-page_xsz6oq.png"
            alt="עמוד ג'וב"
            style={{
              width: "90%",
              borderRadius: "12px",
              boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.8)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
