import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/Forms/LoginForm";
import { Link } from "react-router-dom";
import { loginUser } from "../api/user-login";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

export interface UserInfo {
  email: string;
  password: string;
}

const LoginPage = function () {
  const { loading, error, authCheck } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onLogin = function (userInfo: UserInfo) {
    dispatch(loginUser(userInfo));
  };
  useEffect(() => {
    if (authCheck) {
      navigate(-1);
    } else return;
  }, [authCheck, navigate]);

  return (
    <div className="mt-5 p-3 p-md-5 container" style={{ minHeight: "100vh" }}>
      <div className="d-flex justify-content-center mt-5">
        <div
          className="col-12 col-md-6 p-4 card"
          style={{ boxShadow: "0px 0px 4px rgba(0,0,0,0.2)" }}
        >
          <div>
            <h3 className="mb-5 text-center">היי, ברוכים השבים</h3>
            <LoginForm
              onLogin={onLogin}
              serverError={error}
              loading={loading}
            />
            <div className="text-center mt-3">
              <span>חדש כאן? </span>
              <Link
                to={`/users/register`}
                className="link-danger"
                style={{ textDecoration: "none" }}
              >
                להרשמה
              </Link>
            </div>
          </div>
        </div>
        <div className="d-none d-xxl-block mt-4">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693237795/haltu_assets/Make_it_rain-pana-no-bg4_ymu0dg.svg"
            alt="ברוך הבא!"
            style={{ width: "700px", height: "500px", marginRight: "50px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
