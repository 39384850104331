import { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import { Review } from "../interface/review-interface";
import ReviewCard from "./ReviewCard";

const ReviewsSection = function (props: {
  reviews: Review[];
  header: string;
  noReviewsText: string;
}) {
  const { reviews, header, noReviewsText } = props;

  const sortedReviews = reviews.sort((reviewA, reviewB) => {
    return Date.parse(reviewB.createdAt) - Date.parse(reviewA.createdAt);
  });

  const itemsPerPage = 5;
  const [currentItems, setCurrentItems] = useState<Review[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(sortedReviews.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sortedReviews.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, sortedReviews]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % sortedReviews.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <div
        className="mt-3"
        style={{ minHeight: sortedReviews.length > 5 ? "1000px" : "600px" }}
      >
        <h5 className="border-bottom pb-2">{header}</h5>
        {sortedReviews.length === 0 ? (
          <p className="mt-3">{noReviewsText}</p>
        ) : (
          currentItems.map((review, index) => {
            return <ReviewCard key={index} review={review} />;
          })
        )}
      </div>
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-item btn btn-light"
          previousLinkClassName="page-item btn btn-light"
          nextLinkClassName="page-item btn btn-light"
          activeLinkClassName="active"
        />
      </div>
    </>
  );
};

export default ReviewsSection;
