import React from "react";
import DropDownItem from "./DropDownItem";

interface DropDownProps {
  items: any[];
  text: string;
  name: string;
  value?: string;
  onChange: (event: React.ChangeEvent) => void;
}

const DropDown = function (props: DropDownProps) {
  return (
    <div className="dropdown">
      <label htmlFor="dropdown" className="col-3 col-md-2 m-3">
        {props.text}:
      </label>
      <select
        id="dropdown"
        name={props.name}
        className="col-6 col-md-3 col-xxl-4 btn btn-secondary dropdown dropdown-toggle"
        onChange={props.onChange}
        value={props.value}
      >
        <option value={""}></option>
        {props.items?.map((item) => {
          return <DropDownItem key={item._id} item={item} />;
        })}
      </select>
    </div>
  );
};

export default DropDown;
