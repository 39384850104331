import { baseUrl } from "../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const tokenValidity = function (token: string) {
  return fetch(`${URL}/reset-password/${token}`)
    .then((results) => {
      if (!results.ok) {
        console.log(results);
        window.location.replace("/");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
