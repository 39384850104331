import { Link } from "react-router-dom";
import { askForReset } from "../api/password/password-reset";
import { useState } from "react";

export const SentMail = function (props: { to: string }) {
  const { to } = props;
  const [sentAgain, setSentAgain] = useState<boolean>(false);
  const sendEmailAgain = () => {
    if (!to || sentAgain) {
      return;
    } else {
      askForReset(to)
        .then((results) => {
          if (results.ok) {
            setSentAgain(true);
            return;
          } else {
            throw results;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div
      className="d-flex flex-column flex-md-row justify-content-center align-items-center col-12"
      style={{ maxHeight: "55vh" }}
    >
      <div className="col-12 col-md-5 d-flex justify-content-center">
        <img
          style={{ width: "30vw" }}
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694069372/haltu_assets/Confirmed-pana-no-bg4_bbzgue.png"
          alt="נשלח מייל בהצלחה"
        />
      </div>
      <div className="align-self-center col-12 col-md-5 d-flex flex-column align-items-center justify-content-center">
        <div className="text-center" style={{ fontSize: "22px" }}>
          <p>שלחנו קישור לאיפוס הסיסמה למייל: </p>
          <p>{props.to}</p>
          <p
            className="text-danger"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={sendEmailAgain}
          >
            לא קיבלתי את המייל, שלחו לי מייל חדש
          </p>
          {sentAgain && <p>נשלח קישור נוסף לאיפוס הסיסמה</p>}
        </div>
        <Link to={"/"} className="col-6 btn btn-danger mt-3">
          לעמוד הבית
        </Link>
      </div>
    </div>
  );
};
