import UserImage from "../Image/UserImage";
import { User } from "../../interface/user.interface";
// import { faUser } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewsSum from "../../../reviews/components/ReviewsSum";
import VerificationBadge from "../VerificationBadge";

const UserCard = function (props: { user: User; isUser: boolean }) {
  // const date = new Date(Date.parse(props.user.createdAt));
  // const year = date.getFullYear();
  // const month = date.toLocaleString("heb", { month: "short" });
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mt-3">
        <UserImage
          user={props.user}
          canEdit={props.isUser}
          size={150}
          showOnline={true}
        />
      </div>
      <div className="card-body text-center">
        <div className="d-flex align-items-center justify-content-center">
          <VerificationBadge idVerification={props.user.idVerification} />
          <h5 className="m-0 mx-2 card-title">{`${props.user.firstName} ${props.user.lastName}`}</h5>
        </div>
        <ReviewsSum receivedReviews={props.user.receivedReviews} />
        <p className="card-text text-secondary" style={{ fontSize: "14px" }}>
          {props.user.email}
        </p>
      </div>
    </div>
  );
};

export default UserCard;
