import { Gig } from "../../../interface/gig.interface";
import TextArea from "../../../../../components/Forms/TextArea";
import FileInput from "../../../../../components/Forms/FileInput";
import { GigFormOutput } from "../../../interface/gig-form-output";

import ProgressButtons from "./ProgressButtons";

interface ThirdSectionProps {
  setUploadedFiles: any;
  uploadedFiles: string[];
  onChange: (event: React.ChangeEvent) => void;
  formData: GigFormOutput;
  multiple: boolean;
  progress: number;
  onProgress: (event: any) => void;
  btnText: string;
  gig: Gig | undefined;
  errState: string | null;
  loading: boolean;
}

const ThirdSection = function (props: ThirdSectionProps) {
  return (
    <div>
      <h1 className="pb-2 border-bottom">העלאת תמונות & דרישות</h1>
      <label>תמונות:</label>
      <div className="row mb-5 mt-3">
        {props.uploadedFiles.map((file, index) => {
          return (
            <div
              className="col-4 col-md-2"
              key={index}
              style={{ height: "200px" }}
            >
              <FileInput
                index={index}
                setUploadedFiles={props.setUploadedFiles}
                uploadedFiles={props.uploadedFiles}
                overlayPosition="275px"
              />
            </div>
          );
        })}
      </div>
      <div className="mt-5 mb-5">
        <TextArea
          id="requirements"
          text=" דרישות מהקונה (לא חובה):"
          onChange={props.onChange}
          placeholder="רשום מה תצטרך מהקונה בשביל להשלים את החלטורה"
          value={props.formData.requirements}
          // extraClass={props.errState === "requirements" ? "is-invalid" : ""}
        />
      </div>
      <ProgressButtons
        btnText={props.btnText}
        progress={props.progress}
        onProgress={props.onProgress}
        loading={props.loading}
      />
    </div>
  );
};

export default ThirdSection;
