import { useState, useEffect } from "react";

import OrderCard from "../OrderCard";
import { Order } from "../../interface/order.interface";

import ReactPaginate from "react-paginate";

const BoughtOrders = function (props: { orders: Order[] }) {
  const { orders } = props;
  const itemsPerPage = 7;
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<Order[]>([]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(orders.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(orders.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, orders]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    setItemOffset(newOffset);
  };
  return (
    <div>
      {orders.length ? (
        <div style={{ minHeight: "450px" }}>
          {currentItems.map((order) => {
            return <OrderCard order={order} key={order._id} />;
          })}
        </div>
      ) : (
        <div
          style={{ minHeight: "505px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <h2>לא נמצאו רכישות</h2>
        </div>
      )}
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-item btn btn-light"
          previousLinkClassName="page-item btn btn-light"
          nextLinkClassName="page-item btn btn-light"
          activeLinkClassName="active"
        />
      </div>
    </div>
  );
};

export default BoughtOrders;
