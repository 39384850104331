export const IndemnificationSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>שיפוי</h2>
      <p>
        אתה מסכים להגן ולשפות את חלטו, כולל עובדיה,מנהליה, שותפיה או מי מטעמם
        ללא פשרה בגין כל תביעה,טענה, נזקים, הפסדים, אובדן,עלויות, והוצאות (כולל
        דמי שכר טרחת עורך דין והוצאות משפט סבירות) שנגרמו בעקבות שימוש המשתמש
        באתר או בתכניו ו-או הפרתם של תנאים וההגבלות אלו באתר, או כל תוכן שאתה
        מגיש, מפרסם, משדר או מוצג דרך האתר, לרבות אך מבלי לגרוע מהאמור לעיל
        תביעות בגין אי שביעות רצון מהשירותים המפורסמים באתר.
      </p>
    </div>
  );
};
