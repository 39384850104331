const ServerErrorPage = function () {
  return (
    <div
      className="d-flex flex-column align-items-center"
      style={{ marginTop: "150px" }}
    >
      <h1 style={{ fontSize: "36px" }}>מצטערים, משהו השתבש!</h1>
      <p>אנא נסו שנית במועד מאוחר יותר!</p>
      <img
        src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693683379/haltu_assets/7967792_3819285_herq2q.svg"
        style={{ width: "80vh" }}
        alt="..."
      />
    </div>
  );
};

export default ServerErrorPage;
