import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useAppSelector } from "../../../../../store/hooks";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createUserMessage } from "../../../api/user-chat-create";

const MiniChatForm = function (props: { to: string }) {
  const { userId } = useAppSelector((state) => state.user);
  const [message, setMessage] = useState<string>("");
  const [errState, setErrState] = useState<boolean>(false);

  const submitHandler = function (e: React.FormEvent) {
    e.preventDefault();
    if (userId === null) return;
    if (!message) {
      setErrState(true);
      return;
    } else {
      setMessage("");
      createUserMessage(props.to, message, userId);
    }
  };

  return (
    <Form
      onSubmit={submitHandler}
      className="d-flex align-items-center justify-content"
    >
      <input
        type="text"
        className={`form-control ${errState && "is-invalid"}`}
        id="message"
        value={message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setErrState(false);
          setMessage(e.target.value);
        }}
      />
      <Button
        type="submit"
        variant={`danger ${errState || message === "" ? "disabled" : null}`}
        style={{ borderRadius: "50%", marginRight: "15px" }}
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </Button>
    </Form>
  );
};

export default MiniChatForm;
