import { useEffect, useState, useRef } from "react";
import { getUserMessages } from "../api/user-chat-read";

import { Socket } from "socket.io-client";
import { Message } from "../interface/Message.interface";
import { Conversation } from "../interface/Conversation.interface";
import { User } from "../../user/interface/user.interface";

import MessageForm from "./MessagesComp/MessageForm";
import MessagePill from "./MessagesComp/MessagePill";
import UserImage from "../../user/components/Image/UserImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const MessagesPane = function (props: {
  isMobile: boolean;
  otherUser: User | null;
  setOtherUser: (user: User) => void;
  userId: string | null;
  socket: Socket;
  currentConversation: Conversation;
  closeConversation: () => void;
}) {
  const {
    userId,
    socket,
    currentConversation,
    closeConversation,
    isMobile,
    otherUser,
    setOtherUser,
  } = props;
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [reload, setReload] = useState<boolean>(true);
  const [localConversation, setLocalConversation] = useState<string>("");
  const [messages, setMessages] = useState<Message[] | null>(null);

  socket.on("newMessageEvent", (data) => {
    const { conversationId } = data;
    if (conversationId !== currentConversation._id) return;
    else {
      setReload(true);
    }
  });

  useEffect(() => {
    if (currentConversation._id !== localConversation) {
      setReload(true);
      setLocalConversation(currentConversation._id);
    } else {
      return;
    }
  }, [currentConversation._id, localConversation]);

  useEffect(() => {
    if (reload) {
      getUserMessages(currentConversation._id)
        .then((userMessages) => {
          const { firstUser, secondUser } = currentConversation;
          setMessages(userMessages.messages);
          if (firstUser.user._id === userId) {
            setOtherUser(secondUser.user);
          } else {
            setOtherUser(firstUser.user);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReload(false);
        });
    }
    messagesEndRef.current?.scrollIntoView({
      inline: "start",
      block: "nearest",
      behavior: "auto",
    });
  }, [currentConversation, reload, setReload, userId, setOtherUser]);

  return (
    <>
      <div
        className={`${!isMobile ? "col-12 col-md-6" : "col-12"} ${
          isMobile && !currentConversation && "d-none"
        }`}
        style={{ height: "85vh" }}
      >
        <div className="d-flex align-items-center justify-content-between p-2 bg-light">
          <div className="d-flex align-items-center">
            <div className="mx-2">
              {otherUser && (
                <UserImage
                  user={otherUser}
                  canEdit={false}
                  size={48}
                  fontSize="24px"
                />
              )}
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginRight: "5px",
              }}
            >
              {otherUser?.firstName} {otherUser?.lastName}
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              className="text-secondary close-btn"
              icon={faClose}
              style={{ fontSize: "32px", marginLeft: "5px", cursor: "pointer" }}
              onClick={closeConversation}
            />
          </div>
        </div>

        <div style={{ overflowY: "scroll", height: "67vh" }}>
          {messages && messages.length
            ? messages.map((message) => {
                return (
                  <MessagePill
                    key={message._id}
                    message={message}
                    isUser={message.sender._id === userId}
                  />
                );
              })
            : null}
          <div ref={messagesEndRef} />
        </div>
        <div className="mt-3">
          {otherUser && (
            <div className="mx-2 mb-1">
              <MessageForm to={otherUser._id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessagesPane;
