import { useEffect, useState } from "react";
import { Gig } from "../../../../features/gigs/interface/gig.interface";
import { getMostPopularCategoryGigs } from "../../../../features/gigs/api/gig-read-api";

import Carousel from "react-multi-carousel";
import { GigCard } from "../../../../features/gigs/components/GigCard";
import { useAppSelector } from "../../../../store/hooks";
import { Link } from "react-router-dom";

const PopularGigs = () => {
  const [url, setUrl] = useState<string | undefined>();
  const [categoryHebName, setCategoryHebName] = useState<string | undefined>();
  const [popularGigs, setPopularGigs] = useState<Gig[] | null>(null);
  const { userId } = useAppSelector((state) => state.user);
  const { categories } = useAppSelector((state) => state.categories);

  useEffect(() => {
    let browsedCategories: string[] = [];
    const ls = localStorage.getItem("browsedCategories");
    if (ls) {
      browsedCategories = JSON.parse(ls);
      const rd = Math.floor(Math.random() * browsedCategories.length);
      try {
        const urlName = browsedCategories[rd];
        userId &&
          getMostPopularCategoryGigs(urlName)
            .then(
              (results: {
                gigs: Gig[];
                categoryId: string;
                categoryHebName: string;
              }) => {
                let reviewedGigs: Gig[] = [];
                results.gigs.forEach((gig) => {
                  let addGig = false;
                  if (gig.userId._id !== userId) {
                    gig.userId.receivedReviews.forEach((review) => {
                      if (review.gig.toString() === gig._id) {
                        addGig = true;
                      } else {
                        return;
                      }
                    });
                  }
                  addGig && reviewedGigs.push(gig);
                });
                setUrl(urlName);
                setPopularGigs(reviewedGigs);
                setCategoryHebName(results.categoryHebName);
              }
            )
            .catch((error) => {
              throw new Error(error);
            });
      } catch (error) {
        console.log(error);
      }
    } else if (categories) {
      const rd = Math.floor(Math.random() * categories.length);
      try {
        const urlName = categories[rd].urlName;
        userId &&
          getMostPopularCategoryGigs(urlName)
            .then(
              (results: {
                gigs: Gig[];
                categoryId: string;
                categoryHebName: string;
              }) => {
                let reviewedGigs: Gig[] = [];
                results.gigs.forEach((gig) => {
                  let addGig = false;
                  if (gig.userId._id !== userId) {
                    gig.userId.receivedReviews.forEach((review) => {
                      if (review.gig.toString() === gig._id) {
                        addGig = true;
                      }
                    });
                  }
                  addGig && reviewedGigs.push(gig);
                });
                setUrl(urlName);
                setPopularGigs(reviewedGigs);
                setCategoryHebName(results.categoryHebName);
              }
            )
            .catch((error) => {
              throw new Error(error);
            });
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  }, [categories, userId]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="d-flex justify-content-center">
      {popularGigs?.length ? (
        <div className="col-12">
          <div className="d-flex justify-content-start justify-content-md-center">
            <div className="d-flex align-items-center justify-content-center justify-content-md-start col-12 col-md-11">
              <h1 className="mt-2 text-start">פופולרי ב -</h1>
              <Link
                to={`/gigs/${url}`}
                className="mx-2 fs-1"
                style={{
                  textDecoration: "none",
                  fontWeight: "600",
                  color: "#ff4e5b",
                }}
              >
                {categoryHebName}
              </Link>
            </div>
          </div>
          <div>
            <Carousel
              responsive={responsive}
              rtl={true}
              className="pb-3"
              itemClass="d-flex justify-content-center align-items-center"
            >
              {popularGigs.map((gig) => {
                return (
                  <div className="col-10 col-md-8" key={gig._id}>
                    <GigCard gig={gig} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      ) : (
        <img
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693233713/haltu_assets/Telecommuting-pana-no-bg3_kof8of.svg"
          alt="פרילנסר"
          style={{ maxWidth: "700px" }}
        />
      )}
    </div>
  );
};

export default PopularGigs;
