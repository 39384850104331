import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { Order } from "../interface/order.interface";
import { getSingleOrder } from "../api/order-read";
import BuyerSingleOrder from "../components/BuyerSingleOrder";
import SellerSingleOrder from "../components/SellerSingleOrder";

import { Socket } from "socket.io-client";
import { Spinner } from "../../../components/Spinner";

const SingleOrderPage = function (props: { socket: Socket }) {
  const { socket } = props;
  const { id, orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);
  const { userId } = useAppSelector((state) => state.user);
  const [isBuyer, setIsBuyer] = useState<boolean>(false);

  socket.on("newOrderEvent", (data) => {
    const { buyerId, sellerId } = data;
    if (userId !== buyerId && userId !== sellerId) {
      return;
    } else {
      const timer = setTimeout(() => {
        setReload(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  });

  socket.on("newOrderComment", (data) => {
    const { savedOrder } = data;
    if (savedOrder === order?._id) {
      setReload(true);
    } else return;
  });

  useEffect(() => {
    if (id !== userId) {
      navigate("/");
    } else if (reload) {
      getSingleOrder(id!, orderId!)
        .then((foundOrder: Order) => {
          if (userId === foundOrder.buyerId._id) setIsBuyer(true);
          setOrder(foundOrder);
        })
        .catch((error) => {
          console.log(error);
          navigate("/404");
        })
        .finally(() => {
          setLoading(false);
        });
      setReload(false);
    }
  }, [id, orderId, setOrder, userId, reload, navigate]);

  return (
    <div style={{ marginBottom: "75px" }}>
      {!loading && order ? (
        <div className="container mt-4">
          {isBuyer ? (
            <Fragment>
              <BuyerSingleOrder
                order={order}
                orderId={orderId!}
                userId={userId!}
              />
              {order.status !== "CANCELED" &&
              !order.active &&
              !order.reviewed ? (
                <div className="mt-4 d-flex flex-column align-items-center">
                  <Link
                    to={`/users/${userId}/orders/${order._id}/review`}
                    className="btn btn-primary mt-2 col-12 col-md-4"
                  >
                    השאר/י ביקורת!
                  </Link>
                  <p className="mt-2" style={{ fontSize: "14px" }}>
                    לפתיחת ההזמנה מחדש / תלונות על ההזמנה ניתן לפנות ל
                    support@haltu.co.il
                  </p>
                </div>
              ) : !order.active ? (
                <div className="mt-4 d-flex flex-column align-items-center">
                  <Link
                    to={`/gigs/${order.gigId.category.urlName}/${order.gigId._id}`}
                    className="btn btn-success"
                  >
                    הסתיימה ההזמנה, ניתן לרכוש שוב כאן!
                  </Link>
                  <p className="mt-2" style={{ fontSize: "14px" }}>
                    לפתיחת ההזמנה מחדש / תלונות על ההזמנה ניתן לפנות ל
                    support@haltu.co.il
                  </p>
                </div>
              ) : null}
            </Fragment>
          ) : (
            <SellerSingleOrder
              order={order}
              orderId={orderId!}
              userId={userId!}
            />
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SingleOrderPage;
