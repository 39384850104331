import ContactUs from "../../../components/ContactUs";
import FifthSection from "./Components/FifthSection";
import EighthSection from "./Components/EighthSection";
import FirstSection from "./Components/FirstSection";
import FourthSection from "./Components/FourthSection";
import SecondSection from "./Components/SecondSection";
import SeventhSection from "./Components/SeventhSection";
import SixthSection from "./Components/SixthSection";
import ThirdSection from "./Components/ThirdSection";

const PrivacyPolicy = function () {
  return (
    <div className="mt-5 container">
      <h1>הצהרת פרטיות</h1>
      <p>עדכון אחרון: מאי 2023</p>
      <p>מסמך זה מנוסח בלשון זכר, אך נכון גם לנשים.</p>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <EighthSection />
      <ContactUs />
    </div>
  );
};

export default PrivacyPolicy;
