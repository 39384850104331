import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../store/hooks";

const CreateGig = function () {
  const { userId, verified } = useAppSelector((state) => state.user);
  return (
    <div
      className="mt-5 mt-md-1 d-flex justify-content-center text-light"
      style={{
        height: "400px",
        width: "100%",
        background: "#313131",
      }}
    >
      <div className="col-10 col-md-3 d-flex flex-column justify-content-center text-center">
        <h5
          style={{
            fontWeight: "700",
            marginBottom: "20px",
            fontSize: "32px",
          }}
        >
          מוכנים להתחיל לפרסם בפלטפורמה? <br /> צרו ג'וב חדש עכשיו!
        </h5>
        {verified ? (
          <Link
            to={`/users/${userId}/create-new-gig`}
            className="btn btn-danger p-3"
          >
            יצירה
          </Link>
        ) : (
          <Button variant="danger disabled" className="p-3">
            חשבון לא מאומת
          </Button>
        )}
      </div>
    </div>
  );
};

export default CreateGig;
