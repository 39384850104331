const ContactUs = function () {
  return (
    <div>
      <h5>יצירת קשר</h5>
      <p>
        אם יש לך שאלות או דאגות נוספות עבור מסמך זה, צור עמנו קשר בבקשה ב:
        support@haltu.com
      </p>
      <p>תודה, צוות חלטו.</p>
    </div>
  );
};

export default ContactUs;

// Contact Us If you have any questions or concerns about these Terms, please
// contact us at [Contact Information].
