import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gig } from "../../../features/gigs/interface/gig.interface";
import { getFavorites } from "../../../features/favorites/api/favorites-read";

interface FavoritesInitialState {
  favoriteGigs: Gig[];
  loading: boolean;
  error: null | string;
}

const initialState: FavoritesInitialState = {
  favoriteGigs: [],
  loading: false,
  error: null,
};

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getFavorites.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getFavorites.fulfilled,
      (state, action: PayloadAction<Gig[]>) => {
        state.loading = false;
        state.favoriteGigs = action.payload;
      }
    );
    builder.addCase(
      getFavorites.rejected,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export const favoritesAction = favoritesSlice.actions;

export default favoritesSlice;
