import { Gig } from "../interface/gig.interface";
import {
  sortGigsByDateAsc,
  sortGigsByPriceAsc,
  sortGigsByPopularity,
  sortGigsByPriceDesc,
  sortGigsByDateDesc,
} from "../../../utils/data-sort";

const SortByDropdown = function (props: {
  gigs: Gig[];
  setFunc: any;
  text?: string;
  sortBy: number;
  setSortBy: any;
}) {
  return (
    <div className="d-flex align-items-center col-4 col-lg-2">
      <label htmlFor="SortDropdown" className="text-secondary">
        {props.text}
      </label>
      <select
        id="SortDropdown"
        name="SortDropdown"
        className="mx-1"
        style={{ outline: "none", border: "none" }}
        value={props.sortBy}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const val = e.target.value;
          switch (val) {
            case "1":
              props.setSortBy(1);
              props.setFunc([...sortGigsByPopularity(props.gigs)]);
              break;
            case "2":
              props.setSortBy(2);
              props.setFunc([...sortGigsByDateAsc(props.gigs)]);
              break;
            case "3":
              props.setSortBy(3);
              props.setFunc([...sortGigsByDateDesc(props.gigs)]);
              break;

            case "4":
              props.setSortBy(4);
              props.setFunc([...sortGigsByPriceAsc(props.gigs)]);
              break;
            case "5":
              props.setSortBy(5);
              props.setFunc([...sortGigsByPriceDesc(props.gigs)]);
              break;
            default:
              props.setFunc(props.gigs);
              break;
          }
        }}
      >
        {/* Popularity */}
        <option value={"1"}>פופולריות</option>
        {/* Date Ascending */}
        <option value={"2"}>חלטורות חדשות</option>
        {/* Date Descending */}
        <option value={"3"}>חלטורות ישנות</option>
        {/* Price Ascending */}
        <option value={"4"}>מהזול ליקר</option>
        {/* Price Descending */}
        <option value={"5"}>מהיקר לזול</option>
      </select>
    </div>
  );
};

export default SortByDropdown;
