export const ChoosingServicesSection = () => {
  return (
    <div>
      <h2>בחירת מוצרים ושירותים</h2>
      <h5>כלקוח בפלטפורמה, אתה מסכים:</h5>
      <ul>
        <li>
          לבחור במוצרים ושירותים בהתבסס על תיאור המוצר או שירות, הפרטים והמחירים
          המסופקים על ידי הפרילנסרים בפלטפורמה.
        </li>
        <li>
          לבדוק בקפידה את פרטי המוצרים והשירותים שתרצה לרכוש, כולל כל הזמנים,
          תוצרים ותנאים משויכים. לתקשר את דרישותיך, שאלותיך וציפיותיך במובן ברור
          למוצר או לשירות שנבחר.
        </li>
      </ul>
    </div>
  );
};
