import { useState } from "react";
import FeaturesBubble from "./FeaturesComponents/FeaturesBubble";
import FeaturesButtons from "./FeaturesComponents/FeaturesButtons";

const HaltuFeatures = () => {
  const [feature, setFeature] = useState<number>(2);
  return (
    <div style={{ minHeight: "90vh", maxWidth: "100vw" }}>
      <div
        style={{
          background:
            "url(https://res.cloudinary.com/dm20uwmki/image/upload/v1694536347/haltu_assets/features_background2_c0d1l0.png) no-repeat center",
          paddingBottom: "75px",
          backgroundSize: "1920px auto",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="pt-5 d-flex flex-column align-items-center">
          <h1 className="text-light text-center ">
            הזמינו, תקשרו, ונהלו את ההזמנה בקלות
          </h1>
          <p className="mt-1 text-light text-center">
            חלטו עוזרת לכם למצוא ולנהל פרוייקטים חדשים בקלות ובמהירות, <br />
            מקבלת תמונות לדוגמה ותיאור הזמנה לשליחת קישורים להורדת הפרויקט.
          </p>
          <div className="mt-3 col-12 col-md-4 d-flex justify-content-between px-2 px-md-0">
            <FeaturesButtons
              feature={feature}
              featureNumber={0}
              setFeature={(num: number) => setFeature(num)}
              url="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529068/haltu_assets/research_3041005_edxajr.svg"
              header="מחקר"
              alt="מחקר"
            />
            <FeaturesButtons
              feature={feature}
              featureNumber={1}
              setFeature={(num: number) => setFeature(num)}
              url="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529071/haltu_assets/single_1828228_j1ox0o.svg"
              header="אינטראקציה"
              alt="אינטראקציה"
            />
            <FeaturesButtons
              feature={feature}
              featureNumber={2}
              setFeature={(num: number) => setFeature(num)}
              url="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529073/haltu_assets/workflow_2903359_kpqstk.svg"
              header="זרימה"
              alt="זרימה"
            />
            <FeaturesButtons
              feature={feature}
              featureNumber={3}
              setFeature={(num: number) => setFeature(num)}
              url="https://res.cloudinary.com/dm20uwmki/image/upload/v1694549174/haltu_assets/like_1062675_fpdlwa.svg"
              header="ביקורות"
              alt="לייק"
            />
          </div>
        </div>
        <div className="mt-5 col-11 col-md-8 m-auto">
          <FeaturesBubble featue={feature} />
        </div>
      </div>
    </div>
  );
};

export default HaltuFeatures;
