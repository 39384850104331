interface ButtonProps {
  type: "button" | "submit";
  btnStyle: string;
  name?: string;
  text: any;
  onClick?: (event: any) => void;
}

function Button(props: ButtonProps) {
  return (
    <button
      type={props.type}
      name={props.name}
      className={`btn btn-${props.btnStyle}`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

export default Button;
