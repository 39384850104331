import React from "react";

interface TextAreaProps {
  id: string;
  text?: string;
  value?: string;
  placeholder?: string;
  extraClass?: string;
  rows?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = React.forwardRef((props: TextAreaProps, ref: any) => {
  return (
    <div className="mb-3">
      <label htmlFor={props.id} className="form-label">
        {props.text}
      </label>
      <textarea
        style={{ resize: "none" }}
        className={`form-control ${props.extraClass}`}
        id={props.id}
        name={props.id}
        rows={props.rows || 3}
        placeholder={props.placeholder}
        ref={ref}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
});
export default TextArea;
