import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";
import { getSingleGig } from "../api/gig-read-api";

import { Spinner } from "../../../components/Spinner";
import { Gig } from "../interface/gig.interface";
import GigUser from "../components/SingleGig/GigUser";
import GigPricing from "../components/SingleGig/GigPricing";
import Carousel from "../../../components/Carousel/Carousel";
import ReviewsSection from "../../reviews/components/ReviewsSection";
import { Review } from "../../reviews/interface/review-interface";
import MiniChatBtn from "../../chat/components/MiniChat/MiniChatBtn";
import MiniChatModal from "../../chat/components/MiniChat/MiniChatModal";

import { Socket } from "socket.io-client";
import ImageModal from "../../../components/ImageModal";

const SingleGig = function (props: { socket: Socket }) {
  const [gig, setGig] = useState<Gig>();
  const navigate = useNavigate();
  const { userId, authCheck } = useAppSelector((state) => state.user);
  const [showChatModal, setShowChatModal] = useState<boolean>(false);

  const { categoryId, id } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSeller, setIsSeller] = useState<boolean>(false);

  const [imageModalSrc, setImageModalSrc] = useState<string>("");
  const [showImageModal, setShowImageModal] = useState<boolean>(false);

  const rowsOfText: string[] = gig ? gig.description.split("\n") : [""];

  let gigReviews: Review[] = [];
  if (gig !== undefined) {
    gigReviews = gig.userId.receivedReviews.filter(
      (review) => review.gig === gig._id
    );
  }

  useEffect(() => {
    getSingleGig({ categoryId: categoryId, gigId: id })
      .then((foundGig) => {
        if (foundGig.userId._id === userId) {
          setIsSeller(true);
        } else {
          setIsSeller(false);
        }
        setGig(foundGig);
      })
      .catch((error) => {
        console.log(error);
        const storageViewedGigs = localStorage.getItem("viewedGigs");
        if (!storageViewedGigs) {
          window.location.replace("/404");
        } else {
          let viewedGigs: Gig[] = JSON.parse(storageViewedGigs);
          viewedGigs = viewedGigs.filter((gig) => gig._id !== id);
          if (viewedGigs.length !== 0) {
            localStorage.setItem("viewedGigs", JSON.stringify(viewedGigs));
          } else {
            localStorage.removeItem("viewedGigs");
          }
          window.location.replace("/404");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userId, setGig, id, categoryId]);

  return (
    <div className="container d-flex">
      {!loading ? (
        <>
          {gig && (
            <div className="d-flex flex-column col-12">
              {!showChatModal && authCheck && !isSeller && (
                <MiniChatBtn
                  gigUser={gig.userId}
                  showModal={() => setShowChatModal(true)}
                />
              )}
              {showChatModal && authCheck && !isSeller && (
                <MiniChatModal
                  closeModal={() => setShowChatModal(false)}
                  gigUser={gig.userId}
                  socket={props.socket}
                />
              )}
              <div className="d-flex flex-column col-12">
                <div className="mt-4 ">
                  <div>
                    <Link
                      to={`/gigs/${gig?.category._id}`}
                      className="link-danger gap-3"
                    >
                      {gig?.category.name}
                    </Link>
                    <h1 className="gap-3">{gig?.title}</h1>
                  </div>
                  <div className="d-flex flex-column-reverse flex-md-row justify-content-between border-top border-bottom pb-3 pt-3">
                    <div className="col-12 col-md-3 mt-2 mt-md-0">
                      <GigUser
                        user={gig.userId}
                        isSeller={isSeller}
                        openChat={() => {
                          if (!authCheck) {
                            navigate("/users/login");
                          } else {
                            setShowChatModal(true);
                          }
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-7">
                      <Carousel
                        gigId={gig._id!}
                        images={gig.imagesUrls}
                        imageWidth="400px"
                        imageHeight="50vh"
                        openImageModal={() => setShowImageModal(true)}
                        setModalSrc={(imgSrc: string) =>
                          setImageModalSrc(imgSrc)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <div className="col-12 mt-3 pb-2 d-flex flex-column-reverse flex-md-row justify-content-md-between">
                      <div className="mt-3 mt-md-0 col-12 col-md-6 card">
                        <h5 className="mt-2 border-bottom p-2">על המוצר</h5>
                        <div className="mx-2">
                          {rowsOfText.map((row, index) => {
                            if (row === "") {
                              return null;
                            } else {
                              return <p key={index}>{row}</p>;
                            }
                          })}
                        </div>
                      </div>

                      <div className="col-12 col-md-5">
                        <GigPricing
                          gig={gig}
                          isSeller={isSeller}
                          userId={userId}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <ReviewsSection
                        reviews={gigReviews}
                        header="ביקורות"
                        noReviewsText="לא פורסמו ביקורות על המוצר"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="col-12 d-flex align-items-center justify-content-center p-5">
          <Spinner />
        </div>
      )}
      <ImageModal
        showImageModal={showImageModal}
        closeModal={() => {
          setShowImageModal(false);
          setImageModalSrc("");
        }}
        imgSrc={imageModalSrc}
      />
    </div>
  );
};

export default SingleGig;
