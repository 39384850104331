import { baseUrl } from "../../../API/base-urls";
import { AppDispatch } from "../../../store/store";
import { GigFormOutput } from "../interface/gig-form-output";

const URL = `${baseUrl}/gigs`;

export const createGig = function (
  newGig: GigFormOutput,
  callback: (state: boolean) => void
) {
  return (dispatch: AppDispatch) => {
    const token = localStorage.getItem("token");
    const uploadGig = fetch(URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newGig),
    });
    uploadGig
      .then((results) => {
        if (!results.ok) throw new Error("Something went wrong");
        return results.json();
      })
      .then((gigDetails) => {
        const { savedGig } = gigDetails;
        window.location.replace(`/gigs/${savedGig.category}/${savedGig._id}`);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          callback(false);
        }, 500);
      });
  };
};
