import { Link } from "react-router-dom";

const NotFound = function () {
  return (
    <div className="container text-center">
      <div className="mt-5">
        <h3>אופס!</h3>
        <h5>לא הצלחנו למצוא את מה שחיפשת...</h5>
        <img
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693683600/haltu_assets/7741849_3747372_l1izew.svg"
          alt="..."
          style={{ width: "50%", height: "50%" }}
        />
        <div>
          <Link
            className="btn btn-danger"
            style={{ textDecoration: "none" }}
            to={"/"}
          >
            קחו אותי לעמוד הבית
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
