import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../../../features/user/api/user-login";
import { MembershipType } from "../../../features/user/interface/user.interface";

interface UserInitialState {
  loading: boolean;
  authCheck: boolean;
  token: string | null;
  userId: string | null;
  firstName: string | null;
  verified: boolean;
  isAdmin: boolean;
  membership: MembershipType | null;
  error: undefined | string;
}

const initialState: UserInitialState = {
  loading: false,
  authCheck: false,
  userId: null,
  token: null,
  firstName: null,
  verified: false,
  membership: null,
  isAdmin: false,
  error: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action) {
      const { isAdmin, membership, id, verified, firstName } = action.payload;
      state.isAdmin = isAdmin;
      state.membership = membership;
      state.userId = id;
      state.authCheck = true;
      state.verified = verified;
      state.firstName = firstName;
    },
    logout(state) {
      state.token = null;
      state.userId = null;
      state.isAdmin = false;
      state.verified = false;
      state.membership = null;
      state.firstName = null;
      state.authCheck = false;
      state.error = undefined;
      localStorage.removeItem("token");
    },
    autoLogin(state, action) {
      const token = action.payload;
      state.token = token;
    },
    resetError(state) {
      state.error = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.token;
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        const message = action.payload?.toString();
        state.loading = false;
        state.error = message;
      });
  },
});

export const userActions = userSlice.actions;

export default userSlice;
