import { Button, Spinner } from "react-bootstrap";

interface ProgressButtonsProps {
  progress: number;
  btnText: string;
  onProgress: (event: any) => void;
  loading?: boolean;
}

const ProgressButtons = function (props: ProgressButtonsProps) {
  return (
    <div className="d-flex justify-content-between">
      {props.progress > 0 ? (
        <Button
          name="previous"
          type="button"
          className="col-4 col-md-3"
          variant="secondary"
          onClick={props.onProgress}
        >
          חזור
        </Button>
      ) : (
        <div className="col-3"></div>
      )}
      {props.progress < 100 ? (
        <Button
          name="next"
          type="button"
          variant="primary"
          className="col-4 col-md-3"
          onClick={props.onProgress}
        >
          הבא
        </Button>
      ) : (
        <Button
          type="submit"
          variant={`danger ${props.loading && "disabled"}`}
          className="col-4 col-md-3"
        >
          {props.loading ? (
            <Spinner variant="light" className="spinner-size" />
          ) : (
            `${props.btnText}`
          )}
        </Button>
      )}
    </div>
  );
};

export default ProgressButtons;
