import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

const LoggedOffLinks = function (props: { color: string }) {
  const { color } = props;
  return (
    <div className="d-flex mt-1 mb-1 justify-content-center align-items-center">
      <Nav.Item className="nav-link mx-2">
        <Link
          to="/users/login"
          style={{ textDecoration: "none", fontWeight: "500" }}
          className={color}
        >
          התחבר
        </Link>
      </Nav.Item>
      <Nav.Item className="nav-link mx-2">
        <Link
          to="/users/register"
          style={{
            textDecoration: "none",
            fontWeight: "600",
            fontSize: "20px",
          }}
          className={` link-light btn ${
            props.color === "link-dark" ? "btn-danger" : "btn-dark"
          }`}
        >
          הרשמה
        </Link>
      </Nav.Item>
    </div>
  );
};

export default LoggedOffLinks;
