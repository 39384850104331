import { Socket } from "socket.io-client";
import { Routes, Route, Navigate } from "react-router-dom";

import SingleGig from "../features/gigs/pages/SingleGig";
import GigByCategory from "../features/gigs/pages/GigByCategory";
import NotFound from "../generalPages/NotFound";

import SummaryPage from "../features/orders/pages/Checkout/SummaryPage";
// import CheckOutPage from "../features/orders/pages/Checkout/CheckOutPage";

interface GigRoutesProps {
  authCheck: boolean;
  socket: Socket;
}

const GigRoutes = function (props: GigRoutesProps) {
  return (
    <Routes>
      <Route path=":categoryUrlName/" element={<GigByCategory />} />

      <Route
        path=":categoryUrlName/:id"
        element={<SingleGig socket={props.socket} />}
      />
      <Route
        path=":categoryUrlName/:gigId/summary"
        element={
          props.authCheck ? (
            <SummaryPage />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      />
      {/* Uncomment to get withdraws system */}
      {/* <Route
        path=":categoryId/:gigId/check-out"
        element={
          props.authCheck ? (
            <CheckOutPage />
          ) : (
            <Navigate to={"/users/login"} replace />
          )
        }
      /> */}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default GigRoutes;
