import { useEffect, useState } from "react";
import { Gig } from "../../../../features/gigs/interface/gig.interface";
import { getLatestGigs } from "../../../../features/gigs/api/gig-read-api";

import Carousel from "react-multi-carousel";
import { GigCard } from "../../../../features/gigs/components/GigCard";

interface LSData {
  gigs: Gig[];
  lastUpdated: string;
}

const LatestGigs = () => {
  const [latestGigs, setLatestGigs] = useState<Gig[] | null>(null);

  useEffect(() => {
    const maxLength = 10;
    let storageLatestGigs: Gig[] = [];
    const ls = localStorage.getItem("latestGigs");
    if (ls) {
      try {
        const data: LSData = JSON.parse(ls);
        const lastUpdated = data.lastUpdated;
        if (Date.parse(lastUpdated) <= Date.now()) {
          localStorage.removeItem("latestGigs");
        } else {
          storageLatestGigs = data.gigs;
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (
      storageLatestGigs.length === 0 ||
      storageLatestGigs.length < maxLength
    ) {
      getLatestGigs()
        .then((results: { gigs: Gig[] }) => {
          if (results.gigs.length > 0) {
            const data: LSData = {
              gigs: results.gigs,
              lastUpdated: new Date(Date.now()).toString(),
            };
            localStorage.setItem("latestGigs", JSON.stringify(data));
            setLatestGigs(results.gigs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLatestGigs(storageLatestGigs);
    }
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1920 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1680, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="col-12 d-flex justify-content-center">
      {latestGigs?.length ? (
        <div className="col-12">
          <h1 className="text-center text-light mb-4">
            חדש, <span style={{ color: "#ff4e5b" }}>רק בחלטו:</span>
          </h1>
          <Carousel
            responsive={responsive}
            rtl={true}
            className="pb-3"
            itemClass="d-flex justify-content-center"
          >
            {latestGigs.map((gig) => {
              return (
                <div className="col-10 col-md-8" key={gig._id}>
                  <GigCard gig={gig} />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : null}
    </div>
  );
};

export default LatestGigs;
