interface InputFormikProps {
  id: string;
  type: string;
  text?: string;
  value?: string;
  autoComplete?: string;
  formik: any;
}
const InputFormik = function (props: InputFormikProps) {
  const { errors, touched, handleChange, values, handleBlur } = props.formik;
  return (
    <div className="mb-3">
      <label htmlFor={props.id} className="form-label">
        {props.text}
      </label>
      <input
        autoComplete={props.autoComplete || "off"}
        type={props.type}
        className={`form-control ${
          errors[props.id] && touched[props.id] ? "is-invalid" : ""
        } ${touched[props.id] && !errors[props.id] ? "is-valid" : ""}`}
        id={props.id}
        value={values[props.id]}
        name={props.id}
        aria-describedby={`${props.id}Input`}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors[props.id] && touched[props.id] ? (
        <p className="text-danger">{errors[props.id]}</p>
      ) : null}
    </div>
  );
};

export default InputFormik;
