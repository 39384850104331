import { Link } from "react-router-dom";

interface HomePageProps {
  to?: string;
  brandName?: string;
  color?: string;
}

const HomePage: React.FC<HomePageProps> = function (props) {
  return (
    <div
      style={{ textDecoration: "none", fontFamily: "Comfortaa" }}
      className={`${props.color ? props.color : "link-dark"}`}
    >
      <Link
        to={props.to || "/"}
        style={{
          textDecoration: "none",
          fontSize: "34px",
          fontWeight: "700",
          direction: "ltr",
        }}
        className={`text-start mx-5 ${props.color}`}
      >
        <span style={{ letterSpacing: "-5px" }} className="mx-1">
          <span
            style={{
              color: "#ff4e5b",
              position: "absolute",
            }}
            className="d-inline d-md-none"
          >
            `
          </span>
          <span className="mx-1 mx-md-0">h</span>
          <span
            style={{
              color: `${props.color === "link-dark" ? "#ff4e5b" : "#ffffff"}`,
              position: "absolute",
            }}
            className="d-none d-md-inline"
          >
            `
          </span>
        </span>
        <span style={{ fontSize: "14px" }} className="d-none d-md-inline">
          altu
        </span>
      </Link>
    </div>
  );
};

export default HomePage;
