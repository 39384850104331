export const ClientsUnfairBehaviorSection = () => {
  return (
    <div>
      <h2>התנהגות אתית ואיסור בקשות לא הוגנות</h2>
      <p>
        בתור לקוח המשתמש בשירותים המסופקים דרך פלטפורמת haltu.co.il
        ("הפלטפורמה"), אתה מסכים להתנהג באופן הוגן ואתי בעת האינטראקציה עם
        הפרילנסרים. אתה מכיר ומבין כי ניסיון להשפיע על תנאי העסקה לרווחה האישי,
        כולל דרישה להטבות נוספות, השהייה בלתי הוגנת של פרויקטים או ניצול של
        הפרילנסרים, אסור בצורה מחמירה.
      </p>
      <h5>אתה מתחייב לא לעשות את הדברים הבאים:</h5>
      <ul>
        <li>לדרוש באופן לא הוגן עבודה חינם או בקשת הוגנות יתר מהמחיר שנקבע.</li>
        <li>
          לנסות למשוך מחירים נמוכים יותר לאחר התחלת הפרויקט ללא סיבות תקינות.
        </li>
        <li> להשהות או למנוע תשלום עבור עבודה שהושלמה או להסתיר אי-תשלום.</li>
        <li>
          להוביל את הפרילנסרים לספק שירותים נוספים בלתי הוגנים ללא תשלום ראוי.
        </li>
        <li>לכפות על הפרילנסרים לשנות פרטי הפרויקט, מחירים או תנאי משלוח.</li>
      </ul>
      <h5>
        התנהגות כזו היא נגד עקרונות ההגינות והשקיפות שנמצאים בבסיס שיתוף פעולה
        מוצלח של הפלטפורמה. חלטו מחויבת לשמור על סביבה מכובדת ותומכת לשני
        הצדדים, הלקוחות והפרילנסרים כאחד. במקרה שבו יתברר כי לקוח הפר בסעיף זה,
        חלטו שומרת לעצמה את הזכות לבצע פעולות מתאימות, כולל השעיה או הסרת
        החשבון. שימור תקנות אתיקה ושותפות חשוב לצורך הקידום של קהילה חיובית
        לפרילנסרים וללקוחות כאחד. על ידי הסכמתך לתנאי הסעיף הזה בהסכם הלקוח, אתה
        מכיר כי התנהגותך עם הפרילנסרים תתבצע בדרך שתכבד את מיומנותם, את זמנם ואת
        מאמציהם, ואתה מבין כי הפרה של סעיף זה עשויה לגרום לך לתוצאות לא מועילות
        לסוף של הפרויקט שלך, מעמד החשבון שלך ושיתופים עתידיים.
      </h5>
    </div>
  );
};
