export const DisclaimerOfLiabilitySection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>כתב ויתור</h2>
      <p style={{ fontWeight: "700", textDecoration: "underline" }}>
        השימוש ב-haltu.co.il ("האתר") הוא על אחריותך האישית בלבד. חלטו לא תהיה
        אחראית לכל נזק ישיר, עקיף, תוספי, תוצאתי או מיוחד שעשויים להתרחש בשימושך
        באתר, כולל אך לא מוגבל לנזק פיזי או טכנולוגי, אובדן נתונים, או הפסקת
        שירותים. בכך שאתה נכנס ומשתמש באתר, אתה מכיר ומסכים כי חלטו מספקת את
        האתר על פי "AS IS" וחלטו איננה מספקת כל אחריות, בין אם מפורשת או משתמעת,
        כולל אך לא מוגבל לאחריות לדיוק, האמינות, השלמות, ההתאמה, או הזמינות של
        האתר. חלטו מסירה כל אחריות, בין אם מפורשת או משתמעת, כוללת אך לא מוגבלת
        לאחריות, לסחריות, לכשירות מסוימת ו-או לשמירה על אי הפרת זכויות קניין
        רוחני. אתה מסכים להשתמש באתר בזהירות ולהיעזר בביקורת עצמית כדי להגן על
        עצמך ועל מכשיריך. חלטו איננה מתחייבת לביטחון, זמינות או כל פעילות של
        האתר ולא תהיה אחראית לאובדן או נזק שיתרחשו כתוצאה משימוש באתר.
      </p>
    </div>
  );
};
