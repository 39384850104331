import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

function SearchBar(props: { btnColor?: string; closeCanvas?: () => void }) {
  const navigate = useNavigate();
  // const { btnColor } = props;
  const { closeCanvas } = props;
  const [query, setQuery] = useState<string>("");
  const [errState, setErrState] = useState<boolean>(false);

  const handleSearchSubmit = function (e: React.FormEvent) {
    e.preventDefault();
    if (!query) {
      setErrState(true);
      return;
    }
    closeCanvas && closeCanvas();
    navigate(`/search?query=${query}`);
  };

  const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setErrState(false);
    setQuery(e.target.value);
  };
  return (
    <>
      <form
        className={`d-flex justify-content-between p-0 form-control ${
          errState ? "is-invalid" : null
        }`}
        style={{ borderRadius: "22px" }}
        role="search"
        onSubmit={handleSearchSubmit}
      >
        <input
          className="mx-1"
          type="search"
          placeholder="חיפוש..."
          aria-label="Search"
          onChange={handleChange}
          value={query}
          style={{
            width: "90%",
            border: "none",
            outline: "none",
            borderRadius: "22px",
          }}
        />
        <Button
          type="submit"
          variant={`dark ${!query && "disabled"}`}
          size="sm"
          className="text-center"
          style={{ borderRadius: "22px", margin: "2px", marginLeft: "3px" }}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ fontSize: "14px" }}
          />
        </Button>
      </form>
    </>
  );
}

export default SearchBar;
