import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { userActions } from "../../store/slices/user/user-slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getNotifications } from "../../features/chat/api/chat-notifications-read";

import CategoriesNav from "./CategoriesNav";
import SearchBar from "./NavbarComp/SearchBar/SearchBar";
import HomePage from "./NavbarComp/HomePage";
import { Navbar, Nav, Offcanvas } from "react-bootstrap";

import LoggedInLinks from "./NavbarComp/LoggedInLinks";
import LoggedOffLinks from "./NavbarComp/LoggedOffLinks";
import { Socket } from "socket.io-client";
import OffcanvasCategories from "./NavbarComp/OffcanvasCategories";
import { NotificationsBadge } from "../../features/chat/components/NotificationsBadge";

const HaltuNavbar = function (props: { socket: Socket }) {
  const { socket } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [reload, setReload] = useState<boolean>(true);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [frontPage, setFrontPage] = useState<boolean>(false);
  const { categories } = useAppSelector((state) => state.categories);
  const { authCheck, userId } = useAppSelector((state) => state.user);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [ordersNotifications, setOrdersNotifications] = useState<number>(0);
  const [messageNotifications, setMessageNotifications] = useState<number>(0);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);

  window.addEventListener("resize", function (event) {
    if (this.window.innerWidth < 725) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setShowCanvas(false);
    }
  });

  const handleHide = () => setShowCanvas(false);
  const handleShow = () => setShowCanvas(true);

  window.addEventListener("scroll", function (event) {
    setScrollPos(this.scrollY);
  });

  const logoutHandler = function () {
    dispatch(userActions.logout());
    socket.emit("removeUser", userId);
    window.location.replace("/");
  };

  socket.on("newOrderEvent", (data) => {
    const { buyerId, sellerId } = data;
    if (userId !== buyerId && userId !== sellerId) {
      return;
    } else {
      const timer = setTimeout(() => {
        setReload(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  });

  // Here he got the notification
  socket.on("newMessageEvent", (data) => {
    const { recieverId } = data;
    if (userId !== recieverId) {
      return;
    } else {
      const timer = setTimeout(() => {
        setReload(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  });

  // Here he read the notification
  socket.on("newNotificationEvent", (data) => {
    const { id } = data;
    if (userId?.toString() !== id.toString()) {
      return;
    } else {
      setReload(true);
    }
  });

  useEffect(() => {
    if (location.pathname !== "/") {
      setFrontPage(false);
    } else {
      setFrontPage(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (reload && authCheck) {
      getNotifications()
        .then((results) => {
          setOrdersNotifications(results.ordersNotifications);
          setMessageNotifications(results.messageNotifications);
          setTotalNotifications(
            results.messageNotifications + results.ordersNotifications
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReload(false);
        });
    }
  }, [reload, authCheck]);

  return (
    <>
      <Navbar
        expand="md"
        className={`d-flex justify-content-between align-item-center ${
          !authCheck && frontPage && "fixed-top"
        }`}
        style={{
          transition: "300ms",
          background: `${
            !authCheck && frontPage && scrollPos < 100 ? "none" : "#ffffff"
          }`,
        }}
      >
        <Navbar.Brand className="col-1 col-md-4 m-0">
          <HomePage
            brandName="Haltu"
            color={
              !authCheck && frontPage && scrollPos < 100
                ? "link-light"
                : "link-dark"
            }
          />
        </Navbar.Brand>
        <div className="d-none d-md-block col-md-3">
          {!authCheck && scrollPos < 100 && frontPage ? null : <SearchBar />}
        </div>
        {authCheck ? (
          <div className="col-md-5 col-lg-4 d-flex justify-content-end">
            <Navbar.Toggle
              aria-controls="offcanvasNavbarLabel-expand-md"
              className="mx-3 mx-md-0"
              onClick={handleShow}
            />
            {!showCanvas &&
            isMobile &&
            totalNotifications &&
            totalNotifications > 0 ? (
              <NotificationsBadge
                notifications={totalNotifications}
                top={"10px"}
                left={"5px"}
                width="25px"
                fontSize="14px"
                height="25px"
              />
            ) : null}
            <Navbar.Offcanvas
              id="offcanvasNavbarLabel-expand-md"
              aria-labelledby="offcanvasNavbarLabel-expand-md"
              placement="end"
              className="d-md-none"
              show={showCanvas}
              onHide={handleHide}
            >
              {/* <Offcanvas show={}> */}
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
                  תפריט
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="d-block d-md-flex justify-content-end">
                <Nav>
                  <div className="d-block d-md-none col-md-3">
                    <SearchBar closeCanvas={handleHide} />
                  </div>
                  {categories && (
                    <div className="d-block d-md-none mx-2 mt-3 mb-0">
                      <OffcanvasCategories
                        categories={categories}
                        closeCanvas={handleHide}
                      />
                    </div>
                  )}
                  <LoggedInLinks
                    closeCanvas={handleHide}
                    logoutHandler={logoutHandler}
                    userId={userId}
                    messageNotifications={messageNotifications}
                    ordersNotifications={ordersNotifications}
                    color={"link-dark"}
                  />
                </Nav>
              </Offcanvas.Body>
              {/* </Offcanvas> */}
            </Navbar.Offcanvas>
            <div className="d-none d-md-block">
              <LoggedInLinks
                logoutHandler={logoutHandler}
                userId={userId}
                messageNotifications={messageNotifications}
                ordersNotifications={ordersNotifications}
                color={"link-dark"}
              />
            </div>
          </div>
        ) : (
          <Nav className="mx-1 col-6 col-md-4 d-flex justify-content-end">
            <LoggedOffLinks
              color={
                !authCheck && frontPage && scrollPos < 100
                  ? "link-light"
                  : "link-dark"
              }
            />
          </Nav>
        )}
      </Navbar>
      {categories && (
        <CategoriesNav
          categories={categories}
          scrollPos={scrollPos}
          authCheck={authCheck}
          frontPage={frontPage}
        />
      )}
    </>
  );
};

export default HaltuNavbar;
