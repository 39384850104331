import { createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../../../features/categories/api/categories-read";
import { PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../../../features/categories/interface/category.interface";
import { CategoryPayload } from "../../../features/categories/interface/category-payload.interface";

interface CategoryInitialState {
  categories: null | Category[];
  loading: boolean;
  error: null | string;
}

const initialState: CategoryInitialState = {
  categories: null,
  loading: false,
  error: null,
};

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCategories.fulfilled,
        (state, action: PayloadAction<CategoryPayload>) => {
          state.loading = false;
          state.categories = action.payload.categories;
        }
      )
      .addCase(getCategories.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default categorySlice;
