import { baseUrl } from "../../../API/base-urls";
import { createAsyncThunk } from "@reduxjs/toolkit";

const URL = `${baseUrl}/users/favorites`;
export const getFavorites = createAsyncThunk(
  "favorites/getFavorites",
  (data, thunkApi) => {
    const token = localStorage.getItem("token");
    return fetch(`${URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Something went wrong");
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        console.log(error);
        return thunkApi.rejectWithValue(error.message);
      });
  }
);
