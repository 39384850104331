export const SelfEmployedSection = () => {
  return (
    <div>
      <h2>קבלן עצמאי</h2>
      <p>
        הסכם זה מציין מערך של קבלן עצמאי בינך לבין חלטו. אתה אינך עובד, סוכן, או
        נציג של חלטו. כקבלן עצמאי, אתה אחראי בהתחייבויות המס, בביטוח ובכל
        הדרישות המשפטיות האחרות שעשויות להיות רלוונטיות בעקבות שימוש בפלטפורמה.
      </p>
    </div>
  );
};
