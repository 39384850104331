import { useAppSelector } from "./store/hooks";
import { Routes, Route } from "react-router-dom";
import "./App.css";
//--------Custom Hooks--------
import { useSocket } from "./hooks/ClientHooks/useSocket";
import { useAutoLog } from "./hooks/ClientHooks/useAutoLog";
import { useLocalStorage } from "./hooks/ClientHooks/useLocalStorage";
import { useGetFavorites } from "./hooks/DataHooks/useGetFavorites";
import { useGetCategories } from "./hooks/DataHooks/useGetCategories";
//--------Components----------
import Footer from "./components/Footer/Footer";
import { Spinner } from "./components/Spinner";
import HaltuNavbar from "./components/Navbar/HaltuNavbar";
//--------Gig Routes-----------
import MainFeed from "./generalPages/MainFeed/MainFeed";
import GigRoutes from "./routes/GigRoutes";
//--------User Routes-----------
import UserRoutes from "./routes/UserRoutes";
//--------General Routes-----------
import NotFound from "./generalPages/NotFound";
import ServerErrorPage from "./generalPages/ServerError";
import PlansRoutes from "./routes/PlansRoutes";
import AboutUsRoutes from "./routes/AboutUsRoutes";
import SearchResultsPage from "./generalPages/SearchResultsPage";

import * as socketIO from "socket.io-client";
import { baseUrl } from "./API/base-urls";

const socket = socketIO.connect(baseUrl!, { transports: ["websocket"] });

function App() {
  // Logging in user automaticlly.
  useAutoLog();
  // Validating JWT and UserId Integrity with the server.
  useLocalStorage();
  // Gets the gigs categories
  useGetCategories();
  // User favorites
  useGetFavorites();
  // Socket connection
  useSocket(socket);

  const { authCheck } = useAppSelector((state) => state.user);
  const { categories, loading, error } = useAppSelector(
    (state) => state.categories
  );

  if (loading) {
    return (
      <div>
        {/* <HaltuNavbar socket={socket} /> */}
        <div>
          <Spinner />
        </div>
      </div>
    );
  }

  if (categories) {
    return (
      <div className="d-flex flex-column">
        {!error && <HaltuNavbar socket={socket} />}
        <div>
          <Routes>
            <Route path="/" element={<MainFeed categories={categories} />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route
              path="/gigs/*"
              element={<GigRoutes authCheck={authCheck} socket={socket} />}
            />
            <Route
              path="/users/*"
              element={
                <UserRoutes
                  categories={categories}
                  authCheck={authCheck}
                  socket={socket}
                />
              }
            />
            <Route
              path="/plans/*"
              element={<PlansRoutes authCheck={authCheck} />}
            />
            <Route path="/about-us/*" element={<AboutUsRoutes />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
        {!error && <Footer categories={categories} />}
      </div>
    );
  } else {
    return <div>{error ? <ServerErrorPage /> : null}</div>;
  }
}

export default App;
