const FifthSection = function () {
  return (
    <div>
      <h5>אבטחת נתונים</h5>
      <p>
        אנו נוקטים באמצעים סבירים כדי להגן על המידע האישי שלך מפני גישה או חשיפה
        בלתי מורשית. עם זאת, לא ניתן להבטיח שהעברת נתונים דרך האינטרנט תהיה
        מאובטחת לחלוטין, כך שאיננו יכולים להבטיח את אבטחת המידע האישי שלך.
      </p>
    </div>
  );
};

export default FifthSection;
