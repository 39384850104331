import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { verifyTokenValidity } from "../../api/verification/verification-token";
import { verifyUser } from "../../api/verification/verification-post";

const VerificationPage = function () {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    verifyTokenValidity(params.token!)
      .then((results) => {
        if (!results.ok) {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params.token, navigate]);

  const verificationHandler = function () {
    setLoading(true);
    verifyUser(params.token!).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  };

  return (
    <div
      className="container d-flex justify-content-around  mt-5"
      style={{ minHeight: "60vh" }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center mx-0 mx-md-5">
        <h3>ברוכים הבאים!</h3>
        <h5 className="mt-3 text-center">
          לפני שמתחילים, חייבים לאמת קודם את חשבונך.
        </h5>
        <Button
          type="button"
          variant={`danger ${loading ? "disabled" : " "}`}
          className="mt-3 col-12 col-lg-8"
          onClick={verificationHandler}
        >
          {loading ? <Spinner size="sm" /> : "אימות החשבון!"}
        </Button>
      </div>
      <img
        className="d-none d-md-block w-50"
        src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693239153/haltu_assets/Hello-pana-no-bg_yrkhmj.svg"
        alt="אימות חשבון"
      />
    </div>
  );
};

export default VerificationPage;
