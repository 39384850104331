import { useEffect } from "react";
import { useAppSelector } from "../../../store/hooks";
import { Membership } from "../../user/interface/user.interface";
import { subscribeToPremium } from "../api/subscription-premium";
import PremiumPaymentCard from "../components/Payments/PremiumPaymentCard";

const PremiumTierPayment = function () {
  const premiumPlan = Membership.premium;
  const { membership, userId } = useAppSelector((state) => state.user);

  const approveHandler = function (subscriptionID: string) {
    return subscribeToPremium(subscriptionID, userId);
  };

  useEffect(() => {
    if (membership?.name === premiumPlan.name) {
      window.location.replace("/");
    }
  }, [premiumPlan.name, membership?.name]);

  return (
    <div className="mt-5 container p-3 p-md-5" style={{ minHeight: "100vh" }}>
      <div className="mt-5 d-flex justify-content-center">
        <PremiumPaymentCard approveHandler={approveHandler} />
      </div>
    </div>
  );
};

export default PremiumTierPayment;

// {/* <div id="paypal-button-container-P-43W634203L009821YMS2ZCDY"></div>
// <script src="https://www.paypal.com/sdk/js?client-id=AcYjVwu8fuISg8U-0PYLE0qt5E-XeXuXGqtBztT4HqJVkBu4F2IGZX3gUAK96pJFlrAPm1r4nst9zehl&vault=true&intent=subscription" data-sdk-integration-source="button-factory"></script>
// <script>
//   paypal.Buttons({
//       style: {
//           shape: 'rect',
//           color: 'black',
//           layout: 'vertical',
//           label: 'subscribe'
//       },
//       createSubscription: function(data, actions) {
//         return actions.subscription.create({
//           /* Creates the subscription */
//           plan_id: 'P-43W634203L009821YMS2ZCDY'
//         });
//       },
//       onApprove: function(data, actions) {
//         alert(data.subscriptionID); // You can add optional success message for the subscriber here
//       }
//   }).render('#paypal-button-container-P-43W634203L009821YMS2ZCDY'); // Renders the PayPal button
// </script> */}
