export const ClientsIntellectualPropertySection = () => {
  return (
    <div>
      <h2>זכויות קניין</h2>
      <p>
        לאחר קבלת העבודה שנמסרה על ידי הפרילנסר, אתה מרוויח זכויות קניין רוחני
        מלאות לעבודה הנמסרת. זה מאפשר לך להשתמש, לשנות, להפיץ ולממש את זכויות
        הקניין שלך כפי שתרצה. אתה מכיר כי כל עבודה או תוכן שנמסרו על ידי
        הפרילנסרים ומועלים לפלטפורמה, חלטו מקבלת זכויות קניין מלאות על עבודות
        אלו.
      </p>
    </div>
  );
};
