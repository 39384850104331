import { useState, useEffect } from "react";
import { Order } from "../interface/order.interface";

import { Button } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RevisionModal from "./Modals/RevisionModal";

const Revisions = function (props: { order: Order; userId: string | null }) {
  const { order, userId } = props;
  const [isBuyer, setIsBuyer] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    setIsBuyer(false);
    if (userId && userId === order.buyerId._id) {
      setIsBuyer(true);
    }
  }, [userId, order.buyerId._id]);
  return (
    <div className="border-bottom pb-3">
      <h4 className="mt-3">תיקונים:</h4>
      <h6>כמה נותרו: {order.revisions}</h6>
      {order.revisionDescription && (
        <div>
          <h5>דגשים לתיקון:</h5>
          <p>{order.revisionDescription}</p>
        </div>
      )}
      {isBuyer && order.status === "AWAITING" && order.revisions > 0 ? (
        <Button variant="danger" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> הוספת בקשה לתיקון
        </Button>
      ) : null}
      <RevisionModal
        open={showModal}
        onClose={() => setShowModal(false)}
        orderId={order._id!}
        userId={userId}
      />
    </div>
  );
};

export default Revisions;
