export const FreelancerReviewsSection = () => {
  return (
    <div>
      <h2>ביקורות ומשוב</h2>
      <p>
        כפרילנסר בפלטפורמה של haltu.co.il ("הפלטפורמה"), אתה מכיר בחשיבותם של
        ביקורות לקוחות ובתפקידן בבניית המוניטין שלך בקהילה. ביקורות הלקוחות
        מספקות תובנות יקרות ללקוחות פוטנציאליים ותורם לאמון כללי לשקיפות
        הפלטפורמה.
      </p>
      <ul>
        <li>
          שביעות רצון של הלקוח: הביקורות בפלטפורמה הן השקפה על שביעות רצון הלקוח
          עם מוצרים ושירותים. ללקוחות יש הזכות לספק משוב כנה בהתבסס על חווייתם.
        </li>
        <li>
          שינוי ביקורות: haltu.co.il מחויבת לשמור על תקינות הביקורות המסופקות על
          ידי לקוחות. לכן, הביקורות שמספקים הלקוחות לא ישונו ולא יוסרו על ידי
          החברה, גם אם יתכנו שישפיעו על המוניטין שלך.
        </li>
      </ul>
    </div>
  );
};
