import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../store/hooks";

import ReactPaginate from "react-paginate";
import { getCategoryGigs } from "../api/gig-read-api";

import { Gig } from "../interface/gig.interface";
import { GigCard } from "../components/GigCard";
import { Spinner } from "../../../components/Spinner";

import SortByDropdown from "../components/SortByDropdown";

import {
  sortGigsByPopularity,
  sortGigsByDateAsc,
  sortGigsByDateDesc,
  sortGigsByPriceAsc,
  sortGigsByPriceDesc,
} from "../../../utils/data-sort";

import NoFoundGigs from "../components/NoFoundGigs";
// import PagesButtons from "../components/PagesButtons";

let previousCategory: string;

const GigByCategory = function () {
  const params = useParams();
  const { categoryUrlName } = params;
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryGigs, setCategoryGigs] = useState<Gig[]>([]);
  const { categories } = useAppSelector((state) => state.categories);
  const itemsPerPage = 48;
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<Gig[]>([]);

  const categoryIndex = categories?.findIndex(
    (category) => category.urlName === categoryUrlName
  );

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(categoryGigs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(categoryGigs.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, categoryGigs]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % categoryGigs.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const maxLength = 3;
    let storageCateogries: string[] = [];
    const ls = localStorage.getItem("browsedCategories");
    if (ls) {
      storageCateogries = JSON.parse(ls);
      if (storageCateogries.indexOf(categoryUrlName!) === -1) {
        if (storageCateogries.length >= maxLength) {
          storageCateogries.pop();
          storageCateogries.unshift(categoryUrlName!);
          localStorage.setItem(
            "browsedCategories",
            JSON.stringify(storageCateogries)
          );
        } else {
          storageCateogries.push(categoryUrlName!);
          localStorage.setItem(
            "browsedCategories",
            JSON.stringify(storageCateogries)
          );
        }
      } else {
        return;
      }
    } else {
      const browsedCategories = [categoryUrlName];
      localStorage.setItem(
        "browsedCategories",
        JSON.stringify(browsedCategories)
      );
    }
  });

  useEffect(() => {
    setLoading(true);
    getCategoryGigs(categoryUrlName!)
      .then((results) => {
        if (previousCategory !== categoryUrlName) {
          setPage(1);
        }
        if (!results.message) {
          previousCategory = categoryUrlName!;
          const gigs = results.gigs || [];
          setCategoryGigs((prev) => {
            switch (sortBy) {
              case 1:
                return [...sortGigsByPopularity(gigs)];
              case 2:
                return [...sortGigsByDateAsc(gigs)];
              case 3:
                return [...sortGigsByDateDesc(gigs)];
              case 4:
                return [...sortGigsByPriceAsc(gigs)];
              case 5:
                return [...sortGigsByPriceDesc(gigs)];
              default:
                return [...sortGigsByPopularity(gigs)];
            }
          });
        } else {
          throw new Error(results.message);
        }
      })
      .catch((error: Error) => {
        console.log(error);
        window.location.replace("/404");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [categoryUrlName, page, sortBy]);
  return (
    <div className={!loading ? "mx-3" : ""}>
      {!loading ? (
        <div className="row">
          {categoryGigs.length ? (
            <>
              <div className="mt-3">
                <h1>{categories?.length && categories[categoryIndex!].name}</h1>
                <p style={{ fontSize: "18px" }} className="d-none d-md-block">
                  {categories?.length && categories[categoryIndex!].description
                    ? categories[categoryIndex!].description
                    : null}
                </p>
              </div>
              <div className="p-3 border-bottom">
                <SortByDropdown
                  gigs={categoryGigs}
                  setFunc={setCategoryGigs}
                  text="מיין לפי:"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </div>
            </>
          ) : null}
          <div className="row" style={{ minHeight: "700px" }}>
            {categoryGigs.length ? (
              currentItems.map((gig) => {
                if (gig.userId === null) return;
                return (
                  <div
                    className="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-2 mx-2 mx-md-0"
                    key={gig._id}
                  >
                    <GigCard key={gig._id} gig={gig} />
                  </div>
                );
              })
            ) : (
              <NoFoundGigs />
            )}
          </div>
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="page-item btn btn-light"
              previousLinkClassName="page-item btn btn-light"
              nextLinkClassName="page-item btn btn-light"
              activeLinkClassName="active"
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default GigByCategory;
