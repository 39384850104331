import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { Review } from "../interface/review-interface";
import { Gig } from "../../gigs/interface/gig.interface";

import UserImage from "../../user/components/Image/UserImage";

const ReviewCard = function (props: { review: Review }) {
  const { review } = props;
  const { from } = review;
  const creationDate = new Date(Date.parse(review.createdAt));
  const gig: Gig = review.gig as Gig;

  const rowsOfText: string[] = review.reviewText.split("\n");

  return (
    <div className="m-3 border-bottom">
      <div className="d-flex">
        <UserImage fontSize="15px" canEdit={false} user={from} size={40} />
        <div className="mx-2">
          <h6 className="mx-1">
            {from.firstName} {from.lastName}
          </h6>
          <span className="mx-1 text-secondary">
            {`${creationDate.getDate()}/${
              creationDate.getMonth() + 1
            }/${creationDate.getFullYear()}`}
          </span>
          <p>
            {[...Array(review.rating)].map((child, index) => {
              return <FontAwesomeIcon key={index} icon={faStar} />;
            })}
            <span className="mx-1 fw-semibold">{review.rating}</span>
          </p>
        </div>
      </div>
      <div className="mx-5">
        {rowsOfText.map((row, index) => {
          if (row === "") {
            return null;
          } else {
            return <p key={index}>{row}</p>;
          }
        })}
      </div>
      {gig.category ? (
        <div className="mx-5 mb-3">
          <span className="fw-semibold">קטגוריה:</span>
          <Link to={`/gigs/${gig.category._id}`} className="link-dark mx-1">
            {gig.category.name}
          </Link>
          ,
          <Link
            to={`/gigs/${gig.category._id}/${gig._id}`}
            className="link-dark mx-1"
          >
            ג'וב
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default ReviewCard;
