import { Link } from "react-router-dom";

const FeaturesBubble = (props: { featue: number }) => {
  let alt: string = "";
  let text: string = "";
  let header: string = "";
  let imgUrl: string = "";
  const { featue } = props;
  switch (featue) {
    case 0: {
      alt = "מחקר";
      header = "פרסם וחקור את שירות הפרילנסינג המתאים לך";
      text =
        "עם מגוון שירותים ומגוון נותני שירות תוכלו תמיד ללמוד מפרילנסרים מתקדמים בשוק הישראלי ולרכוש מהם שירותים עבור הפרויקטים שלכם.";
      imgUrl =
        "https://res.cloudinary.com/dm20uwmki/image/upload/v1693233788/haltu_assets/Job_hunt-pana-no-bg2_thsa9u.svg";
      break;
    }
    case 1: {
      alt = "אינטראקציות";
      header = "צרו אינטראקציות באתר עם מגוון רחב של משתמשים";
      text =
        "תקשרו בקלות באמצעות הצ'אט בפלטפורמה עם קליינטים חדשים או פרילנסרים אשר מפרסמים שירותים שהם מציעים למכירה באתר.";
      imgUrl =
        "https://res.cloudinary.com/dm20uwmki/image/upload/v1694553230/haltu_assets/Group_Chat-pana-no-bg_fe2df2.svg";
      break;
    }
    case 2: {
      alt = "ניהול הזמנות";
      header = "נהלו את תהליך ההזמנה שלכם בקלות";
      text =
        "קבלו עדכונים במייל עבור רכישות חדשות, אישורי הזמנה, תיקונים עבור ההזמנה ועוד! כך ניתן לעקוב בקלות אחרי סטטוס ההזמנה שלכם.";
      imgUrl =
        "https://res.cloudinary.com/dm20uwmki/image/upload/v1694553488/haltu_assets/Business_Plan-pana-no-bg_ahajq7.svg";
      break;
    }
    case 3: {
      alt = "ביקורות";
      header = "תנו חוות דעת על שירותים אשר קיבלתם";
      text =
        "כתבו או קבלו חוות דעת על שירותים אשר ניתנו דרך האתר ובכך תחזקו את חזות הפרופיל שלכם בפלטפורמה.";
      imgUrl =
        "https://res.cloudinary.com/dm20uwmki/image/upload/v1694553428/haltu_assets/Working_remotely-pana-no-bg_v5uaed.svg";

      break;
    }
    default: {
      header = "פרסם וחקור את שירות הפרילנסינג המתאים לך";
      text =
        "עם מגוון שירותים ומגוון נותני שירות תוכלו תמיד ללמוד מפרילנסרים מתקדמים בשוק הישראלי ולרכוש מהם שירותים עבור הפרויקטים שלכם.";
      imgUrl =
        "https://res.cloudinary.com/dm20uwmki/image/upload/v1694529071/haltu_assets/single_1828228_j1ox0o.svg";
      break;
    }
  }

  return (
    <div
      className="p-0 p-md-5 d-flex flex-column-reverse flex-md-row"
      style={{
        background: "#ffffff",
        borderRadius: "18px",
        width: "100%",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.3)",
      }}
    >
      <div className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start mt-5 mb-md-0">
        <h1 className="col-10 text-center text-md-start">{header}</h1>
        <p className="col-10 text-center text-md-start">{text}</p>
        <Link
          to={"/users/register"}
          className="mt-3 mb-3 mb-md-0 mt-md-5 col-6 btn btn-danger "
        >
          התחילו עכשיו בחינם
        </Link>
      </div>
      <div
        className="col-12 col-md-6 d-flex justify-content-center"
        style={{
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.3)",
          borderRadius: "18px",
        }}
      >
        <img
          style={{ width: "100%", maxWidth: "500px", borderRadius: "18px" }}
          src={imgUrl}
          alt={alt}
        />
      </div>
    </div>
  );
};

export default FeaturesBubble;
