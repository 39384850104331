interface CheckboxFormikProps {
  id: string;
  text?: string;
  value?: boolean;
  formik: any;
}

const CheckboxFormik = function (props: CheckboxFormikProps) {
  const { errors, values, touched, handleBlur, handleChange } = props.formik;
  return (
    <div className="mb-3 form-check">
      <label className="form-check-label" htmlFor="exampleCheck1">
        {props.text}
      </label>
      <input
        type="checkbox"
        className={`form-check-input
      ${errors[props.id] && touched[props.id] ? "is-invalid" : ""}`}
        id={props.id}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[props.id]}
      />
    </div>
  );
};

export default CheckboxFormik;
