export const DenailOfServiceSection = () => {
  return (
    <div>
      <h2>ניצול השירות</h2>
      <p>
        כפרילנסר בפלטפורמה של haltu.co.il ("הפלטפורמה"), אתה מסכים שלא לנהוג בכל
        התנהגות שכוללת סירוב או השהיית מתן השירותים שלך ללקוחות במטרה לקבל
        תשלומים נוספים, לשיפור ביקורות, או לכל רווח אישי. התנהגות כזו כוללת אך
        אינה מוגבלת למהלך הבא:
      </p>
      <ul>
        <li>הארכת מועד הפרויקט מתוך רצון מכוון.</li>
        <li>
          אי אספקת עבודה שהושלמה ושמירתה כדי לדרוש תשלום גבוה יותר ממה שהוצג
          בעמוד המוצר / השירות.
        </li>
        <li>
          דרישת תשלומים נוספים מעבר למחיר המוצג העמוד השירות או המוצר במטרה לספק
          את השירותים בזמן.
        </li>
        <li>פגיעה באיכות או זמינות העבודה כדי לקבל ביקורת טובה מהלקוח.</li>
        <li>חשיבה אגרסיבית או טקטיקות משא ומתן לא הוגנות.</li>
      </ul>
      <p>
        התנהגות כזו אסורה ומהווה הפרת הסכם פרילנסר זה. חלטו שומרת לעצמה את הזכות
        לפעולה מיידית, כולל סגירה או השהיית חשבונך כמשתמש, כתגובה לכל הפרת הסעיף
        הזה.
      </p>
    </div>
  );
};
