import { Card } from "react-bootstrap";
import { Socket } from "socket.io-client";
import { useState, useEffect, useRef } from "react";
import MessagePill from "../MessagesComp/MessagePill";
import { useAppSelector } from "../../../../store/hooks";
import MiniChatForm from "./ChatModalComps/MiniChatForm";
import { getConversation, getUserMessages } from "../../api/user-chat-read";
import MiniChatHeader from "./ChatModalComps/MiniChatHeader";
import { User } from "../../../user/interface/user.interface";
import { Message } from "../../interface/Message.interface";
import { Conversation } from "../../interface/Conversation.interface";

const MiniChatModal = function (props: {
  gigUser: User;
  closeModal: () => void;
  socket: Socket;
}) {
  const { gigUser, closeModal, socket } = props;
  const [initialReload, setInitialReload] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const { userId } = useAppSelector((state) => state.user);

  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[] | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  socket.on("newMessageEvent", (data) => {
    const { conversationId } = data;
    if (conversationId !== conversation?._id) {
      return;
    } else {
      setReload(true);
    }
  });

  socket.on(
    "newConversationEvent",
    (data: { firstUser: string; secondUser: string }) => {
      const { firstUser, secondUser } = data;
      if (firstUser !== userId && secondUser !== userId) {
        return;
      } else {
        setReload(true);
      }
    }
  );

  useEffect(() => {
    if (initialReload) {
      getConversation(gigUser._id)
        .then((results: undefined | { conversation: Conversation }) => {
          if (results) {
            setConversation(results.conversation);
            setReload(true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setInitialReload(false);
        });
    }
    if (reload && !conversation) {
      getConversation(gigUser._id)
        .then((results: { conversation: Conversation }) => {
          setConversation(results.conversation);
          setMessages(results.conversation.messages);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReload(false);
        });
    }
    if (reload && conversation) {
      getUserMessages(conversation._id)
        .then((userMessages) => {
          setMessages(userMessages.messages);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReload(false);
        });
    }
    messagesEndRef.current?.scrollIntoView({
      inline: "start",
      block: "nearest",
      behavior: "auto",
    });
  }, [reload, gigUser._id, conversation, initialReload]);

  return (
    <Card
      style={{
        width: "450px",
        height: "75%",
        bottom: "5vh",
        right: "2vh",
        zIndex: 9999,
        background: "#FFF",
        position: "fixed",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
      }}
    >
      <Card.Header>
        <MiniChatHeader
          user={gigUser}
          closeModal={() => {
            closeModal();
            setReload(false);
            setInitialReload(true);
          }}
        />
      </Card.Header>
      <Card.Body style={{ overflowY: "scroll" }}>
        {conversation &&
          messages &&
          messages.map((message) => {
            return (
              <MessagePill
                key={message._id}
                message={message}
                isUser={message.sender._id !== gigUser._id}
              />
            );
          })}
        <div ref={messagesEndRef} />
      </Card.Body>
      <Card.Footer>
        <MiniChatForm to={gigUser._id} />
      </Card.Footer>
    </Card>
  );
};

export default MiniChatModal;
