const FourthSection = function () {
  return (
    <div>
      <h5>חשיפת המידע שלך</h5>
      <p>
        אנו עשויים לחשוף את המידע האישי שלך לספקי שירותים של צד שלישי המבצעים
        שירותים בשמנו, כגון מעבדי תשלומים או ספקי תמיכת לקוחות. אנו עשויים גם
        לחשוף את המידע שלך אם אנו נדרשים לעשות זאת על פי חוק או בתגובה לצו בית
        משפט או בקשה משפטית אחרת.
      </p>
    </div>
  );
};

export default FourthSection;
