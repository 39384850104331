import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Conversation } from "../interface/Conversation.interface";
import ConversationCard from "./ConversationsComp/ConversationCard";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const ConversationsPane = function (props: {
  conversations: Conversation[] | null;
  userId: string | null;
  currentConversation: Conversation | null;
  setCurrentConversation: (id: Conversation) => void;
  isMobile: boolean;
}) {
  const {
    userId,
    setCurrentConversation,
    conversations,
    currentConversation,
    isMobile,
  } = props;

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Conversation[] | null>(
    null
  );

  useEffect(() => {
    if (!searchInput) {
      setSearchResults(null);
      return;
    } else if (searchInput) {
      const value = searchInput.toLowerCase();
      const filteredConversations = conversations!.filter((conversation) => {
        if (userId === conversation.firstUser.user._id.toString()) {
          if (
            conversation.secondUser.user.firstName
              .toLowerCase()
              .includes(value) ||
            conversation.secondUser.user.lastName.toLowerCase().includes(value)
          ) {
            return conversation;
          } else {
            return null;
          }
        } else {
          if (
            conversation.firstUser.user.firstName
              .toLowerCase()
              .includes(value) ||
            conversation.firstUser.user.lastName.toLowerCase().includes(value)
          ) {
            return conversation;
          } else {
            return null;
          }
        }
      });
      setSearchResults(filteredConversations);
    }
  }, [searchInput, conversations, userId]);

  return (
    <div
      className={` bg-light border ${
        !isMobile ? "col-12 col-md-3" : "col-12"
      } ${isMobile && currentConversation && "d-none"}`}
      style={{
        overflowY: "hidden",
        minHeight: "85vh",
      }}
    >
      <div style={{ height: "64px" }} className="d-flex align-items-center">
        <div
          className="mx-2 d-flex align-items-center form-control"
          style={{ borderRadius: "22px" }}
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className={`${searchInput ? "text-dark" : "text-secondary"}`}
          />
          <input
            type="text"
            value={searchInput}
            className="mx-1 col-11"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchInput(e.target.value)
            }
            style={{ border: "none", outline: "none" }}
          />
        </div>
      </div>
      {conversations && !searchResults ? (
        conversations.map((conversation) => {
          return (
            <ConversationCard
              currentConversation={currentConversation}
              key={conversation._id}
              conversation={conversation}
              userId={userId}
              setConversation={() => setCurrentConversation(conversation)}
            />
          );
        })
      ) : (
        <div>
          {searchResults &&
            searchResults.map((conversation) => {
              return (
                <ConversationCard
                  key={conversation._id}
                  currentConversation={currentConversation}
                  conversation={conversation}
                  userId={userId}
                  setConversation={() => {
                    setSearchInput("");
                    setSearchResults(null);
                    setCurrentConversation(conversation);
                  }}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ConversationsPane;
