const ThirdSection = function () {
  return (
    <div>
      <h5>כיצד אנו משתמשים במידע שלך</h5>
      <p>
        אנו עשויים להשתמש במידע האישי שלך כדי לספק לך את השירותים שלנו, לעבד
        תשלומים, לתקשר איתך לגבי חשבונך או השירותים שלנו, ולשפר את האתר
        והשירותים שלנו. אנו עשויים גם להשתמש במידע שלך כדי לשלוח לך תקשורת
        שיווקית על השירותים שלנו או על מוצרים ושירותים של צד שלישי.
      </p>
    </div>
  );
};

export default ThirdSection;
