import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import LinkItem from "./NavbarComp/LinkItem";
import { Category } from "../../features/categories/interface/category.interface";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CategoriesNav = function (props: {
  categories: Category[];
  scrollPos: number;
  authCheck: boolean;
  frontPage: boolean;
}) {
  const location = useLocation();
  const [hide, setHide] = useState<boolean>(false);
  const { frontPage, categories, scrollPos, authCheck } = props;

  useEffect(() => {
    if (
      location.pathname !== "/users/login" &&
      location.pathname !== "/users/register" &&
      !frontPage
    ) {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [location.pathname, frontPage]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 12,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 0,
    },
  };
  return (
    <>
      {!authCheck && hide && scrollPos < 100 ? null : (
        <nav
          style={{
            background: "#E2E2E2",
            boxShadow: "0px 1px 4px rgba(0,0,0,0.5)",
            top: "75px",
          }}
          className={`d-none d-md-block ${
            !authCheck && frontPage && "fixed-top"
          }`}
        >
          <div>
            <Carousel responsive={responsive} rtl={true} itemClass="link-item">
              {categories.map((category) => {
                return (
                  <LinkItem
                    key={category._id}
                    to={`/gigs/${category.urlName}/`}
                    text={category.name!}
                  />
                );
              })}
            </Carousel>
          </div>
        </nav>
      )}
    </>
  );
};

export default CategoriesNav;
