import { useState } from "react";
import { registerUser } from "../api/user-register";
// import RegisterForm from "../components/Forms/RegisterForm";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { uiActions } from "../../../store/slices/ui/ui-slice";
import Alert from "../../../components/Alert";
import RegisterFormV2 from "../components/Forms/RegisterFormV2";
import { Link } from "react-router-dom";

const RegisterPage = function () {
  const dispatch = useAppDispatch();
  // const [errState, setErrState] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { serverError } = useAppSelector((state) => state.ui);
  const registerHandler = function (registerInfo: any) {
    // for (const [key, value] of Object.entries(registerInfo)) {
    //   if (!value) {
    //     setErrState(key);
    //     break;
    //   }
    // }
    if (!loading) {
      setLoading(true);
      dispatch(registerUser(registerInfo, (state) => setLoading(state)));
    }
  };

  const resetServerError = function () {
    dispatch(uiActions.resetError());
  };

  return (
    <div>
      <div className="mt-5 p-3 p-md-5 container" style={{ minHeight: "125vh" }}>
        <div className="d-flex justify-content-center mt-5">
          <div
            className="col-12 col-md-6 card p-4"
            style={{ boxShadow: "0px 0px 4px rgba(0,0,0,0.2)" }}
          >
            {/* <RegisterForm
            onRegister={registerHandler}
            errState={errState}
            onInputChange={onInputChange}
          /> */}
            <h3 className="mb-5 text-center">
              התחילו את המסע שלכם, יחד איתנו.
            </h3>
            <RegisterFormV2
              onSubmit={registerHandler}
              resetError={resetServerError}
              loading={loading}
            />
            {serverError && <Alert message={serverError} />}
            <div className="text-center mt-3">
              <span>רשום כבר? </span>
              <Link
                to={"/users/login"}
                className="link-danger"
                style={{ textDecoration: "none" }}
              >
                להתחברות
              </Link>
            </div>
          </div>
          <div className="d-none d-xxl-block mt-5">
            <img
              src="https://res.cloudinary.com/dm20uwmki/image/upload/v1695720863/haltu_assets/Moving_forward-pana-no-bg_pqdsob.svg"
              alt="מסע"
              style={{ width: "700px", height: "600px", marginRight: "60px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
