const FreelancerBenefits = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1 className="text-center m-0">
        פרילנסרים? תוכלו ליהנות מהדברים הבאים:
      </h1>
      <div className="p-0 p-md-5 col-10 d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529071/haltu_assets/team_476700_lpuyia.svg"
            alt="קליינטים"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">מציאת קליינטים</h5>
          <p className="mt-2 text-center text-secondary">
            גישה מלאה לשוק הארצי בו תוכלו להכיר קליינטים חדשים. דבר זה יגדיל את
            החשיפה שלכם לקליינטים פוטנציאלים ויתן לכם הזדמנות לעבוד על פרויקטים
            מרגשים שיתאימו לכישוריכם.
          </p>
        </div>
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529062/haltu_assets/chat_1789947_gbrkj3.svg"
            alt="יצירת קשר"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">היכרות מקצועית</h5>
          <p className="mt-2 text-center text-secondary">
            הפלטפורמה שלנו תספק לכם את ההזדמנות להיקשר עם פרילנסרים אחרים
            בתעשייה. תוכלו לשתף את החוויות וללמוד מאחרים בתחום, מה שיכול להגדיל
            את המוניטין שלכם ולהרחיב את המעגל העסקי שלכם.
          </p>
        </div>
        <div className="mx-2 mt-2 col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694529072/haltu_assets/web-programming_1336494_ro5a8w.svg"
            alt="קוד"
            style={{ width: "100px" }}
          />
          <h5 className="mt-4">ממשק קל לשימוש</h5>
          <p className="mt-2 text-center text-secondary">
            חלטו מעוצבת בממשק ידידותי למשתמש שהופך את אופן מציאת העבודה וניהול
            הפרויקטים לקל יותר. תוכלו בקלות ליצור משתמש, לקבל עבודות, וליצור קשר
            עם קליינטים, הכל ממקום אחד!
          </p>
        </div>
      </div>
    </div>
  );
};

export default FreelancerBenefits;
