import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/category`;

export const getCategories = createAsyncThunk(
  "category/getCategories",
  (data, thunkApi) => {
    return fetch(URL)
      .then((allCategories) => {
        return allCategories.json();
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message);
      });
  }
);
