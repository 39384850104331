import { Link } from "react-router-dom";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Order } from "../interface/order.interface";
import { useAppSelector } from "../../../store/hooks";
import UserImage from "../../user/components/Image/UserImage";

const OrderCard = function (props: { order: Order }) {
  const { userId } = useAppSelector((state) => state.user);
  const { order } = props;
  const { buyerId, sellerId, gigId, status } = order;
  const defaultImg =
    "https://res.cloudinary.com/dm20uwmki/image/upload/v1690181148/haltu_assets/no_image_found_jchi3o.png";

  const dueDate = new Date(props.order.dueDate);

  const today = new Date(Date.now());

  const overdue: boolean = dueDate <= today;

  let dueDaysNew: number;
  let dueHoursNew: number;
  if (!overdue && status !== "FINISHED" && status !== "CANCELED") {
    let difference = dueDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(difference / (1000 * 3600 * 24));
    const hoursLeft = dueDate.getUTCHours() + 24 - today.getUTCHours();
    dueDaysNew = daysLeft >= 0 ? daysLeft - 1 : 0;
    dueHoursNew = hoursLeft >= 0 ? hoursLeft : 0;
  } else {
    dueDaysNew = 0;
    dueHoursNew = 0;
  }

  let badgeColor: string;
  let badgeText: string;
  switch (props.order.status) {
    case "FINISHED": {
      badgeColor = "success";
      badgeText = "הסתיימה";
      break;
    }
    case "CANCELED": {
      badgeColor = "danger";
      badgeText = "בוטלה";
      break;
    }
    case "PENDING": {
      badgeColor = "primary";
      badgeText = "אישור מוכר";
      break;
    }
    case "AWAITING": {
      badgeColor = "primary";
      badgeText = "אישור לקוח";
      break;
    }
    case "IN-PROGRESS": {
      if (overdue) {
        badgeColor = "warning";
        badgeText = "איחור";
      } else {
        badgeColor = "dark";
        badgeText = "תהליך";
      }
      break;
    }
    default: {
      badgeColor = "light";
      badgeText = "לא ידוע";
      break;
    }
  }

  return (
    <div className="border-bottom">
      <div className="d-flex">
        <div className="col-2 col-md-5 col-lg-6 d-flex align-items-center">
          {gigId.active && gigId.userId.active === true ? (
            <Link
              to={`/gigs/${gigId.category.urlName}/${gigId._id}`}
              className="d-none d-md-block"
            >
              <img
                src={gigId.imagesUrls![0] || defaultImg}
                className="rounded"
                alt="תמונת הג'וב"
                style={{
                  width: "100px",
                  height: "70px",
                  textDecoration: "none",
                  marginRight: "5px",
                }}
              />
            </Link>
          ) : (
            <div className="mx-2 card d-flex align-items-center">
              <span className="col-6 text-center" style={{ fontSize: "16px" }}>
                ג'וב זה הושהה
              </span>
            </div>
          )}
          {userId === buyerId._id ? (
            <Link
              to={`/users/${sellerId._id}`}
              className="d-flex align-items-center justify-content-center mx-2"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <UserImage
                user={sellerId}
                canEdit={false}
                size={35}
                fontSize={"15px"}
              />
            </Link>
          ) : (
            <Link
              to={`/users/${buyerId._id}`}
              className="d-flex align-items-center justify-content-center mx-2"
              style={{ textDecoration: "none" }}
            >
              <UserImage
                user={buyerId}
                canEdit={false}
                size={35}
                fontSize={"15px"}
              />
            </Link>
          )}
        </div>
        <div className="col-8 col-md-7 col-lg-6">
          <div className="d-flex">
            <div className="col-3">
              <p className="text-secondary p-0 m-0">מחיר:</p>
              <p className="text-dark p-0 m-0 mt-2">₪{order.gigId.price}</p>
            </div>
            <div className="col-4 col-md-3">
              <p className="text-secondary p-0 m-0">מועד:</p>
              <p className="text-dark p-0 m-0 mt-2">
                <FontAwesomeIcon icon={faClock} />
                <span className="mx-1">
                  {order.active ? dueDaysNew : "0"}י,
                  {order.active ? dueHoursNew : "00"}ש
                </span>
              </p>
            </div>
            <div className="col-4 col-md-3">
              <p className="text-secondary p-0 m-0">סטטוס:</p>
              <p className={`badge rounded-pill mt-2 text-bg-${badgeColor}`}>
                <>{badgeText}</>
              </p>
            </div>
            <div className="col-3 d-flex justify-content-center align-items-center">
              <Link
                to={`/users/${userId}/orders/${order._id}`}
                className="link-secondary"
              >
                צפייה
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
