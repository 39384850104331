export const UserDisputesSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>פתרון סכסוכים</h2>
      <p>
        במקרה של אי הסכמה או סכסוכים הנגרמים בין משתמשים, כולל פרילנסרים
        ולקוחות, לגבי מוצרים, שירותים, עסקאות או כל נושא אחר הקשור לשימוש
        בפלטפורמה, חלטו מעודדת את המשתמשים לנסות ראשית לפתור את הבעיה ביניהם דרך
        תקשורת פתוחה ומשא ומתן. אם המשתמשים אינם מצליחים להגיע לפתרון מוצלח,
        ניתן ליצור קשר עם חלטו לקבלת סיוע בנושא. אך חלטו אינה נושאת באחריות
        להיות נציגה של אחד הצדדים ו-או לפתור סכסוכים בין המשתמשים. המשתמשים
        מכירים כי כל התקשרות, עסקה או אינטראקציה עם משתמשים אחרים בפלטפורמה היא
        על אחריותם האישית, וחלטו אינה אחראית לסכסוכים, הפסדים, נזקים או טענות
        הנגרמים כתוצאה משימוש בפלטפורמה זו.
      </p>
    </div>
  );
};
