import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Gig } from "../interface/gig.interface";
import { Review } from "../../reviews/interface/review-interface";
import Carousel from "../../../components/Carousel/Carousel";
import UserImage from "../../user/components/Image/UserImage";
import ReviewSum from "../../reviews/components/ReviewsSum";
import { useAppSelector } from "../../../store/hooks";

import { addDots } from "../../../utils/trimmers";
import LikeButton from "../../favorites/components/LikeButton";

interface GigCardProps {
  gig: Gig;
}
export const GigCard: React.FC<GigCardProps> = function (props) {
  let gigReviews: Review[] = [];
  const { userId } = useAppSelector((state) => state.user);
  const { favoriteGigs } = useAppSelector((state) => state.favorites);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    favoriteGigs.findIndex((gig) => gig._id === props.gig._id) !== -1
      ? setIsFavorite(true)
      : setIsFavorite(false);
  }, [favoriteGigs, props.gig._id]);

  if (props.gig !== undefined) {
    gigReviews = props.gig.userId.receivedReviews.filter(
      (review) => review.gig === props.gig._id
    );
  }

  const addToViewed = () => {
    if (props.gig.userId._id === userId) {
      return;
    }
    const maxLength = 8;
    let storageViewedGigs: Gig[] = [];
    const ls = localStorage.getItem("viewedGigs");
    if (ls) {
      let addGig: boolean = false;
      storageViewedGigs = JSON.parse(ls);
      for (let i = 0; i < storageViewedGigs.length; i++) {
        const viewedGig = storageViewedGigs[i];
        if (viewedGig._id === props.gig._id) {
          addGig = false;
          break;
        } else if (props.gig.userId._id === userId) {
          addGig = false;
          return;
        } else {
          addGig = true;
        }
      }
      if (addGig) {
        if (storageViewedGigs.length >= maxLength) {
          storageViewedGigs.pop();
          storageViewedGigs.unshift(props.gig);
          localStorage.setItem("viewedGigs", JSON.stringify(storageViewedGigs));
        } else {
          storageViewedGigs.unshift(props.gig);
          localStorage.setItem("viewedGigs", JSON.stringify(storageViewedGigs));
        }
      }
    } else {
      storageViewedGigs.unshift(props.gig);
      localStorage.setItem("viewedGigs", JSON.stringify(storageViewedGigs));
    }
  };

  return (
    <div
      className="mt-3 card"
      style={{ boxShadow: "0px 5px 4px rgba(0,0,0,0.3)" }}
    >
      <div className="card-body p-0">
        {props.gig && (
          <div>
            <Carousel
              gigId={props.gig._id!}
              images={props.gig.imagesUrls!}
              imageWidth="100px"
              imageHeight="150px"
              extraImageClass="card-img-top"
            />
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center mx-2">
          <Link
            to={`/users/${props.gig.userId?._id}`}
            className="link-dark d-flex mt-2 pt-1 "
            style={{ textDecoration: "none" }}
          >
            <UserImage
              canEdit={false}
              showOnline={true}
              user={props.gig.userId}
              size={25}
              fontSize={"10px"}
            />

            <p className="mx-2">
              {props.gig.userId?.firstName} {props.gig.userId?.lastName}
            </p>
          </Link>
          <div className="mt-3">
            <ReviewSum receivedReviews={gigReviews} />
          </div>
        </div>
        <Link
          to={`/gigs/${props.gig.category.urlName}/${props.gig._id}`}
          className="link-danger"
          style={{ textDecoration: "none" }}
          onClick={addToViewed}
        >
          <h5 className="card-title mx-2">{addDots(props.gig.title, 20)}</h5>
        </Link>
      </div>
      <div className="border-top"></div>
      <div className="d-flex justify-content-between align-items-center mx-2">
        <div className="mx-2 fs-5">
          {props.gig.userId._id !== userId && (
            <LikeButton gigId={props.gig._id} isFavorite={isFavorite} />
          )}
        </div>
        <div className="d-flex flex-column mx-2" style={{ fontSize: "80%" }}>
          מחיר
          <span className="text-end fs-4">₪{props.gig.price}</span>
        </div>
      </div>
    </div>
  );
};
