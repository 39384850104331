import { baseUrl } from "../../../API/base-urls";

export const subscribeToPremium = function (
  subscriptionID: string,
  clientUserId: string | null
) {
  const token = localStorage.getItem("token");
  return fetch(`${baseUrl}/users/subscription/premium`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subscriptionID: subscriptionID,
      clientUserId: clientUserId,
    }),
  })
    .then((results) => {
      if (!results.ok) throw new Error("Something went wrong");
      return results.json();
    })
    .then((response) => {
      const token = response.newToken;
      localStorage.setItem("token", token);
      window.location.replace("/");
      return;
    })
    .catch((error) => {
      console.log(error);
    });
};
