import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CategoriesCard from "./CategoriesCard";
import { Category } from "../interface/category.interface";

const CategoriesCarousel = function (props: { categories: Category[] }) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    largeTablet: {
      breakpoint: { max: 1224, min: 464 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    smallTablet: {
      breakpoint: { max: 824, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 624, min: 464 },
      items: 1,
    },
    superSmallMobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const { categories } = props;
  return (
    <Carousel
      className="pb-3 pt-3"
      responsive={responsive}
      rtl={true}
      itemClass="category-max-width d-flex justify-content-center"
    >
      {categories.map((category) => {
        return <CategoriesCard key={category._id} category={category} />;
      })}
    </Carousel>
  );
};

export default CategoriesCarousel;
