import { UserInfo } from "../pages/LoginPage";
import { baseUrl } from "../../../API/base-urls";
import { createAsyncThunk } from "@reduxjs/toolkit";

const URL = `${baseUrl}/users`;

export const loginUser = createAsyncThunk(
  "user/loginUser",
  (data: UserInfo, thunkApi) => {
    return fetch(`${URL}/login`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Invalid email or password");
        }
        return response.json();
      })
      .catch((error) => {
        return thunkApi.rejectWithValue(error.message);
      });
  }
);
