import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../../API/base-urls";

export const getUserChat = createAsyncThunk(
  "chat/getUserChat",
  (data, thunkApi) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    return fetch(`${baseUrl}/users/${userId}/conversations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((results) => {
        return results.json();
      })
      .catch((error) => {
        console.log(error);
        thunkApi.rejectWithValue(error.message);
      });
  }
);

export const getConversation = function (userId: string) {
  const token = localStorage.getItem("token");
  return fetch(`${baseUrl}/users/${userId}/conversation`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((results) => {
      if (results.status === 204) {
        return;
      }
      if (results.ok) {
        return results.json();
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUserConversations = function (userId: string | null) {
  const token = localStorage.getItem("token");
  return fetch(`${baseUrl}/users/${userId}/conversations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((allConversations) => {
      return allConversations.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUserMessages = function (conversationId: string) {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  return fetch(`${baseUrl}/users/${userId}/conversations/${conversationId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((results) => {
      return results.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
