export const ClientsRealLifeMeetingsSection = () => {
  return (
    <div>
      <h2>פגישות בחיים האמיתיים</h2>
      <p>
        אתה מכיר ומסכים שהסדרת והשתתפותך בפגישות או אינטראקציות בחיים האמיתיים
        עם הפרילנסרים או משתמשים אחרים היא לפי שיקול דעתך ועל אחריותך האישית.
        חלטו אינה אחראית לכל נזק פיזי או התנהגויות שעשויות להתרחש במהלך כאלה
        פגישות.
      </p>
    </div>
  );
};
