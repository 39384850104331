import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Gig } from "../../gigs/interface/gig.interface";
import { useNavigate } from "react-router-dom";
import { createOrder } from "../api/order-create";
import { OrderOutput } from "../interface/order-output.interface";
import { useAppSelector } from "../../../store/hooks";

const SummaryCard = function (props: {
  gig: Gig;
  description: string;
  total: number;
  uploadedFiles: string[];
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { gig, description, total, uploadedFiles } = props;
  const { verified } = useAppSelector((state) => state.user);
  const orderHandler = function () {
    setLoading(true);
    const newOrder: OrderOutput = {
      gig,
      description,
      total,
      images: uploadedFiles,
    };
    createOrder(newOrder)
      .then((createOrder) => {
        setTimeout(() => {
          navigate(`/users/${createOrder.buyerId}/orders/${createOrder._id}`);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };
  return (
    <div className="card mx-3">
      <div className="card-body">
        <div className="d-flex flex-column">
          <h5>סיכום ההזמנה:</h5>
          <div className="mt-2 border-bottom">
            <div className="d-flex justify-content-between">
              <p>מוצר</p>
              <p>₪{props.gig.price}</p>
            </div>
            {/* <div className="d-flex justify-content-between">
              <p>מע"מ</p>
              <p className="text-secondary">
                ₪{(props.gig.price * 0.17).toFixed(2)}
              </p>
            </div> */}
            {/* <div className="d-flex justify-content-between">
              <p>עמלה</p>
              <p className="text-secondary">₪{props.gig.price * 0.2}</p>
            </div> */}
          </div>
          <div className="mt-2">
            <div className="d-flex justify-content-between">
              <p>ימי אספקה</p>
              <p className="text-secondary">{props.gig.deliveryDays} ימים</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>תיקונים</p>
              <p className="text-secondary">{props.gig.revisions} תיקונים</p>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <h6>סה"כ</h6>
              <h6>₪{props.total}</h6>
            </div>
            <div className="border-top">
              <p className="mt-3">{props.gig.whatsIncluded}</p>
            </div>
          </div>
          {verified ? (
            <Button
              className="mt-2"
              variant={`success ${loading && "disabled"}`}
              onClick={orderHandler}
            >
              {loading ? <Spinner size="sm" /> : "לביצוע הזמנה!"}
            </Button>
          ) : (
            <Button className="mt-2" variant="success disabled">
              חשבון לא מאומת
            </Button>
          )}
          {/* <Link
            to={`/gigs/${props.gig.category._id}/${props.gig._id}/check-out`}
            className="btn btn-success mt-2"
            state={{
              gig: props.gig,
              description: props.description,
              images: props.uploadedFiles,
              total: props.total,
            }}
          >
            ביצוע הזמנה!
          </Link> */}
          {/* <p className="text-center text-secondary mt-1">לא תחוייב עדיין</p> */}
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
