import { Review } from "../interface/review-interface";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReviewsSum = function (props: { receivedReviews: Review[] }) {
  const { receivedReviews } = props;
  let ratingAvg: number = 0;
  receivedReviews.forEach((review) => {
    ratingAvg += review.rating;
  });
  return (
    <p>
      <FontAwesomeIcon icon={faStar} />
      <span className="mx-1">
        {Number(
          !(ratingAvg / receivedReviews.length)
            ? 0
            : ratingAvg / receivedReviews.length
        ).toFixed(1)}
      </span>
      <span className="text-secondary">({receivedReviews.length})</span>
    </p>
  );
};

export default ReviewsSum;
