import { baseUrl } from "../../../API/base-urls";
// import { createAsyncThunk } from "@reduxjs/toolkit";
// import openSocket from "socket.io-client";

const URL = `${baseUrl}/gigs`;

// export const getGigs = createAsyncThunk("gigs/getGigs", (data, thunkApi) => {
//   return fetch(URL)
//     .then((allGigs) => {
//       // openSocket(baseUrl);
//       return allGigs.json();
//     })
//     .catch((error) => {
//       console.log(error);
//       return thunkApi.rejectWithValue(error.message);
//     });
// });

export const getMostPopularCategoryGigs = function (
  categoryId: string | undefined
) {
  return fetch(`${URL}/${categoryId}/popular`)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getLatestGigs = function () {
  return fetch(`${URL}`)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCategoryGigs = function (
  categoryId: string | undefined
  // page: number
) {
  // return fetch(`${URL}/${categoryId}/?page=${page}`)
  return fetch(`${URL}/${categoryId}`)
    .then((respone) => {
      return respone.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleGig = function (reqParams: {
  categoryId: string | undefined;
  gigId: string | undefined;
}) {
  return fetch(`${URL}/${reqParams.categoryId}/${reqParams.gigId}`)
    .then((foundGig) => {
      return foundGig.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
