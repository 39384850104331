export const UserPaymentSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>תשלומים על השירותים</h2>
      <p>
        תשלום עבור השירותים שסופקו על ידי פרילנסרים באתר מתבצע באופן עצמאי מחוץ
        למערכת האתר. החברה אינה אחראית לכל הפרת חוזה, חובות שלא שולמו, או לכל
        התנגדות בנוגע לתשלומים או לשירותים שניתנו או לא ניתנו על ידי המשתמשים.
      </p>
    </div>
  );
};
