import { baseUrl } from "../../../API/base-urls";

export const getNotifications = function () {
  const token = localStorage.getItem("token");
  return fetch(`${baseUrl}/users/notifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((results) => {
      if (!results.ok) throw new Error("Something went wrong");
      return results.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
