import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const orderRevisionRequest = function (
  userId: string,
  orderId: string,
  revisionDesc: string
) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/orders/${orderId}/revision`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/JSON",
    },
    body: JSON.stringify({ revisionDesc }),
  });
};
