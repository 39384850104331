import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../../store/hooks";
import { Membership } from "../../user/interface/user.interface";

import { cancelPayment } from "../api/cancel-payment";

const CancelPayment = function () {
  const [loading, setLoading] = useState<boolean>(false);
  const { userId, membership } = useAppSelector((state) => state.user);

  const cancelHandler = () => {
    setLoading(true);
    cancelPayment(userId).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  };

  useEffect(() => {
    if (membership?.name === Membership.free.name) {
      window.location.replace("/");
    }
  }, [membership]);

  return (
    <div
      style={{ minHeight: "100vh" }}
      className="p-5 container d-flex flex-column align-items-center"
    >
      <h1 className="mt-5">ביטול תשלום</h1>
      <p>מצטערים לשמוע שברצונך לבטל את המנוי, בכל שלב תוכלו לרכוש מנוי מחדש.</p>
      <p>
        במידה וחשבונכם יחרוג מכמות הג'ובים המותרת למנוי תצטרכו להסיר בעתיד
        ג'ובים.
      </p>
      <div>
        <img
          className="col-12"
          src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694334202/haltu_assets/7140762_3528487_jask1z.svg"
          alt="עזיבה"
        />
      </div>
      <div className="mt-5 d-flex justify-content-around col-12">
        <Link to="/" className="col-4 btn btn-danger">
          לדף הבית
        </Link>
        <Button
          variant={`outline-danger ${loading && "disabled"}`}
          className="col-4"
          onClick={cancelHandler}
        >
          {loading ? <Spinner variant="danger" size="sm" /> : "ביטול"}
        </Button>
      </div>
    </div>
  );
};

export default CancelPayment;
