export const ThirdPartyIntellectualPropertySection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>אחריות קניין רוחני</h2>
      <p>
        חלטו אינה מניחה אחריות על איכות, דיוק או שלמותם של מוצרים או שירותים
        שמספקים עצמאיים בפלטפורמה. חלטו לא תהיה אחראית להפרות בזכויות הקניין
        הרוחני של צדדים שלישיים, כולל אך לא רק פרטי זכות יוצרים, סימני מסחר
        ופטנטים, הנובעות מעבודה או תוכן שמספקים עצמאיים בפלטפורמה. לכל טענות
        הנוגעות להפרות בזכויות הקניין הרוחני של צדדים שלישיים או לדאגות בנוגע
        לאיכות של מוצרים או שירותים שנמצאים בפלטפורמה, ניתן ליצור קשר איתנו
        בכתובת הדוא"ל [כתובת הדוא"ל] כדי להתחיל בתהליך ביקורת ופתרון.
      </p>
    </div>
  );
};
