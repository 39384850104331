import { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import TextArea from "../../../../components/Forms/TextArea";
import { orderRevisionRequest } from "../../api/order-revision";

const RevisionModal = function (props: {
  open: boolean;
  onClose: () => void;
  orderId: string;
  userId: string | null;
}) {
  const { open, userId, orderId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [errState, setErrState] = useState<boolean>(false);
  const [revisionInput, setRevisionInput] = useState<string>("");
  if (!open) return null;
  const submitHandler = function () {
    if (!revisionInput || loading) {
      setErrState(true);
      return;
    } else {
      setLoading(true);
      orderRevisionRequest(userId!, orderId, revisionInput)
        .then((results) => {
          if (!results.ok) {
            throw new Error("Error");
          }
          if (results.ok) {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 3000);
        });
    }
  };
  return (
    <Modal show={open} onHide={props.onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>בקשה לתיקון</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">אנא פרטו מהם הדגשים שתרצו שהמוכר יתקן:</p>
        <TextArea
          id="revisionDesc"
          extraClass={errState ? "is-invalid" : ""}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setErrState(false);
            setRevisionInput(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-row justify-content-between">
        <Button
          className="col-4 col-md-3 mx-2"
          variant="danger"
          type="button"
          onClick={props.onClose}
        >
          ביטול
        </Button>
        <Button
          variant={`primary ${loading && "disabled"}`}
          className="col-4 col-md-3 mx-2"
          type="button"
          onClick={submitHandler}
        >
          {loading ? (
            <Spinner
              style={{ width: "16px", height: "16px", fontSize: "10px" }}
            />
          ) : (
            "אישור"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevisionModal;
