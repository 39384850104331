export const UserIdentificationSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>אפשרות לאימות המשתמש</h2>
      <p>
        חלטו עשויה להציע תהליך אימות משתמש על מנת לשפר את אמינותם ואמיתותם של
        המשתמשים באתר. משתמשים שעברו את תהליך האימות ועומדים בקריטריונים
        המצויינים עשויים לקבל תג "מאומת" בפרופיל שלהם. במקרים מסוימים, חלטו או
        נציגים מטעמיה עשויים להתחיל באינטראקציה איתך לגבי אימות הזהות שלך.
        האינטראקציה הזו עשויה לכלול מתן מידע אישי ומענה על שאלות למטרת הוכחת
        הזהות שלך. אתה מכיר ומסכים כי השתתפות בתהליך האימות היא פעולה רשות אך
        יכולה להיות נדרשת כדי לגשת לשירותים מסוימים באתר. בבחירתך להשתתף, אתה
        מסכים לספק מידע מדויק ואמיתי ולשיתוף פעולה בתהליך האימות. חלטו תקח
        אמצעים מתאימים להגנה על הפרטיות והאבטחה של המידע המסופק במהלך האימות.
        חלטו ברוב המקרים לא תשמור על נתונים אישיים המתקבלים במהלך התהליך. אם
        התקבלו נתונים אישיים, יתכן שהם ימחקו לאחר השלמת התהליך. החברה עשויה לא
        ליידע את המשתמש אם הנתונים נשמרו. חלטו שומרת לעצמה את הזכות לדחות או
        להסיר את תג ה-"מאומת" אם יוגש מידע שקרי או לא עדכני במהלך האימות ו-או אם
        המשתמש אינו עומד בקריטריוני האימות. משתמשים המקבלים את תג ה-"מאומת"
        מסכימים לשמור על עדכניות המידע שמאומת ולעדכן את חלטו במקרה של שינויים
        בפרטים.
      </p>
    </div>
  );
};
