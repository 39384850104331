export const UserProfilesSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>פרופיל המשתמש</h2>
      <p>
        פרופיל משתמש בחלטו הינם נחשבים פומביים וניתן לצפות בהם על ידי משתמשים
        אחרים ומבקרי האתר. על ידי יצירת פרופיל, אתה מכיר כי המידע והתוכן שאתה
        מכליל בפרופיל שלך, כולל אך לא רק את שמך, תמונת הפרופיל, תיאור עצמי,
        ופרטים נוספים, יהיו גלויים לאחרים. אתה אחראי באופן בלעדי לדיוק, לתוקף
        ולעדכון של המידע והתוכן בפרופיל שלךהנך מאשר בזה כי הנך האחראי הבלעדי לכל
        המידע המופיע בפרופיל שלך, הנך מתחייב כי הנך הבעלים החוקי של כל המידע
        האמור וכי המידע האמור, אינו מפר, לא הפר ולא יפר כל קניין רוחני, זכויות
        יוצרים, סימני מסחר, רשיונות וכיוצא בכך. כל המידע שאתה מספק חייב להיות
        אמיתי ולשקף את מעמדך הנוכחי והשירותים שלך. חלטו שומרת לעצמה את הזכות
        לצפות ולבחון את פרופילי המשתמש על מנת לוודא התאמה לתנאים והגבלות אלו. אם
        יימצא כי פרופיל משתמש חורג מתנאי אלו, חלטו עשויה, לפי שיקול דעתה הבלעדי,
        למחוק או לשנות את התוכן ללא הודעה מראש. פרופילים הכוללים מידע שגוי, תוכן
        לא ראוי או הפרות להגבלות שנקבעו בתנאים והגבלות אלו, עשויים להיחשף להסרת
        התוכן וכמו כן להשעיית החשבון.
      </p>
    </div>
  );
};
