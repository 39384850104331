import { baseUrl } from "../../../API/base-urls";

export const createUserMessage = function (
  to: string,
  text: string,
  userId: string | null
) {
  const token = localStorage.getItem("token");
  return fetch(`${baseUrl}/users/${userId}/message`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    body: JSON.stringify({ userId: to, message: text }),
  });
};
