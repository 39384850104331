const EighthSection = function () {
  return (
    <div>
      <h5>שינויים במדיניות שלנו</h5>
      <p>
        אנו עשויים לעדכן מדיניות זו מעת לעת. אם נבצע שינויים מהותיים במדיניות
        זו, נודיע לך בדוא"ל או על ידי פרסום הודעה באתר האינטרנט שלנו. צור קשר אם
        יש לך שאלות או חששות כלשהם לגבי מדיניות זו או נוהלי הפרטיות שלנו.
      </p>
    </div>
  );
};

export default EighthSection;
