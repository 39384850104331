import { Link } from "react-router-dom";

interface LinkItemProps {
  to: string;
  text: string;
  onClick?: () => void;
}

const LinkItem: React.FC<LinkItemProps> = function (props) {
  return (
    <div className="p-2 px-0 text-center">
      <Link
        to={props.to}
        style={{ background: "none" }}
        className="nav-link"
        aria-current="page"
        onClick={props.onClick}
      >
        {props.text}
      </Link>
    </div>
  );
};

export default LinkItem;
