export const SpamContentSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>איסור תוכן ספאם</h2>

      <p>
        האתר haltu.co.il ("האתר") מחויב לספק חוויית משתמש חיובית ושימושית לכל
        המשתמשים. כדי להבטיח את תפיסת המערכת ולשמור על סביבה של כבוד, הגשת,
        שיתוף או הפצת תוכן ספאם אסורה. תוכן ספאם כולל, אך לא מוגבל, הודעות
        פרסומיות לא מוזמנות, פרסומות, קישורים או כל תוכן שמטרתו לרמות, להונות או
        לטעות משתמשים. האיסור זה חל על כל צורת תקשורת באתר, כולל הודעות, תגובות,
        ביקורות ופוסטים. משתמשים שעשויים לעסוק בפעילויות ספאם עשויים להתמודד עם
        השעיית החשבון והסרת התוכן מהפלטפורמה. חלטו שומרת לעצמה את הזכות לקבוע
        מהו נחשב לתוכן ספאם ומה הפעולות הנדרשות כדי למנוע את הפצתו. אנחנו
        מעריכים את יוצרי התוכן ומזמינים אותך להיות מעורב ולדווח על תוכן חשוד או
        דומה לספאם לצוות התמיכה שלנו במייל support@haltu.co.il לצורך בחינה
        ופעולה. בשימושך באתר, אתה מכיר ומסכים להימנע מפעילויות ספאם שמפריעות
        בחוויית המשתמש ועוברות את ההנחיות של הפלטפורמה. חלטו מחויבת לשמור על
        סביבה בטוחה ונעימה לכל המשתמשים ומעריכה את שיתוף הפעולה שלך בנוגע לנושא
        זה.
      </p>
    </div>
  );
};
