export const UserCreationSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>יצירת חשבון</h2>
      <p>
        ייתכן כי תצטרך ליצור חשבון כדי לגשת לתכונות מסוימות באתר. אתה אחראי
        לשמירה על הסודיות של מידע החשבון שלך וכל הפעילויות שהתרחשו בחשבון שלך.
        חלטו שומרת לעצמה את הזכות לסיים חשבונות, להסיר או לערוך תוכן, ולסרב
        לשירות לפי שיקול דעתה בלבד.
      </p>
    </div>
  );
};
