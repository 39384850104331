import { Routes, Route } from "react-router-dom";
// import AccessibilityPage from "../generalPages/AboutUs/AccessibilityPolicy/AccessibilityPage";
import NotFound from "../generalPages/NotFound";
import FAQPage from "../generalPages/AboutUs/FAQPage/FAQPage";
import TermsOfService from "../generalPages/AboutUs/TermsOfService/TermsOfService";
import PrivacyPolicy from "../generalPages/AboutUs/PrivacyPolicy/PrivacyPolicyPage";

const AboutUsRoutes = function () {
  return (
    <Routes>
      <Route path="faq" element={<FAQPage />} />
      <Route path="terms-of-service" element={<TermsOfService />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AboutUsRoutes;
