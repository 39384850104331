export const ClientsDataDiscretionSection = () => {
  return (
    <div>
      <h2>הסתרת מידע</h2>
      <p>
        אתה מסכים לשמור על סודיות כל מידע רגיש שנשלח על ידי הפרילנסרים במהלך
        הפרויקט ולא לחשוף מידע זה לצדדים שלישיים בלעדי הסכמת הפרילנסר.
      </p>
    </div>
  );
};
