const SecondSection = function () {
  return (
    <div>
      <h5>מידע שאנו אוספים</h5>
      <p>
        אנו עשויים לאסוף ממך מידע אישי כאשר אתה נרשם לחשבון, נרשם לשירותים שלנו
        או משתמש באתר האינטרנט שלנו. סוגי המידע האישי שאנו אוספים עשויים לכלול
        את שמך, כתובת הדואר האלקטרוני שלך, פרטי חיוב ופרטי קשר אחרים.
      </p>
    </div>
  );
};

export default SecondSection;
