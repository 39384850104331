const FeaturesButtons = (props: {
  feature: number;
  featureNumber: number;
  setFeature: (num: number) => void;
  url: string;
  header: string;
  alt: string;
}) => {
  const { feature, featureNumber, setFeature, url, header, alt } = props;
  return (
    <div
      className="p-3 p-md-3 d-flex flex-column align-items-center justify-content-center main-feed-feature rounded"
      style={{
        width: "auto",
        maxWidth: "80px",
        minHeight: "80px",
        cursor: "pointer",
        background: `${feature === featureNumber ? "#f0f0f0" : "none"}`,
      }}
      onClick={() => setFeature(featureNumber)}
    >
      <img
        src={url}
        alt={alt}
        style={{
          cursor: "pointer",
          width: "70%",
          filter: `${feature === featureNumber ? "none" : "invert(100%)"}`,
        }}
      />
      <h5
        style={{ fontSize: "70%" }}
        className={`mt-3 text-${
          feature === featureNumber ? "dark" : "light"
        } mb-0 pb-0`}
      >
        {header}
      </h5>
    </div>
  );
};

export default FeaturesButtons;
