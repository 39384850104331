export const RealLifeMeetingsSection = () => {
  return (
    <div>
      <h2 style={{ color: "#ff4e5b" }}>פגישות בחיים האמיתיים</h2>

      <p>
        משתמשים באתר haltu.co.il ("האתר") עשויים לקבל אפשרות להסדיר ולהשתתף
        בפגישות או ביצירת קשר בחיים האמיתיים עם משתמשים אחרים לצורך דיונים
        בנושאי פרוייקט,הזמנות, שיתופי פעולה או התייעצויות. אתה מכיר ומסכים שכל
        פגישות או ביצוע קשר בחיים האמיתיים הנסדרים דרך האתר הם לפי שיקול דעתך
        ועל אחריותך הבלעדית. חלטו לא ממליצה, אינה תומכת ואינה מבטיחה את הבטיחות,
        התוצאות או התנהגותם של המשתתפים בפגישות כאלו. חלטו אינה מתחייבת כלפיך
        לכל נזק פיזי, פציעה, נזק או אובדן העשוי להתרחש במהלך או כתוצאה מפגישות
        בחיים האמיתיים שהוסדרו דרך האתר. מומלץ למשתמשים להיות זהירים, ולהיפגש
        במקומות בטוחים וציבוריים ולהסדיר אמצעים מתאימים להבטחת בטיחותם האישית.
        חלטו אינה תישא באחריות כל סכסוכים, חוסר הסכמה או השלכות הנובעים
        מהאינטראקציות או טרנזקציות בחיים האמיתיים בין משתמשים בפלטפורמה. משתמשים
        שעשויים להשתתף בפגישות בחיים האמיתיים עושים זאת ברשות ומשאירים לעצמם את
        האחריות המלאה על פעולותיהם והחלטותיהם. מומלץ למשתמשים שמשתתפים בפגישות
        בחיים האמיתיים לקבוע ביטוח מתאים לצורך הפחתת הסיכונים והאחריות העשויים
        להיות קשורים לפגישות בחיים האמיתיים.
      </p>
    </div>
  );
};
