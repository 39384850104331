import { CommitmentToWorkSection } from "./FreelanceComponents/CommitmentToWorkSection";
import { DenailOfServiceSection } from "./FreelanceComponents/DenailOfServiceSection";
import { FreelancerReviewsSection } from "./FreelanceComponents/FreelancerReviewsSection";
import { SelfEmployedSection } from "./FreelanceComponents/SelfyEmployedSection";

export const FreelanceAgreement = () => {
  return (
    <div>
      <h1 style={{ color: "#ff4e5b" }}>הסכם פרילנסרים</h1>
      <p style={{ fontWeight: 700 }}>
        על ידי הסכם זה עם חלטו, אתה מכיר ומסכים לתנאים הנוספים המתוארים למטה
        לתפקידך כפרילנסר המספק מוצרים ושירותים דרך הפלטפורמה haltu.co.il
        ("הפלטפורמה").
      </p>
      <p>
        חלטו שומרת לעצמה את הזכות לשנות, לעדכן, או לשנות את התנאים וההגבלות הללו
        בכל עת מבלי לספק הודעה מראש. זהו עליך לבדוק מעת לעת את התנאים האלו
        לשינויים. השימוש המתמשך באתר לאחר שנעשו שינויים מהווה את הסכמתך לתנאים
        המעודכנים.
      </p>
      <CommitmentToWorkSection />
      <SelfEmployedSection />
      <FreelancerReviewsSection />
      <DenailOfServiceSection />
      <p>
        כפרילנסר , אתה מכיר ומסכים לתנאים האתים של חלטו ולתקנות אלו, המסירות,
        הזמינות, והטיפול ההוגן שלך בלקוחות הם חלקים אינטגרליים ביצירת סביבת
        פרילנסינג חיובית בפלטפורמה. אתה מבין שכל הפרה של סעיף זה עשויה לגרום
        לתוצאות שליליות עבור המוניטין שלך, מעמד החשבון שלך והשתתפויות עתידיות
        בפלטפורמה.
      </p>
    </div>
  );
};
