import { User } from "../../user/interface/user.interface";
import UserImage from "../../user/components/Image/UserImage";
import ReviewsSum from "../../reviews/components/ReviewsSum";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerificationBadge from "../../user/components/VerificationBadge";

const ChatUserCard = function (props: { user: User }) {
  const { user } = props;

  const date = new Date(Date.parse(props.user.createdAt));
  const year = date.getFullYear();
  const month = date.toLocaleString("heb", { month: "short" });

  return (
    <div className="mt-5 d-flex flex-column align-items-center">
      <div className="mt-3">
        <UserImage user={user} canEdit={false} size={150} />
      </div>
      <div className="text-center">
        <div className="d-flex align-items-center justify-content-center">
          <VerificationBadge idVerification={props.user.idVerification} />
          <h5 className="mx-2 card-title">{`${props.user.firstName} ${props.user.lastName}`}</h5>
        </div>
        <ReviewsSum receivedReviews={props.user.receivedReviews} />
        <p className="card-text text-secondary" style={{ fontSize: "14px" }}>
          {props.user.email}
        </p>
      </div>
      <div className="mt-4 col-12 d-flex justify-content-between">
        <div>
          <span>
            <FontAwesomeIcon icon={faCalendar} />
          </span>
          <span className="mx-2">הצטרף בתאריך:</span>
        </div>
        <div>
          <span>
            {year} {month}
          </span>
        </div>
      </div>
      {user.active === false ? (
        <h4 className="text-danger">משתמש זה מושהה</h4>
      ) : null}
    </div>
  );
};

export default ChatUserCard;
