import { Link } from "react-router-dom";
import { Category } from "../../features/categories/interface/category.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  // faLinkedin,
  // faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import FooterLinks from "./FooterLinks";

const Footer = function (props: { categories: Category[] }) {
  // const { categories } = props;
  return (
    <div
      className="justify-self-end border-top d-flex flex-column justify-content-center"
      style={{
        height: "400px",
        // background: "#313131",
        background: "#ffffff",
        marginTop: "100px",
      }}
    >
      <div className="d-flex justify-content-center">
        <div className="col-12 mt-4 d-flex flex-column justify-content-center">
          <div className="mt-4 mb-3 d-flex flex-column align-items-center">
            <div>
              <Link
                to="/"
                style={{ textDecoration: "none", fontFamily: "Comfortaa" }}
                className="link-dark"
              >
                <div
                  style={{
                    fontSize: "42px",
                    fontWeight: "700",
                    direction: "ltr",
                  }}
                  className="d-flex"
                >
                  <span style={{ letterSpacing: "-5px" }}>
                    <span>h</span>
                    <span
                      style={{
                        color: "#ff4e5b",
                      }}
                    >
                      `
                    </span>
                  </span>
                </div>
              </Link>
            </div>
            <FooterLinks />
            <div
              className="d-flex flex-column text-center text-secondary"
              style={{ fontSize: "14px", fontWeight: "400" }}
            >
              <span className="mt-2 text-secondary">
                A Roei Katabi Production
              </span>
              <span className="text-secondary">Haltu Israel © 2023</span>
            </div>
          </div>
          <div
            className="mb-3 mt-1 text-dark d-flex justify-content-center"
            style={{ fontSize: "28px" }}
          >
            {/* <FontAwesomeIcon icon={faTwitter} className="mx-2" /> */}
            <a
              className="link-dark link-item"
              href="https://www.facebook.com/profile.php?id=61550082780300"
              target="blank"
            >
              <FontAwesomeIcon icon={faFacebook} className="mx-2" />
            </a>
            <a
              className="link-dark link-item"
              href="https://www.instagram.com/haltu_israel/"
              target="blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            {/* <FontAwesomeIcon icon={faLinkedin} className="mx-2" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
