import { useAppSelector } from "../../../../store/hooks";
import UserImage from "../../../user/components/Image/UserImage";
import { OrderComment } from "../../interface/order-comment.interface";

const CommentCard = function (props: { comment: OrderComment }) {
  const { userId } = useAppSelector((state) => state.user);
  const { comment } = props;
  const { commentSender } = comment;
  const isUser: boolean = userId === commentSender._id;

  return (
    <div
      className={`d-flex align-items-center mt-2 mb-2 ${
        !isUser && "flex-row-reverse"
      }`}
    >
      <div className="mx-2">
        <UserImage
          user={commentSender}
          canEdit={false}
          size={32}
          fontSize={"16px"}
        />
      </div>
      <span
        className={`badge rounded-pill text-bg-${
          isUser ? "danger" : "secondary"
        } p-2`}
      >
        {comment.commentBody}
      </span>
    </div>
  );
};

export default CommentCard;
