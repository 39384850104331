import { baseUrl } from "../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export interface NewPassword {
  token: string;
  password: string;
  confirmPass: string;
}

export const postPassword = function (newPassword: NewPassword) {
  return fetch(`${URL}/reset-password/${newPassword.token}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPassword),
  })
    .then((results) => {
      if (results.ok) {
        window.location.replace("/");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
