const SeventhSection = function () {
  return (
    <div>
      <h5>קובצי Cookies</h5>
      <p>
        אנו עשויים להשתמש בקובצי Cookie או בטכנולוגיות דומות אחרות כדי לאסוף
        מידע על אופן השימוש באתר או בשירותים שלנו. אתה יכול להשבית קובצי Cookie
        בהגדרות הדפדפן שלך אם אינך רוצה שנאסוף מידע זה.
      </p>
    </div>
  );
};

export default SeventhSection;
