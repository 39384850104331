import { useEffect } from "react";
import { getCategories } from "../../features/categories/api/categories-read";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const useGetCategories = function () {
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.categories);
  useEffect(() => {
    if (categories === null) {
      dispatch(getCategories());
    }
  }, [dispatch, categories]);
};
