import EditAboutMe from "../Fields/EditAboutMe";

const AboutUserCard = function (props: { aboutMe: string; isUser: boolean }) {
  return (
    <div className="align-items-start">
      <EditAboutMe aboutMe={props.aboutMe} isUser={props.isUser} />
    </div>
  );
};

export default AboutUserCard;
