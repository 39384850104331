import { baseUrl } from "../../../API/base-urls";
import { AppDispatch } from "../../../store/store";
import { getFavorites } from "./favorites-read";

export const deleteFavorites = function (gigId: string) {
  const token = localStorage.getItem("token");
  return (dispatch: AppDispatch) => {
    const deleteFavorite = fetch(`${baseUrl}/users/favorites`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({ gigId }),
    });
    deleteFavorite
      .then((results) => {
        if (!results.ok) {
          throw new Error("Something went wrong");
        }
        dispatch(getFavorites());
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
