import { baseUrl } from "../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const getAllOrders = function (userId: string | null) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((allOrders) => {
      return allOrders.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSingleOrder = function (userId: string, orderId: string) {
  const token = localStorage.getItem("token");
  return fetch(`${URL}/${userId}/orders/${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((foundOrder) => {
      return foundOrder.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
