import React from "react";
import { Link } from "react-router-dom";
import { Gig } from "../../interface/gig.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock } from "@fortawesome/free-solid-svg-icons";

const GigPricing = function (props: {
  gig: Gig;
  isSeller: boolean;
  userId: string | null;
}) {
  const { gig, userId } = props;

  const check = <FontAwesomeIcon icon={faCheck} className="text-primary" />;
  const clock = <FontAwesomeIcon icon={faClock} />;

  const rowsOfText: string[] = gig.whatsIncluded.split("\n");

  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-3">
          <h5 className="pb-2 border-bottom">מה מקבלים?</h5>
          <div>
            {rowsOfText.map((row, index) => {
              if (row === "") {
                return null;
              } else {
                return <h6 key={index}>{row}</h6>;
              }
            })}
          </div>
        </div>
        <div className="container pb-2 border-bottom">
          <div className="text-dark fw-semibold">
            {clock} {gig.deliveryDays} ימי אספקה
          </div>
          <div className="text-secondary mt-1 mb-1">
            <span>
              {check} {gig.revisions} תיקונים
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <div>
            <h5>סה"כ:</h5>
          </div>
          <h5 className="mx-1">₪{gig.price}</h5>
        </div>
        <div className="d-flex flex-column mt-3">
          {!props.isSeller ? (
            <Link
              to={`/gigs/${gig.category.urlName}/${gig._id}/summary`}
              className="btn btn-danger"
              state={gig}
            >
              המשך
            </Link>
          ) : (
            <Link
              to={`/users/${userId}/edit-job`}
              className="btn btn-secondary"
              state={gig}
            >
              ערוך
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default GigPricing;
