import { Gig } from "../../gigs/interface/gig.interface";
import { Review } from "../../reviews/interface/review-interface";

export type MembershipType = { name: string; maxGigs: number };

export const Membership: {
  free: MembershipType;
  mid: MembershipType;
  premium: MembershipType;
} = {
  free: { name: "FREE", maxGigs: 1 },
  mid: { name: "MID", maxGigs: 3 },
  premium: { name: "PREMIUM", maxGigs: 8 },
};

export interface DecodedToken {
  id: string;
  email: string;
  membership: MembershipType;
  exp: number;
  iat: number;
  isAdmin: boolean;
  expiryDate: Date;
  verified: boolean;
}

export interface User {
  _id: string;
  active?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  userPhoto: string;
  aboutMe: string;
  education?: string;
  certifications?: string;
  idVerification?: boolean;
  languages?: string;
  gigs?: Gig[];
  createdAt: string;
  membership: MembershipType;
  receivedReviews: Review[];
  sentReviews: Review[];
}
