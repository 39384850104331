import { Link } from "react-router-dom";

const ReadyToGetStarted = () => {
  return (
    <div className="d-flex justify-content-center">
      <div
        style={{ background: "linear-gradient(to left, #fffafb, #ffeced)" }}
        className="col-10 col-md-10 col-xxl-8 p-5 p-md-0 rounded d-flex flex-column flex-md-row justify-content-center"
      >
        <div
          style={{ color: "#313131" }}
          className="m-0 m-md-5 col-12 col-md-5 d-flex flex-column justify-content-center justify-content-md-start text-center text-md-start"
        >
          <h1>
            רוצים לפתוח חשבון חדש <br /> להצלחת העסק שלכם?
          </h1>
          <Link
            to={"/users/register"}
            className="col-12 col-md-6 btn btn-danger p-2 mt-2"
          >
            התחילו עכשיו בחינם
          </Link>
        </div>
        <div className="d-none d-md-flex justify-content-start col-5">
          <img
            className="mx-5"
            style={{ width: "50%" }}
            src="https://res.cloudinary.com/dm20uwmki/image/upload/v1694526477/haltu_assets/Server_status-amico-no-bg_o4rxhj.svg"
            alt="הצלחה"
          />
        </div>
      </div>
    </div>
  );
};

export default ReadyToGetStarted;
