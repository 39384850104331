import { Socket } from "socket.io-client";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { useParams, useNavigate } from "react-router-dom";

import ChatUserCard from "../components/ChatUserCard";
import { Spinner } from "../../../components/Spinner";
import MessagesPane from "../components/MessagesPane";
import { User } from "../../user/interface/user.interface";
import { getUserConversations } from "../api/user-chat-read";
import ConversationsPane from "../components/ConversationsPane";
import { Conversation } from "../interface/Conversation.interface";

const ChatPage = function (props: { socket: Socket }) {
  const { socket } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const { userId, authCheck } = useAppSelector((state) => state.user);
  const [reload, setReload] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [otherUser, setOtherUser] = useState<User | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [conversations, setConversations] = useState<Conversation[] | null>(
    null
  );
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(null);

  const handleResize = () => {
    if (window.innerWidth < 725) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  socket.on("newNotificationEvent", (data) => {
    const { id } = data;
    if (userId !== id) {
      return;
    } else {
      const timer = setTimeout(() => {
        setReload(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  });

  socket.on("newMessageEvent", (data) => {
    const { conversationId } = data;
    const foundConversation = conversations?.find(
      (conversation) => conversation._id === conversationId
    );
    if (!foundConversation) {
      return;
    } else {
      setReload(true);
    }
  });

  socket.on("newConversationEvent", (data) => {
    const { firstUser, secondUser } = data;
    if (userId !== firstUser && userId !== secondUser) {
      return;
    } else {
      setReload(true);
    }
  });

  socket.on("newNotificationEvent", (data) => {
    const { id } = data;
    if (id.toString() !== userId?.toString()) {
      return;
    } else {
      setReload(true);
    }
  });
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!authCheck) {
      navigate("/");
    } else if (id !== userId) {
      navigate("/");
    }
    if (reload) {
      getUserConversations(userId)
        .then((results) => {
          setConversations(results.conversations);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReload(false);
          setLoading(false);
        });
    }
  }, [reload, userId, id, navigate, authCheck]);

  return (
    <div
      className="d-flex flex-column flex-sm-row border "
      style={{ minHeight: "90vh" }}
    >
      {!loading ? (
        <>
          <ConversationsPane
            isMobile={isMobile}
            userId={userId}
            conversations={conversations}
            currentConversation={currentConversation}
            setCurrentConversation={(conversation) => {
              setCurrentConversation(conversation);
            }}
          />
          {currentConversation ? (
            <>
              <MessagesPane
                isMobile={isMobile}
                otherUser={otherUser}
                setOtherUser={(user: User) => setOtherUser(user)}
                userId={userId}
                socket={socket}
                currentConversation={currentConversation}
                closeConversation={() => {
                  setOtherUser(null);
                  setCurrentConversation(null);
                }}
              />
              <div className="d-none border-start d-lg-flex align-items-start bg-light justify-content-center col-3">
                {otherUser && <ChatUserCard user={otherUser} />}
              </div>
            </>
          ) : (
            <div
              className="d-none d-lg-flex flex-column justify-content-center text-center"
              style={{ marginRight: "300px" }}
            >
              <div className="mb-5">
                <img
                  src="https://res.cloudinary.com/dm20uwmki/image/upload/v1693233316/haltu_assets/6199003_3156812_rx4nwd.svg"
                  alt="לא נבחרה שיחה להצגה"
                  style={{ width: "35vw" }}
                />
                <h1>לא נבחרה שיחה להצגה</h1>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default ChatPage;
