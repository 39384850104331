import { baseUrl } from "../../../../API/base-urls";

const URL = `${baseUrl}/users`;

export const verifyUser = function (token: string) {
  return fetch(`${URL}/verify/${token}`, {
    method: "PATCH",
  })
    .then((results) => {
      if (!results.ok) {
        throw new Error("Something went wrong ");
      }
      return results.json();
    })
    .then((results) => {
      const token = results.newToken;
      localStorage.setItem("token", token);
      window.location.replace("/");
      return;
    })
    .catch((error) => {
      console.log(error);
    });
};
