import jwtDecode from "jwt-decode";
import { useState, useEffect } from "react";
import { baseUrl } from "../../API/base-urls";
import { useAppDispatch } from "../../store/hooks";
import { DecodedToken } from "../../features/user/interface/user.interface";
import { userActions } from "../../store/slices/user/user-slice";

export const useLocalStorage = function () {
  const dispatch = useAppDispatch();
  const [storageToken, setStorageToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  useEffect(() => {
    if (storageToken) {
      const decodedToken: DecodedToken = jwtDecode(storageToken);
      const currentDate = Date.now();
      const expiryDate = Date.parse(decodedToken.expiryDate.toString());
      if (currentDate >= expiryDate) {
        dispatch(userActions.logout());
      } else {
        const validateUser = () =>
          fetch(`${baseUrl}/users/validate`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${storageToken}`,
            },
          });
        validateUser()
          .then((results) => {
            if (results.status === 200) {
              dispatch(userActions.autoLogin(storageToken));
            } else if (results.status === 205) {
              dispatch(userActions.logout());
            } else {
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [storageToken, dispatch]);

  return [storageToken, setStorageToken];
};
