import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCamera, faClose } from "@fortawesome/free-solid-svg-icons";

interface FileInputProps {
  id?: string;
  text?: string;
  describedBy?: string;
  overlayPosition?: string;
  index: number;
  uploadedFiles: string[];
  setUploadedFiles: (payload: string[]) => void;
  onChange?: (event: React.ChangeEvent) => void;
}

const FileInput = function (props: FileInputProps) {
  const [errState, setErrState] = useState<boolean>(false);
  const [switchIcons, setSwitchIcons] = useState<boolean>(false);
  const pickedFile = function (event: React.ChangeEvent) {
    event.preventDefault();
    setErrState(false);
    const newArr = [...props.uploadedFiles];
    const files = (event.target as HTMLInputElement).files;
    if (!files) return;
    for (const [key, value] of Object.entries(files)) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      if (value.size > 9437184) {
        setErrState(true);
        return;
      }
      reader.onloadend = (e) => {
        if (!reader.result) return;
        newArr[props.index] = reader.result?.toString();
        props.setUploadedFiles(newArr);
      };
    }
  };

  const onPhotoDelete = function (e: any) {
    const filteredArray = [...props.uploadedFiles];
    filteredArray[props.index] = "";
    props.setUploadedFiles(filteredArray);
  };

  return (
    <div>
      <label htmlFor={props.id}>
        {props.text}
        {props.uploadedFiles[props.index] === "" ? (
          <div
            onMouseOver={() => setSwitchIcons(true)}
            className="d-flex card file-input justify-content-center align-items-center position-absolute"
            style={{ width: "7rem", height: "10rem" }}
            onMouseOut={() => setSwitchIcons(false)}
          >
            <FontAwesomeIcon
              icon={!switchIcons ? faPlus : faCamera}
              style={{ fontSize: "30px", padding: "", opacity: "0.8" }}
            />
            <input
              type="file"
              id={props.id}
              hidden
              aria-describedby={props.describedBy}
              defaultValue={props.uploadedFiles[props.index]}
              onChange={pickedFile}
              accept="image/*"
            />
            {errState ? <p className="text-danger">קובץ גדול מדי</p> : null}
          </div>
        ) : (
          <div>
            <img
              src={props.uploadedFiles[props.index]}
              alt="..."
              className="card position-absolute"
              style={{ width: "7rem", height: "10rem" }}
            />
            <div className="file-image-overlay" onClick={onPhotoDelete}>
              <FontAwesomeIcon icon={faClose} />
            </div>
          </div>
        )}
      </label>
    </div>
  );
};
export default FileInput;
